<div class="app-content scrollable-content" id="scrollableContainer">
    <!-- Top Banner  -->
    <section class="page-header" cds-layout="container:fill container:center container:vertical xl">

        <div cds-layout="grid gap:md gap@md:xl p:md p@xs:xl p@md:xxl p-x@sm:xxl">
            <div cds-layout="vertical gap:md gap@md:lg col:12 col@xxl:6 col@xl:7 col@lg:8 col@md:9 col@sm:10 col@xs:11">
                <h1 cds-text="display" cds-layout="m-b:md m-t:xl m-t@xs:xxl">{{banner.title}}</h1>
                <h4 cds-text="section">{{banner.description}}</h4>
                <div cds-layout="horizontal gap:md m-t:md">
                    <cds-button [routerLink]="['/systems']">View all Systems</cds-button>
                    <a [href]="codeAction?.link" target="_blank">
                        <cds-button>Explore the code</cds-button>
                    </a>
                </div>
            </div>
            <div cds-layout="col@xxl:5 col@xl:4 col@lg:3 col@md:2 col@xs:1"></div>
            <div class="ukis-visual">
                <!-- <img [src]="banner.image" width="355" height="355" /> -->
                <img [ngSrc]="banner.image" width="355" height="355" ngSrcset="384w, 640w" />
            </div>
        </div>
    </section>


    <!-- featured systems Gallery  -->
    <section class="systems-gallery"
        cds-layout="container:fill container:center p:md p@xs:xl p@md:xxl p-x@sm:xxl container:vertical xl">
        <h3 cds-text="title left" cds-layout="m-b:lg">Example Systems</h3>
        <app-highlight-slider [sliderItems]="featuredSystems"></app-highlight-slider>
    </section>

    <!-- call to action  -->
    <section class="call-to-action"
        cds-layout="container:lg container:center m-bxs:lg m-b@md:xl p:md p@xs:xl p@md:xxl p-x@sm:xxl">
        <div cds-layout="grid cols@sm:6 gap:lg gap@md:xxl">

            <!-- Action Block-->
            @for (act of actions; track act; let i = $index){
            <div class="card cds-card">
                <div class="card-img">
                    <!-- <img [src]="act.image" /> -->
                    <img [ngSrc]="act.image" width="1339" height="753" sizes="(max-width: 768px) 95vw, 45vw" />
                </div>
                <div class="card-block">
                    <div class="card-text" cds-layout="vertical gap:md p:sm p@md:xl">
                        <p cds-text="subsection" cds-layout="m-y:4rem">{{act.subTitle}}</p>
                        <h3 cds-text="headline" cds-layout="m-y:4rem">{{act.questionTitle}}</h3>
                        <div class="card-block questions">
                            <ul cds-list>
                                @for (q of act.questions; track q){
                                <li [innerHTML]="q | markdownLink" cds-text="message expanded"></li>
                                }
                            </ul>
                        </div>
                        <div>
                            @if (checkLinkExternal(act.link)) {
                            <a [href]="act.link" target="_blank">
                                <cds-button>{{act.title}}</cds-button>
                            </a>
                            } @else {
                            <cds-button [routerLink]="[act.link]">{{act.title}}</cds-button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>

    </section>

    <app-footer></app-footer>
</div>