var e;
!function (e) {
  e.ready = "ready", e.active = "active", e.start = "starting", e.end = "done";
}(e || (e = {}));
const t = "hidden",
  a = "reverse",
  r = "-reverse",
  n = 300,
  i = "linear",
  s = "_cds-animation-status";
export { e as AnimationStatus, t as CLARITY_MOTION_ENTER_LEAVE_PROPERTY, n as CLARITY_MOTION_FALLBACK_DURATION_IN_MS, i as CLARITY_MOTION_FALLBACK_EASING, a as CLARITY_MOTION_REVERSE_ANIMATION_LABEL, r as CLARITY_MOTION_REVERSE_ANIMATION_SUFFIX, s as PRIVATE_ANIMATION_STATUS_ATTR_NAME };
