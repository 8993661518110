import { Positions as t } from "./interfaces.js";
import { transformSpacedStringToArray as e, removePrefix as o, capitalizeFirstLetter as n } from "../utils/string.js";
import { setAttributes as i, getWindowDimensions as r } from "../utils/dom.js";
import { isNil as s } from "../utils/identity.js";
import { updateElementStyles as p, getCssPropertyValue as f } from "../utils/css.js";
import { sumAndSubtract as u } from "../utils/math.js";
import { getEnumValueFromStringKey as c } from "../utils/enum.js";
function a(t, e, o, n, i) {
  const r = {
      mainAxisOffset: 0,
      crossAxisOffset: 0
    },
    p = o && "default" === o.type;
  return s(t) ? p && (r.mainAxisOffset = -10) : r.mainAxisOffset = t, s(e) ? p && "end" === n && "start" === i && (r.crossAxisOffset = -10) : r.crossAxisOffset = e, r;
}
function l(t) {
  t.removeAttribute("responsive");
  const e = a(t.mainAxisOffset, t.crossAxisOffset, t.pointer, t.pointerAlign, t.anchorAlign),
    o = O(t.orientation, t.anchorRect, t.anchorAlign, t.pointer, t.pointerAlign, t.contentWrapper, e.mainAxisOffset, e.crossAxisOffset);
  if (!1 === o) i(t, ["responsive", ""], ["_position-at", !1]), p(t.hostWrapper, ["position", ""], ["top", ""], ["left", ""]), p(t.pointerWrapper, ["visibility", "hidden"]);else {
    if (t.pointer) {
      const e = o.pointer;
      p(t.pointerWrapper, ["visibility", "visible"]), i(t, ["_pointer-type", t.pointer.type || !1]), i(t, ["_position-at", e.location]);
    } else p(t.pointerWrapper, ["visibility", "hidden"]), i(t, ["_position-at", !1], ["_pointer-type", !1]);
    const e = o.popup.top + "px",
      n = o.popup.left + "px";
    p(t.hostWrapper, ["position", "absolute"], ["top", e], ["left", n]);
  }
}
function h(i) {
  const r = [],
    s = [];
  for (const p of e(i)) {
    if ("none" === p) return [[], 0];
    if (p.indexOf("only:") > -1) return [[c(t, o(p, "only:"), n)], 0];
    if (p.indexOf("not:") > -1) s.push(c(t, o(p, "not:"), n));else {
      const e = c(t, p, n);
      s.push(e), r.push(e);
    }
  }
  return [r, u(0, [t.All], s)];
}
function m(t) {
  switch (t) {
    case "mid":
      return [1, 0, 2];
    case "end":
      return [2, 1, 0];
    default:
      return [0, 1, 2];
  }
}
function d(e, o, n) {
  if (!1 === o) return !1;
  const i = o[t[e]?.toLowerCase()];
  if (!i) return !1;
  const [r, s, p] = i;
  if (!1 === r && !1 === s && !1 === p) return !1;
  for (const t of m(n)) if (i[t]) return Object.assign({}, i[t]);
  return !1;
}
function g(e) {
  const o = [t.Bottom, t.Right, t.Left, t.Top, t.Responsive];
  for (const t of o) if (e >= t) return [t, e - t];
  return [0, 0];
}
function b(e, o) {
  if (e.length < 1) {
    const [t, n] = g(o);
    return [t, e, n];
  }
  return [e[0], e.slice(1), e[0] !== t.Responsive ? o : 0];
}
function w(t) {
  switch (t.toLowerCase()) {
    case "top":
      return "popup-bottom";
    case "bottom":
      return "popup-top";
    case "left":
      return "popup-right";
    default:
      return "popup-left";
  }
}
function x(t, e) {
  let o;
  return o = "popup-bottom" === t || "popup-top" === t ? {
    start: "pointer-left",
    mid: "pointer-center",
    end: "pointer-right"
  } : {
    start: "pointer-top",
    mid: "pointer-mid",
    end: "pointer-bottom"
  }, o[e];
}
function A(e, o, n) {
  let [i, r] = o,
    s = null;
  for (; null === s;) {
    const [o, p, f] = b(i, r),
      u = d(o, e, n);
    switch (!0) {
      case !1 !== u:
        s = Object.assign({}, u, {
          pointer: {
            location: w(t[o])
          }
        });
        break;
      case !1 === u && 0 === f:
        s = !1;
        break;
      case !1 === u:
        i = p, r = f;
    }
  }
  return s;
}
function O(t, e, o, n, i, s, p, u) {
  if (!e) return !1;
  const c = n ? n.offsetHeight : 0,
    a = r();
  if (a.width <= parseInt(f("--cds-global-layout-width-xs-static"), 10)) return !1;
  const l = A(y(e, c, i, s.getBoundingClientRect(), a, p, u), h(t), o);
  if (!1 === l) return !1;
  const m = l.pointer.location,
    d = m + " " + x(m, i);
  return Object.assign({}, l, {
    pointer: {
      size: c,
      location: d
    }
  });
}
function y(t, e, o, n, i, r, s) {
  return {
    top: j("top", o, t, n, e, i, r, s),
    right: j("right", o, t, n, e, i, r, s),
    bottom: j("bottom", o, t, n, e, i, r, s),
    left: j("left", o, t, n, e, i, r, s)
  };
}
function j(t, e, o, n, i, r, s, p) {
  let f;
  switch (t) {
    case "top":
      return f = L(o.top, i, n.height, s, 0), !1 !== f && C(o.left, o.width, n.width, p, 0, r.width, e).map(t => !1 !== t && {
        popup: {
          top: f,
          left: t
        }
      });
    case "bottom":
      return f = L(o.bottom, 0, n.height, s, r.height), !1 !== f && C(o.left, o.width, n.width, p, 0, r.width, e).map(t => !1 !== t && {
        popup: {
          top: f,
          left: t
        }
      });
    case "left":
      return f = L(o.left, i, n.width, s, 0), !1 !== f && C(o.top, o.height, n.height, p, 0, r.height, e).map(t => !1 !== t && {
        popup: {
          top: t,
          left: f
        }
      });
    case "right":
      return f = L(o.right, 0, n.width, s, r.width), !1 !== f && C(o.top, o.height, n.height, p, 0, r.height, e).map(t => !1 !== t && {
        popup: {
          top: t,
          left: f
        }
      });
  }
}
function v(t, e, o, n, i) {
  return 0 === i ? u(t, [], [e, o, n]) : u(t, [e, o, n], []);
}
function W(t, e, o) {
  return 0 === o ? t > o && t : t < o && e;
}
function R(t, e, o, n, i, r = 0) {
  switch (t) {
    case "mid":
      return e + o * n - .5 * r + i;
    case "end":
      return e + o * n - i;
    case "start":
      return e + o * n + i;
  }
}
function _(t, e, o, n) {
  const [i, r] = n;
  switch (t) {
    case "mid":
      return e > i && e + o < r && e;
    case "end":
      {
        const t = e - o;
        return t > i && t;
      }
    case "start":
      return e + o < r && e;
  }
}
function C(t, e, o, n, i, r, s) {
  return [0, .5, 1].map(p => _(s, R(s, t, p, e, n, o), o, [i, r]));
}
function L(t, e, o, n, i = 0) {
  return W(v(t, e, o, n, i), t + n, i);
}
export { d as checkNextPosition, A as getBestPositionForPreferences, m as getCrossAxisOrderOfPreference, R as getCrossAxisPosition, v as getMainAxisPosition, L as getMainAxisPositionOrViolation, g as getNextDefaultPosition, b as getNextPosition, h as getOrientationTuple, x as getPointerAlignment, w as getPointerPosition, a as getPopupOffsetOrDefaultOffsets, O as getPopupPosition, j as getPositionConfig, C as getPositionOrViolationFromCrossAxis, y as getPositions, l as setPopupPosition, _ as testCrossAxisPosition, W as testMainAxisPosition };
