<div class="tag-container">
    @if (expanded) {
        @for (tag of tags | sort:'desc'; track tag) {
            <cds-tag [status]="tagIsActive(tag)" (click)="onTagClicked(tag)" color="blue">{{ tag }}</cds-tag>
        }

        @if (tags.length > maxNr) {
            <cds-icon shape="angle" direction="up" (click)="expanded = false"></cds-icon>
        }
    }
    @else {
        @for (tag of tags | sort:'desc'; track tag; let i = $index;) {
            @if (i <= maxNr) { 
                <cds-tag (click)="onTagClicked(tag)" [status]="tagIsActive(tag)" color="blue">{{ tag }}</cds-tag>
            }
        }

        @if (tags.length > maxNr) {
            <span>...</span>
            <cds-icon shape="angle" direction="down" class="icon-button" (click)="expanded = true"></cds-icon>
        }
    }
</div>