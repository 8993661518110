<div class="app-content scrollable-content" id="scrollableContainer">
  <section cds-layout="container:lg container:center p-x:md p-x@xs:xl p-x@sm:xxl p-y:lg p-y@sm:xxl">
    <h1 cds-text="display" cds-layout="m-b:md m-t:xl m-t@xs:xxl">UKIS Key Features</h1>

    <section cds-layout="m-t:xl m-t@md:xxl m-b:xl">
      @for (item of introSection; track $index) {
        <p class="p1" cds-text="subsection left light" [innerHTML]="item"></p>
      }
    </section>

    <section class="image-section">
      @for (ft of features; track ft; let i = $index) {
      <div class="item" [ngClass]="isClassEven(i)" cds-layout="grid cols@sm:6 cols@md:6 gap:sm gap@md:xxl m-b:xxl">
        <div class="img-link" cds-layout="p:md p@md:sm">
          <img [ngSrc]="ft.image.link" width="687" height="385" sizes="(max-width: 992px) 95vw, 45vw">
        </div>
        <div class="image-text" cds-layout="row p-t@md:sm p-x:md">
          <div aria-hidden="true" cds-layout="grid gap:md m-t:md m-t@sm:none m-b:md">
            <div cds-layout="col:2" class="content-separator"></div>
          </div>
          <h3 cds-text="title left" cds-layout="m-t:md">{{ft.image.title}}</h3>
          <p class="p1" cds-text="subsection left light" cds-layout="m-t:lg m-b:md">
            {{ft.image.description}}
          </p>
          @if (ft.systems) {
            @for (sys of ft.systems; track sys) {
            <a [routerLink]="['/systems',sys.id]" cds-layout="m-b:md">
              <cds-tag color="blue">{{ sys.name }}</cds-tag>
            </a>
            }
          }
        </div>
      </div>
      }
    </section>
  </section>

  <app-footer></app-footer>
</div>