import { Component } from '@angular/core';
import { Iproject } from '../../data/ukis-projects';
import { DataService } from '../../services/data.service';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-kpi-section',
  templateUrl: './kpi-section.component.html',
  styleUrls: ['./kpi-section.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink]
})
export class KpiSectionComponent {
  public systems: Iproject[] = [];
  public librariesCount = 17;
  public projectsCount = '20'; 	// Mühlbauer, Martin 2023-12-04: Es sollten um die 20 unterstützte projekte sein je nachdem wie man zählt. Ich würde 20 reinschreiben
  constructor(private dataSvc: DataService) {
    this.systems = [...this.dataSvc.getProjects('active'), ...this.dataSvc.getProjects('inactive')];
  }
}
