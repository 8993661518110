import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from "@angular/common";
import { adjustImageLinkSize } from "../services/data.service";

// [16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]
export const IMAGE_CONFIG_Breakpoints = [384, 640, 750, 1080, 1920];

export const IMAGE_CONFIG_Provider = {
    provide: IMAGE_CONFIG,
    useValue: {
        // [16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]
        breakpoints: IMAGE_CONFIG_Breakpoints
    }
};

export const IMAGE_LOADER_Provider = {
    provide: IMAGE_LOADER,
    useValue: (config: ImageLoaderConfig) => {
        const devicePixelRatio = window.devicePixelRatio;
        const deviceWidth = window.innerWidth;
        // TODO: fix this at the images [ngSrc]...
        if (!config.width) {
            // console.log(config, devicePixelRatio, deviceWidth);
            /** https://stackoverflow.com/a/19277804 */
            const closestWidth = IMAGE_CONFIG_Breakpoints.reduce((prev, curr) => Math.abs(curr - deviceWidth) < Math.abs(prev - deviceWidth) ? curr : prev);
            config.width = closestWidth; // deviceWidth * devicePixelRatio;
        }
        let link = adjustImageLinkSize(config.src, config.width);
        return link;
    },
};