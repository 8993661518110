import { Subscription } from 'rxjs';

// inspired by https://github.com/wardbell/subsink
export class CollectSub {
  protected privSubs: Array<Subscription> = [];
  public get subs(): Array<Subscription> {
    return this.privSubs;
  }


  public set subs(value: Subscription | Array<Subscription>) {
    if (Array.isArray(value)) {
      this.privSubs.push(...value);
    } else {
      this.privSubs.push(value);
    }
  }

  public unsubscribe(): void {
    this.subs.forEach(s => {
      if (s instanceof Subscription) {
        s.unsubscribe();
      }
      // https://bytethisstore.com/articles/pg/rxjs-unsubscribe
      s = void 0 as any;
    });
    this.subs = [];
  }
}