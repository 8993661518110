<!-- cds-layout="grid" > cds-layout="col:2" , cds-layout="col:8" , cds-layout="col:2" -->
<footer class="footer-wrap">
    <div class="app-footer">
        <div class="footer-logos">
            <a href="https://www.dlr.de/" target="_blank">
                <cds-icon shape="dlr-icon"></cds-icon>
            </a>
            <a href="https://www.dlr.de/eoc/" target="_blank">
                <cds-icon shape="eoc-icon"></cds-icon>
            </a>
            <a [routerLink]="['/home']">
                <cds-icon shape="ukis-icon"></cds-icon>
            </a>
        </div>
        <ul class="footer-links" cds-text="message">
            @for (route of footerRoutes; track route.path) {
            <li>
                <a routerLink="/{{route.path}}" routerLinkActive="active" class="link">{{route.data?.['title']}}</a>
            </li>
            }
        </ul>
    </div>
</footer>