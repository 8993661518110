<!-- featured systems Gallery  -->
<div cds-layout="container:fill container:center">
    <section #sliderContainer class="splide">
        <div class="splide__track">
            <ul class="splide__list">
                @for (item of sliderItems; track item; let i = $index) {
                <li class="splide__slide">
                    <div class="cds-card clickable grid-card">
                        <app-list-system-card-main [system]="item" [index]="i"></app-list-system-card-main>
                    </div>
                </li>
                }
            </ul>
        </div>

        <div class="splide__controls">
            <button class="splide__toggle" type="button">
                <svg class="splide__toggle__play" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m22 12-20 11v-22l10 5.5z" />
                </svg>
                <svg class="splide__toggle__pause" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m2 1v22h7v-22zm13 0v22h7v-22z" />
                </svg>
            </button>

            <ul class="splide__pagination"></ul>
        </div>
    </section>
</div>