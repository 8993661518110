import { onFirstInteraction as t, onChildListMutation as s } from "../utils/events.js";
import { getFlattenedDOMTree as e, getFlattenedFocusableItems as i } from "../utils/traversal.js";
import { contextMenuClick as o } from "../utils/dom.js";
import { validKeyNavigationCode as h } from "../utils/keycodes.js";
import { initializeKeyListItems as l, simpleFocusable as r, getActiveElement as n, setActiveKeyListItem as c, focusElement as d } from "../utils/focus.js";
import { getNextKeyGridItem as a } from "./key-navigation.utils.js";
function u() {
  return t => t.addInitializer(t => new f(t));
}
class f {
  constructor(t) {
    this.host = t, this.observers = [], this.host.addController(this);
  }
  get hostGrid() {
    return this.host.keyNavGrid ? this.host.keyNavGrid : this.host;
  }
  get hostRows() {
    const t = Array.from(this.host.rows);
    return this.host.columnRow && t.unshift(this.host.columnRow), t;
  }
  get hostCells() {
    const t = Array.from(this.host.cells);
    return this.host.columns && t.unshift(...Array.from(this.host.columns)), t;
  }
  get activeCell() {
    return Array.from(this.hostCells).find(t => 0 === t.tabIndex);
  }
  async hostConnected() {
    await this.host.updateComplete, t(this.host).then(() => {
      l(this.hostCells), this.hostGrid.addEventListener("mouseup", t => this.clickCell(t)), this.hostGrid.addEventListener("keydown", t => this.keynavCell(t)), this.hostGrid.addEventListener("keyup", t => this.updateCellActivation(t));
    }), this.observers.push(s(this.host, () => l(this.hostCells)));
  }
  hostDisconnected() {
    this.observers.forEach(t => t?.disconnect());
  }
  clickCell(t) {
    if (!o(t)) {
      const s = t.composedPath().find(t => this.hostCells.find(s => s === t));
      s && this.setActiveCell(t, s);
    }
  }
  keynavCell(t) {
    if (h(t) && r(n())) {
      const {
          x: s,
          y: i
        } = a(this.hostCells, this.hostRows, {
          code: t.code,
          ctrlKey: t.ctrlKey,
          dir: this.host.dir
        }),
        o = Array.from(e(this.hostRows[i])).filter(t => !!this.hostCells.find(s => s === t))[s];
      this.setActiveCell(t, o), t.preventDefault();
    }
  }
  setActiveCell(t, s) {
    c(this.hostCells, s);
    const e = i(s),
      o = e.filter(t => r(t));
    1 === o.length && 1 === e.length ? d(o[0]) : d(s), s.dispatchEvent(new CustomEvent("cdsKeyChange", {
      bubbles: !0,
      detail: {
        code: t.code,
        shiftKey: t.shiftKey,
        activeItem: s
      }
    }));
  }
  updateCellActivation(t) {
    "Escape" === t.code && this.activeCell?.focus(), "Enter" === t.code && this.activeCell === t.composedPath()[0] && i(this.activeCell)[0]?.focus();
  }
}
export { f as KeyNavigationGridController, u as keyNavigationGrid };
