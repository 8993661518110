<div class="app-content scrollable-content">
  <div class="privacy pad-footer-bottom" cds-layout="container:lg container:center p:lg p-y:lg">
    <h2>Information about Data Protection</h2>
    <p></p>
    <p><strong>For the website of project UKIS (Environmental and Crisis Information Systems, 
      German: Umwelt- und Kriseninformationssysteme)</strong></p>
    <p>DLR takes the protection of personal data very seriously. We want you to
      know when we store data, which types of data are stored and how it is
      used. As an incorporated entity under German civil law, we are subject
      to the provisions of the <a href="https://gdpr-info.eu/" rel="external" target="_blank" class="external-link"
        title="Externer Link ">EU General Data Protection Regulation (GDPR)</a>, the <a
        href="https://www.gesetze-im-internet.de/englisch_bdsg/index.html" rel="external" target="_blank"
        class="external-link" title="Externer Link ">Federal Data Protection Act (BDSG)</a>
      and the <a href="https://www.gesetze-im-internet.de/ttdsg/" rel="external" target="_blank" class="external-link"
        title="Externer Link ">Telecommunications Telemedia Data Protection Act (TTDPA)</a>. We have taken
      technical and organisational measures to ensure our compliance and the
      compliance of external service providers with the data protection
      regulation.</p>
    <p>This website uses SSL -- that is, TLS encryption -- for security
      reasons. A connection is encrypted if you see the character sequence
      'https://' and the padlock icon in your browser's address bar.</p>
    <h2>Website of project UKIS</h2>
    <p>This website provides an overview on DLR's technology UKIS (Environmental and Crisis Information Systems, 
      German: Umwelt- und Kriseninformationssysteme) and lists some exemplary geospatial information systems in 
      order to demonstrate for which purposes UKIS can be used.</p>
    <h2 id="name-and-address-of-the-controller" class="test">Name and address of the controller</h2>
    <p>The controller in the meaning of the General Data Protection Regulation,
      other national data protection laws in the Member States and related
      data protection regulations is:</p>
    <p>Deutsches Zentrum für Luft- und Raumfahrt e. V. (DLR)<br>Linder Höhe<br>51147 Cologne</p>
    <p>Telephone: +49 2203 601-0<br>Email: <a href="mailto:datenschutz@dlr.de">datenschutz&#64;dlr.de</a><br>WWW: <a
        href="https://www.dlr.de">https://www.dlr.de</a></p>
    <h2 id="name-and-address-of-the-data-protection-officer" class="test">Name and address of the data protection
      officer</h2>
    <p>The controller's appointed data protection officer is:</p>
    <p>Uwe Gorschütz, Deutsches Zentrum für Luft- und Raumfahrt e. V., Linder
      Höhe, 51147 Cologne<br>Email: <a href="mailto:datenschutz@dlr.de">datenschutz&#64;dlr.de</a></p>
    <h2 id="definition-of-terms" class="test">Definition of terms</h2>
    <p>Among others, we use the following terms in this Privacy Policy, set out
      in the General Data Protection Regulation and the Federal Data
      Protection Act:</p>
    <p><em>1. Personal data</em></p>
    <p>Personal data refers to any information relating to an identified or
      identifiable natural person (hereinafter: 'data subject'). An
      identifiable natural person is one who can be identified -- directly or
      indirectly -- in particular by reference to an identifier such as a
      name, an identification number, location data, an online identifier or
      to one or more factors specific to the physical, physiological, genetic,
      mental, economic, cultural or social identity of that natural person.</p>
    <p><em>2. Data subject</em></p>
    <p>A data subject is any identified or identifiable natural person whose
      personal data is processed by the controller.</p>
    <p><em>3. Processing</em></p>
    <p>Processing is any operation or set of operations performed on personal
      data or on sets of personal data -- whether or not by automated means --
      such as collection, recording, organisation, structuring, storage,
      adaptation or alteration, retrieval, consultation, use, disclosure by
      transmission, dissemination or otherwise making available, alignment or
      combination, restriction, deletion or destruction.</p>
    <p><em>4. Restriction of processing</em></p>
    <p>Restriction of processing means the marking of stored personal data with
      the aim of limiting its processing in the future.</p>
    <p><em>5. Profiling</em></p>
    <p>Profiling means any form of automated processing of personal data
      consisting of the use of personal data to evaluate certain personal
      aspects relating to a natural person, in particular to analyse or
      predict aspects concerning that natural person's performance at work,
      economic situation, health, personal preferences, interests,
      reliability, behaviour, location or movements.</p>
    <p><em>6. Pseudonymisation</em></p>
    <p>Pseudonymisation means the processing of personal data in such a manner
      that the personal data can no longer be attributed to a specific data
      subject without the use of additional information, provided that such
      additional information is kept separately and is subject to technical
      and organisational measures to ensure that the personal data are not
      attributed to an identified or identifiable natural person.</p>
    <p><em>7. Controller or data processing controller</em></p>
    <p>Controller or data processing controller means the natural or legal
      person, public authority, agency or other body which, alone or jointly
      with others, determines the purposes and means of the processing of
      personal data; where the purposes and means of such processing are
      determined by Union or Member State law, the controller or the specific
      criteria for its nomination may be provided for by Union or Member State
      law.</p>
    <p><em>8. Processor</em></p>
    <p>Processor means a natural or legal person, public authority, agency or
      other body that processes personal data on behalf of the controller.</p>
    <p><em>9. Recipient</em></p>
    <p>Recipient means a natural or legal person, public authority, agency or
      another body, to which the personal data are disclosed, whether a third
      party or not. However, public authorities that may receive personal data
      in the framework of a particular inquiry in accordance with Union or
      Member State law shall not be regarded as recipients.</p>
    <p><em>10. Third party</em></p>
    <p>Third party means a natural or legal person, public authority, agency or
      body other than the data subject, controller, processor and persons who,
      under the direct authority of the controller or processor, are
      authorised to process personal data.</p>
    <p><em>11.Consent</em></p>
    <p>Consent of the data subject means any freely given, specific, informed
      and unambiguous indication of the data subject's wishes by which he or
      she, by a statement or by a clear affirmative action, signifies
      agreement to the processing of personal data relating to him or her.</p>
    <h2 id="general-information-on-data-processing" class="test">General information on data processing</h2>
    <p><em>1. Scope of processing of personal data</em></p>
    <p>We process personal data concerning our users exclusively to the extent
      required to provide a functioning website, as well as our content and
      services. Ordinarily, we will only process the personal data of our
      users after obtaining their consent. An exception to this rule is where
      obtaining prior consent is factually impossible and the processing of
      the data is permitted by law.</p>
    <p><em>2. Legal grounds for the processing of personal data</em></p>
    <p>Where we obtain consent from the data subject for the processing of
      personal data, the legal grounds are set out in Art. 6, paragraph 1,
      part (a) of the EU General Data Protection Regulation (GDPR).</p>
    <p>Where personal data is processed for the performance of a contract in
      which the data subject is a contractual partner, the legal grounds are
      set out in Art. 6, paragraph 1, part (b) of the GDPR. This also applies
      to processing that is necessary for pre-contractual measures.</p>
    <p>Where personal data is processed for compliance with a legal obligation
      to which our research centre is subject, the legal grounds are set out
      in Art. 6, paragraph 1, part (c) of the GDPR.</p>
    <p>Where processing of personal data is necessary for the protection of
      vital interests of the data subject or another natural person, the legal
      grounds are set out in Art. 6, paragraph 1, part (d) of the GDPR.</p>
    <p>Where processing is necessary for the legitimate interests of our
      research centre or a third party, and where the fundamental rights and
      freedoms of the data subject do not override the first interests, the
      legal grounds are set out in Art. 6, paragraph 1, part (f) of the GDPR.</p>
    <p><em>3. Data deletion and duration of data storage</em></p>
    <p>The personal data of the data subject will be deleted or blocked as soon
      as the purpose of storage no longer applies. In addition, storage takes
      place if authorised by Union or Member State directives, laws or other
      regulations to which the controller is subject. Blocking or deletion of
      the data shall also take place when a storage period stipulated by one
      of the above standards comes to an end, except where it is necessary to
      continue storing the data to enter into or perform a contract.</p>
    <h2 id="provision-of-the-website-and-generation-of-log-files" class="test">Provision of the website and generation
      of log files</h2>
    <p><em>a) Description and scope of data processing</em></p>
    <p>Our system automatically collects data and information from the
      accessing computer system each time our website is visited.</p>
    <p>The following data is collected in this context:</p>
    <ul>
      <li>
        <p>The user's IP address</p>
      </li>
      <li>
        <p>Date and time of access</p>
      </li>
      <li>
        <p>HTTP request method</p>
      </li>
      <li>
        <p>Requested file</p>
      </li>
      <li>
        <p>Version of HTTP protocol</p>
      </li>
      <li>
        <p>HTTP status code</p>
      </li>
      <li>
        <p>Size of server response in bytes</p>
      </li>
      <li>
        <p>Referrer website(s)</p>
      </li>
      <li>
        <p>Websites accessed by the user from our website</p>
      </li>
      <li>
        <p>Information about the browser type and version</p>
      </li>
    </ul>
    <p>The data is also stored in log files kept on our system. This data is
      not stored together with other personal data concerning the user.</p>
    <p><em>b) Legal grounds for data processing</em></p>
    <p>The legal grounds for temporary storage of the data and log files are
      set out in Art. 6, paragraph 1, part (f) of the EU General Data
      Protection Regulation (GDPR).</p>
    <p><em>c) Purpose of data processing</em></p>
    <p>Temporary storage of the IP address by our system is necessary to
      deliver the website to the computer of the user. For this purpose, the
      user's IP address must be stored for the duration of the session.</p>
    <p>Storage in log files takes place to ensure functionality of the website.
      In addition, the data is used to optimise the website and to ensure
      security of our Information Technology systems. Data analysis for
      marketing purposes does not take place in this context.</p>
    <p>DLR does not draw any conclusions about the identity of the data subject
      during use of the data mentioned under a). Instead, this information is
      necessary to (1) deliver the contents of our website in their correct
      form; to (2) optimise the contents of our website and promote it; to (3)
      guarantee the permanent functionality of our information technology
      systems and equipment used for our website; and to (4) provide the
      information necessary for law enforcement organisations to investigate
      cyber-attacks.</p>
    <p>These purposes justify our legitimate interests in data processing
      according to Art. 6, paragraph 1, part (f) of the GDPR.</p>
    <p><em>d) Duration of storage</em></p>
    <p>The data is deleted as soon as it is no longer needed for the purpose
      for which it was collected. In the case of data collection for the
      provision of this website, this applies at the end of each session.</p>
    <p>In the case of data stored in log files, this occurs after no longer
      than fourteen days. Further storage is possible; in these cases, the
      users' IP addresses are deleted or pseudonymised to prevent any
      association with the accessing client.</p>
    <p><em>e) Right to objection and removal</em></p>
    <p>The collection of data for the provision of our website and the storage
      of data in log files is crucial to operation of the website. Hence,
      users are not granted a right to object.</p>
    <h2 id="use-of-cookies" class="test">Use of cookies</h2>
    <p>This website does not use cookies.</p>
    <h2 id="rights-of-the-data-subject" class="test">Rights of the data subject</h2>
    <p>Where personal data concerning you is processed, you are the data
      subject as defined in the EU General Data Protection Regulation (GDPR)
      and you have the following rights with respect to the controller:</p>
    <p><em>a) Right to information</em></p>
    <p>You have the right to obtain from the controller confirmation of whether
      personal data concerning you is processed by us.</p>
    <p>Where such processing takes place, you have the right to obtain the
      following information from the controller:</p>
    <ul>
      <li>
        <p>the purposes for which the personal data is processed;</p>
      </li>
      <li>
        <p>the categories of personal data that is processed;</p>
      </li>
      <li>
        <p>the recipients, or categories of recipients to whom the personal
          data relating to you has been or will be disclosed;</p>
      </li>
      <li>
        <p>the planned duration of storage of the personal data concerning you,
          or the criteria applied to defining the duration of storage if
          precise information in this regard is not available;</p>
      </li>
      <li>
        <p>the existence of a right to correction or deletion of the personal
          data concerning you, the right to restrict processing by the
          controller or the right to object to this processing;</p>
      </li>
      <li>
        <p>the right to lodge a complaint with a supervisory authority;</p>
      </li>
      <li>
        <p>all information available concerning the origins of the data if the
          personal data was not collected from the data subject;</p>
      </li>
      <li>
        <p>the existence of an automated decision-making process, including
          profiling, according to Art. 22 paragraphs 1 and 4 of the GDPR and
          -- at least in these cases -- meaningful information on the logic
          and implications involved, as well as on the intended effects of
          this kind of processing on the data subject;</p>
      </li>
      <li>
        <p>You also have the right to obtain information on whether the
          personal data concerning you has or will be transferred to a third
          country or to an international organisation. In this regard, you are
          entitled to request information on the appropriate guarantees in
          place with regard to this processing in accordance with Art. 46 of
          the GDPR.</p>
      </li>
    </ul>
    <p>The controller will provide a copy of the personal data that is subject
      to processing. Where you request additional copies, the controller is
      entitled to charge an appropriate fee based on administrative costs. If
      you place the application by electronic means, the information will be
      made available in a standard electronic format, except where otherwise
      specified by you. The right to receive a copy in accordance with
      paragraph 3 of this section must not adversely affect the rights and
      freedoms of other persons.</p>
    <p><em>b) Right to correction</em></p>
    <p>As a data subject, you have the right to request from the controller the
      correction of inaccurate personal data concerning you without undue
      delay. Taking into account the purposes of the processing, you have the
      right to have incomplete personal data completed, including by means of
      providing a supplementary statement.</p>
    <p><em>c) Right to limit processing</em></p>
    <p>You have the right to request from the controller restriction of
      processing of personal data concerning you under the following
      conditions:</p>
    <ul>
      <li>
        <p>where the accuracy of the personal data is contested by you, for a
          period enabling the controller to verify the accuracy of the
          personal data;</p>
      </li>
      <li>
        <p>the processing is unlawful and you oppose the deletion of the
          personal data, and instead request the restriction of its use;</p>
      </li>
      <li>
        <p>the controller no longer needs the personal data for the purposes of
          the processing, but it is required by you for the establishment,
          exercise or defence of legal claims; or</p>
      </li>
      <li>
        <p>if you have objected to processing pursuant to Art. 21, paragraph 1,
          of the GDPR, pending the verification of whether the legitimate
          reasons of the controller override your reasons.</p>
      </li>
    </ul>
    <p>Where processing of the personal data concerning you has been
      restricted, such personal data shall, with the exception of storage,
      only be processed with your consent or for the establishment, exercise
      or defence of legal claims or for the protection of the rights of
      another natural or legal person or for reasons of important public
      interest of the Union or of a Member State.</p>
    <p>Where you have obtained restriction of processing under the conditions
      set out above, you will be informed by the controller before the
      restriction of processing is lifted.</p>
    <p><em>d) Right to deletion</em></p>
    <p>Obligation to delete</p>
    <p>You have the right to request the controller to delete personal data
      concerning you without undue delay, and the controller will be obliged
      to delete personal data immediately where one of the following grounds
      applies:</p>
    <ul>
      <li>
        <p>the personal data is no longer necessary in relation to the purposes
          for which it was collected or otherwise processed;</p>
      </li>
      <li>
        <p>you withdraw consent on which the processing is based according to
          part (a) of Art. 6, paragraph 1, or part (a) of Art. 9, paragraph 2
          of the GDPR, and there is no other legal basis for the processing;</p>
      </li>
      <li>
        <p>you object to the processing pursuant to Art. 21, paragraph 1 of the
          GDPR and there are no overriding legitimate grounds for the
          processing, or you object to the processing pursuant to Art. 21,
          paragraph 2 of the GDPR;<br>the personal data concerning you has been unlawfully processed;</p>
      </li>
      <li>
        <p>the personal data has to be deleted to comply with a legal
          obligation under a Union or Member State law to which the controller
          is subject;</p>
      </li>
      <li>
        <p>The personal data concerning you has been collected in relation to
          the offer of information society services referred to in Art. 8,
          paragraph 1 of the GDPR.</p>
      </li>
      <li>
        <p>Information to third parties</p>
      </li>
    </ul>
    <p>Information to third parties</p>
    <p>Where the controller has made the personal data concerning you public
      and is obliged pursuant to Art. 17, paragraph 1 of the GDPR to delete
      the personal data, the controller, taking account of available
      technology and the cost of implementation, is required to take
      reasonable steps, including technical measures, to inform controllers
      who are processing the personal data that you have requested to be
      deleted by such controllers, as well as any links to, copies or
      replications of such personal data.</p>
    <p>Exceptions</p>
    <p>The right to deletion does not apply to the extent that processing is
      necessary:</p>
    <ul>
      <li>
        <p>for exercising the right of freedom of expression and information;</p>
      </li>
      <li>
        <p>for compliance with a legal obligation under Union or Member State
          law to which the controller is subject or for the performance of
          tasks carried out in the public interest or in the exercise of
          official authority vested in the controller;</p>
      </li>
      <li>
        <p>for reasons of public interest in the area of public health in
          accordance with parts (h) and (i) of Art. 9, paragraph 2 and Art. 9,
          paragraph 3 of the GDPR;</p>
      </li>
      <li>
        <p>for archiving purposes in the public interest, for scientific or
          historical research purposes or for statistical purposes in
          accordance with Art. 89, paragraph 1 of the GDPR, insofar as the
          rights referred to in section (a) are likely to render impossible or
          seriously impair the achievement of the objectives of that
          processing; or</p>
      </li>
      <li>
        <p>for the establishment, exercise or defence of legal claims.</p>
      </li>
    </ul>
    <p><em>e) Right to notification</em></p>
    <p>Where you have exercised the right to correction, deletion or
      restriction of processing with the data controller, the data controller
      shall be obliged to notify all recipients to whom the personal data
      concerning you was disclosed of this correction or deletion of data or
      of the restriction of processing, except where compliance proves to be
      impossible or is associated with a disproportionate effort.</p>
    <p>In addition, you are entitled to require that the data controller inform
      you about these recipients.</p>
    <p><em>f) Right to data portability</em></p>
    <p>You have the right to receive the personal data concerning you, which
      you have provided to the controller, in a structured, commonly used and
      machine-readable format and have the right to transfer that data to
      another controller without hindrance from the controller to which the
      personal data have been provided, where:</p>
    <p>the processing is based on consent pursuant to part (a) of Article 6,
      paragraph 1 or part (a) of Article 9, paragraph 2 of the GDPR or in a
      contract pursuant to part (b) of Art. 6, paragraph 1 of the GDPR; and<br>the processing is carried out by
      automated means.<br>In exercising your right to data portability, you have the right to have
      the personal data concerning you transmitted directly from one
      controller to another, where technically feasible. This must not
      adversely affect the rights and freedoms of other persons.</p>
    <p>The right to data portability does not apply to processing that is
      necessary for the performance of a task carried out in the public
      interest or in the exercise of official authority vested in the
      controller.</p>
    <p><em>g) <strong>Right to object</strong></em></p>
    <p><strong>You have the right to object, at any time, on grounds relating to your
        particular situation, to the processing of personal data concerning you,
        which is based on parts (e) or (f) of Art. 6, paragraph 1 of the GDPR;
        this includes profiling based on those provisions.</strong></p>
    <p><strong>The controller shall no longer process the personal data concerning
        you, unless the controller demonstrates compelling legitimate grounds
        for the processing which override your interests, rights and freedoms or
        for the establishment, exercise or defence of legal claims.</strong></p>
    <p><strong>Where personal data concerning you is processed for direct marketing
        purposes, you have the right to object, at any time, to the processing
        of personal data concerning you for the purpose of such marketing. This
        applies also to profiling to the extent that it is related to such
        direct marketing.</strong></p>
    <p><strong>Where you object to processing for direct marketing purposes, the
        personal data will no longer be processed for such purposes.</strong></p>
    <p><strong>In the context of the use of information society services, and
        notwithstanding directive 2002/58/EC, you may exercise your right to
        object by automated means that use technical specifications.</strong></p>
    <p><strong>Where personal data is processed for scientific or historical research
        purposes or for statistical purposes pursuant to <a href="https://dsgvo-gesetz.de/art-89-dsgvo/" rel="external"
          target="_blank" class="external-link" title="Externer Link ">Art. 89</a>, paragraph 1 of the GDPR, you
        have the right, on grounds relating to your particular situation, to
        object to processing of personal data concerning you, except where the
        processing is necessary for the performance of a task carried out for
        reasons of public interest.</strong></p>
    <p><strong>Should you wish to exercise your right to withdraw consent or to
        object, please send an email to <a href="mailto:datenschutz@dlr.de">datenschutz&#64;dlr.de</a>.</strong></p>
    <p><em>h) Right to withdraw consent pursuant to Art. 7, paragraph 3 of the
        GDPR</em></p>
    <p>You have the right to withdraw your consent to the processing of data at
      any time, with future effect. In the event that you withdraw consent, we
      will delete the data concerned immediately, except where processing can
      be based on legal grounds that do not require consent. The withdrawal of
      consent will not affect the lawfulness of processing carried out prior
      to withdrawal of consent.</p>
    <p><em>i) Automated individual decision-making, including profiling</em></p>
    <p>You have the right not to be subject to a decision based solely on
      automated processing, including profiling, which produces legal effects
      for you or similarly significantly affects you.</p>
    <p>This does not apply if the decision:</p>
    <ul>
      <li>
        <p>is necessary for entering into, or performance of, a contract
          between you and the data controller;</p>
      </li>
      <li>
        <p>is authorised by Union or Member State law to which the controller
          is subject and which also contains suitable measures to safeguard
          your rights, freedoms and legitimate interests; or</p>
      </li>
      <li>
        <p>is based on your explicit consent.</p>
      </li>
    </ul>
    <p>However, these decisions must not be based on special categories of
      personal data referred to in Art 9, paragraph 1 of the GDPR, unless
      parts (a) or (g) of Art. 9, paragraph 2 of the GDPR applies and suitable
      measures to safeguard your rights, freedoms and legitimate interests are
      in place.</p>
    <p>In the cases referred to in parts (1) and (3), the data controller is
      required to implement suitable measures to safeguard your rights,
      freedoms and legitimate interests, including at least the right to
      obtain human intervention on the part of the controller, to express your
      own point of view and to contest the decision.</p>
    <p><em>j) Right to lodge a complaint with a supervisory authority</em></p>
    <p>Without prejudice to any other administrative or judicial remedy, you
      have the right to lodge a complaint with a supervisory authority, in
      particular in the Member State of your normal residence, you place of
      work or the place of the alleged infringement, if you consider that the
      processing of personal data relating to you infringes the GDPR.</p>
    <p>
      The supervisory authority with which the complaint has been lodged is
      required to inform the complainant on the progress and the outcome of
      the complaint, including the possibility of a judicial remedy pursuant
      to Article 78.</p>
  </div>
  <app-footer></app-footer>
</div>
