<div class="app-content scrollable-content">
  <div class="terms pad-footer-bottom" cds-layout="container:lg container:center p:lg p-y:lg">
    <h2>Terms of Use</h2>
    <p></p>
    <h3 id="copyright-and-terms-of-use">Copyright and Terms of use</h3>
    <p>The copyrights of all copyrighted content used on this website, such as,
      but not restricted to images, videos, geospatial data, illustrations and
      texts is held by Deutsches Zentrum fuer Luft- und Raumfahrt e. V., the
      German Aerospace Center, (DLR). For example, "Source: DLR" means that
      the copyrights belong to the Deutsches Zentrum fuer Luft- und Raumfahrt
      e. V., the German Aerospace Center (DLR). Prior to the use of the
      respective copyrighted content you must ask the indicated copyright
      holders for each individual case for their permission (i. e. license) to
      use the respective content.</p>
    <h3 id="creative-commons-license-cc-license">Creative Commons license (CC license)</h3>
    <p>If expressly stated (e.g. in a caption, on a text section, under
      "Information" for the respective work), certain images, videos, images
      and texts are licensed to you under a Creative Commons license. There
      are different variants of CC licenses, the most frequently used version
      is CC-BY (<a href="https://creativecommons.org/licenses/by/3.0/de/" rel="external" target="_blank"
        class="external-link" title="Externer Link ">https://creativecommons.org/licenses/by/3.0/de/</a>). This means
      that you may reproduce, distribute and make the work or content publicly
      available, make modifications and adaptations of the work or content and
      use the respective work commercially if you reproduce the licenser's
      legal notice concerning the rights required in the license text AND make
      the content available under the same license CC-BY 3.0. Examples of how
      DLR usually indicates that it makes its copyrighted content available
      under the terms of this license: "Image: DLR, CC-BY 3.0", "Video:
      DLR, CC-BY 3.0". For a description of other CC license versions, see
      <a href="https://creativecommons.org/licenses/?lang=en" rel="external" target="_blank" class="external-link"
        title="Externer Link ">https://creativecommons.org/licenses/?lang=en</a>
    </p>
    <p>Images, videos, illustrations and texts that have no reference to a CC
      license and for which only the copyright holder concerned is specified
      as the source (e.g.: "Source: DLR") are not under a CC license.</p>
    <h3 id="use-of-dlr-content-by-the-press-radio-stations-and-television-stations">Use of DLR content by the press,
      radio stations and television stations</h3>
    <p>Contents on this website without any reference to special terms of use,
      i.e. without any copyright and license indication at all or which
      contain only DLR's copyright notice without indication of specific terms
      of use (examples: "Image: DLR", "Video: DLR") may be used by the
      press, radio stations, broadcasters and TV stations, also in their
      respective internet offers if DLR is indicated as the source or
      copyright holder. This license does not include or concern personal
      data such as photos or videos of people or personal information such as
      names.</p>
    <h2 id="no-restriction-to-third-party-rights" class="test">No restriction to third party rights</h2>
    <p>The terms of use set out above by the DLR have no influence whatsoever
      on the following third-party rights:</p>
    <ul>
      <li>
        <p>The rights that everyone is entitled to due to the limitations of
          copyright (such as the right to quote) or according to other legal
          permits of use (established in some countries as the so-called
          doctrine of fair use)</p>
      </li>
      <li>
        <p>The copyright holders' moral rights</p>
      </li>
      <li>
        <p>Third party rights, either in the license object itself or with
          regard to its use, for example the general rights to protection of
          personality of persons depicted e. g. in photographs or videos, or
          third-party trademark rights to protected trademarks shown, etc.</p>
      </li>
    </ul>
    <h2 id="no-rights-of-use-for-certain-content" class="test">No rights of use for certain content</h2>
    <p>Any license to use copyrighted material, including the Creative Commons
      license, comprises only the usage rights of copyrighted material and
      does not include permission to use protected trademarks, such as, but
      not restricted to, the DLR logo and its components as well as other
      elements of the DLR corporate identity such as the "Blue Earth". The
      DLR logo and its components may not be used by people who are not DLR
      employees or who do not have a DLR license for usage. You must ask the
      trademark owner for each individual case of use for his or her
      permission to use the protected trademark. </p>
    <h2 id="request-a-right-of-use-if-dlr-is-the-copyright-holder" class="test">Request a right of use if DLR is the
      copyright holder</h2>
    <p>If you would like to inquire about individual rights of use for DLR's
      copyrighted content on this website, please contact:</p>
    <p>Martin Mühlbauer<br>Deutsches Zentrum für Luft- und Raumfahrt e.V.<br>Earth Observation Center<br>Münchener
      Straße 20<br>82234 Weßling<br>Germany</p>
    <p>Email: ukis-helpdesk [at] dlr.de</p>
    <h2 id="rights-of-use-for-content-if-the-copyright-holder-is-not-dlr" class="test">Rights of use for content if the
      copyright holder is not DLR</h2>
    <p>
      Certain images, videos, illustrations or texts from this website
      originate from different sources, other than DLR. Please contact the
      indicated copyright holders regarding their applicable terms of use and
      for obtaining a license for use from them. Certain images, videos,
      illustrations or texts have multiple copyright holders. Those copyright
      holders are indicated accordingly. In this case, the terms of use of all
      of the indicated copyright holders apply.</p>
  </div>
  <app-footer></app-footer>
</div>

