function t() {
  return t => t.addInitializer(t => new e(t));
}
class e {
  constructor(t) {
    this.host = t, this.selectionActive = !1, t.addController(this);
  }
  get enabled() {
    return !1 !== this.host.rangeSelection && !Array.from(this.host.rows).find(t => t.draggable);
  }
  async hostConnected() {
    await this.host.updateComplete, this.setupKeyboardListeners(), this.setupMouseEvents();
  }
  setupMouseEvents() {
    const t = this.host.grid.shadowRoot ? this.host.grid.shadowRoot : this.host.grid;
    t.addEventListener("mousedown", t => {
      this.enabled && 1 === t.buttons && !t.ctrlKey && this.setFirstCell(t);
    }), t.addEventListener("mouseover", t => {
      this.enabled && this.setActiveCell(t.composedPath().find(t => Array.from(this.host.cells).includes(t)));
    }), t.addEventListener("mouseup", () => {
      this.enabled && this.stopSelection();
    });
  }
  setupKeyboardListeners() {
    this.host.addEventListener("cdsKeyChange", t => {
      this.enabled && t.detail.code && (this.setActiveCell(t.detail.activeItem), t.detail.shiftKey || (this.stopSelection(), this.resetAllActiveCells(), this.host.dispatchEvent(new CustomEvent("rangeSelectionChange", {
        detail: []
      }))));
    }), this.host.addEventListener("keydown", t => {
      this.enabled && "ShiftLeft" === t.code && t.shiftKey && !this.selectionActive && this.setFirstCell(t);
    });
  }
  setFirstCell(t) {
    const e = t.composedPath().find(t => Array.from(this.host.cells).includes(t));
    e && (this.firstCell = e, this.selectionActive = !0, this.resetAllActiveCells());
  }
  setActiveCell(t) {
    t && this.selectionActive && (this.activeCell = t, this.calculateSelection());
  }
  stopSelection() {
    this.selectionActive = !1;
  }
  resetAllActiveCells() {
    this.host.cells.forEach(t => t.removeAttribute("highlight"));
  }
  calculateSelection() {
    const t = parseInt(this.firstCell.ariaColIndex),
      e = parseInt(this.activeCell.ariaColIndex),
      s = parseInt(this.firstCell.parentElement?.ariaRowIndex),
      i = parseInt(this.activeCell.parentElement?.ariaRowIndex);
    this.resetAllActiveCells(), this.host.cells.forEach(l => {
      const h = parseInt(l.ariaColIndex),
        n = parseInt(l.parentElement?.ariaRowIndex);
      (t <= e && h >= t && h <= e || t >= e && h <= t && h >= e) && (s <= i && n >= s && n <= i || s >= i && n <= s && n >= i) && l.setAttribute("highlight", "");
    }), this.host.dispatchEvent(new CustomEvent("rangeSelectionChange", {
      detail: Array.from(this.host.cells).filter(t => t.hasAttribute("highlight"))
    }));
  }
}
export { e as GridRangeSelectionController, t as gridRangeSelection };
