export const banner = {
    title: `Create Geospatial Web Applications with UKIS`,
    description: `UKIS is a set of technologies that enables software 
      developers to build diverse and user-friendly web applications 
      with geospatial data
      `,
    image: 'assets/images/ukis-logo_with_images.png'
}

const technologiesAction = {
    title: `Get started with UKIS Frontend`,
    subTitle: `UKIS Frontend`,
    questionTitle: `UI for Geospatial Apps`,
    questions: [
        'Built on the [Angular platform](https://angular.io/) and TypeScript to streamline the development and build process.',
        `Built on the [Clarity Design System](https://clarity.design) with many UI components and styling guidelines`,
        `For demo applications, check out our [map demo project](https://dlr-eoc.github.io/ukis-frontend-libraries/#/examples) and its [source code](https://github.com/dlr-eoc/ukis-frontend-libraries/tree/main/projects/demo-maps), or the [authentication demo source code](https://github.com/dlr-eoc/ukis-frontend-libraries/tree/main/projects/demo-auth) with [Angular route guards](https://github.com/dlr-eoc/ukis-frontend-libraries/blob/main/projects/demo-auth/src/app/auth/auth-guard.service.ts).`
    ],
    link: 'https://github.com/dlr-eoc/ukis-frontend-libraries',
    image: 'assets/images/technologies.png'
};

const githubAction = {
    title: `Visit our GitHub page`,
    subTitle: `Benefit and Contribute`,
    questionTitle: `Open Technology`,
    questions: [
        'UKIS is a set of [open source](https://github.com/search?q=org%3Adlr-eoc+ukis) technologies',
        'Extensive open software basis enables rapid software development',
        'Found a bug? Missing a feature? We are looking forward to your pull requests.'
    ],
    link: 'https://github.com/search?q=org%3Adlr-eoc+ukis',
    image: 'assets/images/code-dev.png'
};

const systemsAction = {
    title: `Sample Systems`,
    subTitle: `Reliability and Versatility`,
    questionTitle: `Why UKIS?`,
    questions: [
        'Proven user-friendly technology',
        'Enables developers to create stable and maintainable software',
        'Can be applied in non-commercial and commercial projects'
    ],
    link: '/systems',
    image: 'assets/images/refPl-2022.png'
};

const featuresAction = {
    title: `Discover Features`,
    subTitle: `Features`,
    questionTitle: `What is Possible?`,
    questions: [
        'Visualize and analyze geospatial data in a web browser',
        'Let users browse through multitemporal geospatial data in 2D and 3D',
        'Add statistics, diagrams and much more'
    ],
    link: '/features',
    image: 'assets/images/features-2023.png'
};

export const actions = [systemsAction, featuresAction, technologiesAction, githubAction];
export const allItems = [...actions, banner];