import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusTypes } from '@cds/core/internal';
import { SortPipe } from '../../pipes/sort.pipe';
import { CdsIconModule } from '@cds/angular/icon';
import { CdsTagModule } from '@cds/angular/tag';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-truncate-tag-list',
  templateUrl: './truncate-tag-list.component.html',
  styleUrls: ['./truncate-tag-list.component.scss'],
  standalone: true,
  imports: [CommonModule, CdsTagModule, CdsIconModule, SortPipe]
})
export class TruncateTagListComponent implements OnInit {
  @Input() tags!: string[];
  @Input() activeTags!: string[];
  @Input() maxNr: number = 5;
  @Output() tagClicked = new EventEmitter<string>();
  public expanded = false;

  constructor() { }

  ngOnInit(): void {

  }

  public onTagClicked(tag: string) {
    const lowerTag = tag.toLocaleLowerCase();
    this.tagClicked.emit(lowerTag);
  }

  public tagIsActive(tag: string): StatusTypes {
    const lowerTag = tag.toLocaleLowerCase();
    if (this.activeTags) {
      return (this.activeTags.indexOf(lowerTag) !== -1) ? 'info' : 'neutral';
    } else {
      return 'neutral';
    }
  }

}