import { Injectable } from '@angular/core';
import { Iproject, projects } from '../data/ukis-projects';
import { ukis_features } from '../data/ukis-features';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private projects = projects;
  private features = ukis_features;
  constructor() { }

  getProjects(type: 'all' | 'active' | 'inactive' = 'all') {
    this.projects = this.projects.map(p => this.setMissingPointOfContact(p));
    if (type === 'active') {
      return this.projects.filter(i => i.inactive !== true).filter(i => i.exclude !== true);
    } else if (type === 'inactive') {
      return this.projects.filter(i => i.inactive === true).filter(i => i.exclude !== true);
    } else {
      return this.projects.filter(i => i.exclude !== true);
    }
  }

  setMissingPointOfContact(p:Iproject){
    if(!p.pointOfContact){
      p.pointOfContact = [];
    }
    if(!p.pointOfContact.length){
      p.pointOfContact.push('ukis-helpdesk@dlr.de')
    }
    return p;
  }

  getTags() {
    const t = this.projects.reduce((p: any, c) => {
      if (c.tags) {
        return p.concat(c.tags);
      }
      return p;
    }, []);

    return [...new Set(t)] as string[];
    /* this.projects.forEach(p => {
      if (p.tags) {
        p.tags.forEach(t => {
          if (tags.indexOf(t) === -1) {
            tags.push(t);
          }
        });
      }
    }); */
    // return tags;
  }

  getFeatures(ids: string[]) {
    this.features.filter(f => ids.includes(f.id));
  }

  getPreviewImage(p: Iproject) {
    return p.images[p.previewImage].link;
  }

  /** e.g. DFD-GZS:OP -> DFD-GZS*/
  getParticipant(p: string) {
    const canSplit = p.split(':');
    if (canSplit.length > 1) {
      return canSplit[0];
    } else {
      return p;
    }
  }

  /** e.g. https://gitlab.dlr.de/ukis-frontend/project-flood-h3 -> project-flood-h3 */
  getGitRepoName(p: string) {
    const canSplit = p.split('/');
    if (canSplit.length > 1) {
      return canSplit[canSplit.length - 1];
    } else {
      return p;
    }
  }

  concatDesc(p: Iproject) {
    return p?.description?.join(' ');
  }


  getProject(id: Iproject['id']) {
    return this.getProjects().find(p => p.id === id);
  }

  getDateList(key: 'startDate' | 'lastUpdatedIn') {
    // https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
    // const dates = this.getProjects().map(i => i[key]).filter((i): i is string => !!i);

    //only years
    const dates = this.getProjects().map(i => i[key]?.split('-')[0]).filter((i): i is string => !!i);
    // https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
    return [...new Set(dates)].sort((a, b) => a.localeCompare(b));
  }
}

export function sortSystems(a: Iproject, b: Iproject, type: 'title' | 'date' | 'random' = 'title') {
  if (type === 'title') {
    return a.title.localeCompare(b.title);
  } else if (type === 'date') {
    return new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf();
  } else if (type === 'random') {
    return Math.random() - 0.5;
  } else {
    return 1;
  }
}

export function checkLinkExternal(link: string) {
  if (link.indexOf('http') === -1) {
    return false;
  } else {
    return true;
  }
}

export function adjustImageLinkSize(link: string, width: number | string) {
  const ext = link.split('.').pop();
  if (environment.production) {
    return link.replace(`.${ext}`, `_${width}.${ext}`);
  } else {
    return link;
  }
}

