function t() {
  return t => t.addInitializer(t => new e(t));
}
class e {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  hostUpdated() {
    void 0 !== this.host.selectable && null !== this.host.selectable ? this.host.ariaMultiSelectable = "multi" === this.host.selectable ? "true" : "false" : this.host.ariaMultiSelectable = null;
  }
}
export { e as AriaMultiSelectableController, t as ariaMultiSelectable };
