import { listenForAttributeChange as t } from "../utils/events.js";
import { getActiveElement as s } from "../utils/focus.js";
function e(t = {
  focus: !0
}) {
  return s => s.addInitializer(s => new r(s, t));
}
class r {
  constructor(t, e = {
    focus: !0
  }) {
    this.host = t, this.config = e, this.activeElement = s(), this.host.addController(this);
  }
  get current() {
    return this.host.trigger ? this._current : this.activeElement;
  }
  get prev() {
    return this._prev;
  }
  async hostConnected() {
    this.observer = t(this.host, "hidden", () => {
      const t = s();
      !this.host.hidden && t ? this.activeElement = t : this.focusCurrent();
    });
  }
  hostUpdate() {
    this._current !== this.host.trigger && (this._prev = this._current, this._current = this.host.trigger ? this.host.trigger : this.activeElement);
  }
  hostDisconnected() {
    this.focusCurrent(), this.observer?.disconnect();
  }
  focusCurrent() {
    this.config.focus && this.current?.focus();
  }
}
export { r as TriggerController, e as triggerable };
