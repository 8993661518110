import { PRIVATE_ANIMATION_STATUS_ATTR_NAME as t, AnimationStatus as i } from "../motion/interfaces.js";
import { runPropertyAnimations as o } from "../motion/utils.js";
import { isJsdomTest as n } from "../utils/environment.js";
function e(e) {
  return n() ? function () {} : function (n) {
    return class extends n {
      constructor() {
        super(...arguments);
        this._animationReady = !1, this._animationDemoMode = !1, this._animations = e;
      }
      updated(n) {
        if (super.updated(n), !this._animationReady && !this.hasAttribute(t)) return this.setAttribute(t, i.ready), void (this._animationReady = !0);
        !0 === this._animationDemoMode || this.hasAttribute("_demo-mode") ? this._animationDemoMode = !0 : o(n, this);
      }
    };
  };
}
export { e as animate };
