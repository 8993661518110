<div cds-text="header" class="app-container">
  <header class="app-header">
    <div class="responsive-header" [ngClass]="{'open':navOpen}">
      <div class="header-branding">
        <a class="nav-link responsive-burger" cds-layout="p:md p-r:none" (click)="toggleNav($event)">
          <cds-icon class="burger" shape="bars" size="md"></cds-icon>
          <cds-icon class="close" shape="times" size="md"></cds-icon>
        </a>

        <a class="nav-link logo" cds-layout="p:md p-r:none" href="https://www.dlr.de/" target="_blank">
          <cds-icon shape="dlr-icon" size="lg"></cds-icon>
        </a>
        <a class="nav-link logo" cds-layout="p:md p-r:none" [routerLink]="['/home']">
          <cds-icon shape="ukis-icon" size="lg"></cds-icon>
        </a>
        <a class="nav-link" cds-layout="p:md" [routerLink]="['/home']">
          <span class="title">{{title}}</span>
        </a>
      </div>
      <div class="header-nav">
        @for (route of headerRoutes; track route.path) {
          <a routerLink="/{{route.path}}" routerLinkActive="active"
          class="nav-link nav-text header-nav-el" cds-layout="p:md">{{route.data?.['title']}}</a>
        }
      </div>
    </div>
    <div class="header-backdrop" [ngClass]="{'open':navOpen}" (click)="closeNav($event)"></div>
  </header>
  <router-outlet></router-outlet>
</div>