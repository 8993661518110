import { listenForAttributeChange as t } from "../utils/events.js";
import { TriggerController as r } from "./trigger.controller.js";
function e() {
  return t => t.addInitializer(t => new i(t));
}
class i {
  constructor(t) {
    this.host = t, this.host.addController(this), this.trigger = new r(this.host, {
      focus: !1
    });
  }
  async hostConnected() {
    await this.host.updateComplete, this.observer = t(this.host, "hidden", () => this.updateTrigger(!this.host.hidden));
  }
  async hostUpdate() {
    await this.host.updateComplete, this.updateTrigger(!this.host.hidden);
  }
  hostDisconnected() {
    this.updateTrigger(!1), this.observer?.disconnect();
  }
  updateTrigger(t) {
    this.trigger.current?.hasAttribute("aria-controls") && (this.trigger.current.ariaExpanded = "" + t), this.trigger.prev?.hasAttribute("aria-controls") && this.trigger.prev !== this.trigger.current && (this.trigger.prev.ariaExpanded = "false");
  }
}
export { i as AriaPopupController, e as ariaPopup };
