<div class="app-content scrollable-content" id="scrollableContainer" (click)="closeFilterMenu($event)">
  <!-- grid gap:md gap@md:xl p:lg p@sm:xl p-y@lg:xxl container:lg container:center -->

  <div class="pad-footer-bottom">
    <div class="filters">
      <cds-button aria-controls="filter" tabindex="1" action="flat" size="md"
        (click)="stopEvent($event);toggleFilterMenu()" id="filter-button" [ngClass]="{'active': filtersActive}">
        <cds-icon shape="filter-grid-circle" size="md" [attr.solid]="showFilterOptions"></cds-icon>
      </cds-button>

      @if (showFilterOptions) {
      <form [formGroup]="filterSvc.filterForm" (click)="stopEvent($event)">
        @for (item of filterSvc.filtersArray; track item) {
        <div class="from-filter-item">
          <div class="check-and-toggle">
            <input type="checkbox" class="custom-clr-checkbox" clrCheckbox [checked]="!item.fc.disabled"
              (change)="toggleFilter(item.fc)" />

            <label>{{item.title || item.key}}</label>


            @if (item.fc2) {
            <label>
              <input type="checkbox" class="custom-clr-checkbox" clrCheckbox [checked]="item.fc2.value"
                (change)="filter2(item.fc2, item.fc)" />
              <label>til now</label>
            </label>
            }

          </div>

          @if (item.type === 'number') {
          <input class="custom-clr-input" clrInput type="number" [placeholder]="item.fc.value || 'Enter value'"
            [formControl]="item.fc" />
          }
          @if (item.type === 'text') {
          <input class="custom-clr-input" clrInput type="text" [placeholder]="item.fc.value || 'Enter title'"
            [formControl]="item.fc" />
          }

          <!-- https://angular.io/guide/template-expression-operators#any-type-cast-function -->
          @if (item.key === 'tags') {
          @if (item.type === 'select') {
          <ng-select [class.hide-arrow]="false" [clearable]="true" [searchable]="true" [multiple]="true"
            placeholder="- set tags -" [items]="$any(item).options" bindLabel="title" bindValue="value"
            [formControlName]="item.key">
          </ng-select>
          }
          } @else {
          @if (item.type === 'select') {
          <ng-select [class.hide-arrow]="false" [clearable]="false" [searchable]="true" [items]="$any(item).options"
            bindLabel="title" bindValue="value" [formControlName]="item.key">
          </ng-select>
          }
          }
        </div>
        }
      </form>
      }
    </div>



    <!-- container:lg container:center m-b:xl -->
    @if (systems.length) {
    <section
      cds-layout="container:lg container:center p-t:lg p-b@md:md p-x:md p-x@xs:xl p-x@sm:xl p-x@md:xxl p-t:lg p-t@sm:xxl">
      <h1 cds-text="display" cds-layout="m-b:md m-t:xl m-t@xs:xxl">Our Systems</h1>
    </section>

    <section
      cds-layout="grid cols@sm:6 cols@md:6 gap:lg gap@md:xxl align:center container:lg container:center m-b@xs:md p-y:md p-x:md p-x@xs:xl p-x@sm:xl p-x@md:xxl">
      <!-- only if filter active systems use observe  -->
      @for (s of systems; track s; let i = $index) {
      <div class="cds-card clickable grid-card">
        <app-list-system-card [system]="s" (tagClicked)="onTagClicked($event)"
          [tagFilter]="filterSvc.filters.tags?.fc?.value"></app-list-system-card>
      </div>
      }
    </section>

    <section
      cds-layout="container:lg container:center m-b:md p-y@xs:md horizontal align:right p-x:md p-x@xs:xl p-x@sm:xl p-x@md:xxl">
      <div cds-text="body light">{{systems.length}} Items</div>
    </section>
    }


    @if (systemsInactive.length) {
    <section cds-layout="container:lg container:center p-t:lg p-b@md:md p-x:md p-x@xs:xl p-x@sm:xl p-x@md:xxl">
      <h1 cds-text="display center" cds-layout="m-b:md m-t:xl m-t@xs:xxl">Inactive Systems</h1>
    </section>

    <section
      cds-layout="grid cols@sm:6 cols@md:6 gap:lg gap@md:xxl align:center container:lg container:center m-b@xs:md p-y:md p-x:md p-x@sm:xl p-x@md:xxl">
      @for (s of systemsInactive; track s; let i = $index) {
      <div class="cds-card clickable grid-card">
        <app-list-system-card [system]="s" (tagClicked)="onTagClicked($event)"
          [tagFilter]="filterSvc.filters.tags?.fc?.value"></app-list-system-card>
      </div>
      }
    </section>

    <section
      cds-layout="container:lg container:center m-b:md p-y@xs:md horizontal align:right p-x:md p-x@xs:xl p-x@sm:xl p-x@md:xxl">
      <div cds-text="body light">{{systemsInactive.length}} Items</div>
    </section>
    }
  </div>
  <app-footer></app-footer>
</div>