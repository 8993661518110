<div class="app-content scrollable-content">
  @if (system) {
  <div>
    <section cds-layout="container:lg container:center p-x:md p-x@xs:xl p-x@sm:xxl p-y:lg p-y@sm:xxl">
      <!-- title -->
      <h1 cds-text="display" cds-layout="m-b:md m-t:xl m-t@xs:xxl">{{system.title}}</h1>

      <!-- System description mobile -->
      @for (p of system.description; track p) {
      <p cds-layout="m-t:xl m-t@md:xxl m-b:xl" cds-text="section" [innerHTML]="p | markdownLink">
      </p>
      }
    </section>



    <section class="image-section" cds-layout="container:lg container:center m-b:md p:md p-x:md p-x@xs:xl p-x@sm:xxl">
      @for (item of images; track item; let i = $index) {
      <div class="item" [ngClass]="isClassEven(i)"
        cds-layout="grid cols@sm:6 cols@md:6 gap:sm gap@md:xxl m-b:xxl align:vertical-center">

        <a class="img-link" cds-layout="display:none display@md:block p@md:sm">
          <!-- <img [src]="item.link" (click)="openFullscreen(item)" /> -->
          <img [ngSrc]="item.link" width="1920" height="1075" (click)="openFullscreen(item)" sizes="45vw" />
        </a>

        <a class="img-link" cds-layout="display:block display@md:none p:md"
          [href]="adjustImageLinkSize(item.link, 1920)" target="_blank">
          <!-- <img [src]="item.link" /> -->
          <img [ngSrc]="item.link" width="1920" height="1075" sizes="95vw" />
        </a>

        <div class="image-text" cds-layout="p-t@md:sm p-x:md">
          <div cds-layout="vertical align:vertical-center">
            <div aria-hidden="true" cds-layout="grid gap:md m-t:md m-t@sm:none">
              <div cds-layout="col:2" class="content-separator"></div>
            </div>

            <h3 cds-text="title" cds-layout="m-t:md">{{item.title}}</h3>
            <p cds-text="subsection left light" cds-layout="m-t:lg m-b:md"
              [innerHTML]="item.description | markdownLink"></p>
          </div>
        </div>
      </div>
      }

      @if (fullscreenImg) {
      <cds-modal class="custom-xxl" aria-labelledby="img modal" [closable]="true" (closeChange)="openFullscreen()">
        <cds-modal-content>
          <!-- <p cds-text="body" [innerHTML]="fullscreenImg.description | markdownLink"></p> -->

          <a [href]="adjustImageLinkSize(fullscreenImg.link , 1920)" target="_blank">
            <!-- <img [src]="fullscreenImg.link" /> -->
            <img [ngSrc]="fullscreenImg.link" width="1920" height="1075" />
          </a>
        </cds-modal-content>
      </cds-modal>
      }
    </section>

    <!-- props text and attributes -->
    <section class="info-section">
      <div cds-layout="p-x:md p-x@sm:xl container:lg container:center">
        <h4 cds-text="headline" cds-layout="p-x:sm p-t:xl">Further Information</h4>
      </div>

      <div class="prop-text"
        cds-layout="grid cols@sm:4 gap:md gap@md:xl p:md p@sm:xl container:lg container:center align-horizontal:center">

        <!-- comment -->
        @if (system.commentIn) {
        <div cds-layout="col:12 gap:md p:sm">
          <span cds-text="left message">{{system.commentIn}}</span>
        </div>
        }

        <!-- Dates and Links -->
        <div cds-layout="vertical gap:md p:sm">
          <div aria-hidden="true" cds-layout="grid gap:md m-t:md m-t@sm:none">
            <div cds-layout="col:2" class="content-separator"></div>
          </div>
          <!-- startDate -->
          <p cds-text="message" cds-layout="m-t:md">Initialised:</p>
          <p cds-text="message">{{system.startDate | date:'MM.y'}}</p>
          <!-- last updated -->
          @if (system.lastUpdatedIn) {
          <p cds-text="message" cds-layout="m-t:md">Last Updated:</p>
          }
          @if (system.lastUpdatedIn) {
          <p cds-text="message">{{system.lastUpdatedIn | date:'MM.y'}}</p>
          }
          <div cds-layout="m-t:md"></div>
          @for (g of system.gitIn; track g) {
          <cds-button action="flat-inline"><a [href]="g" target="_blank">View the Source Code</a>
          </cds-button>
          }
          <!-- urlInternal -->
          @if (system.urlIn) {
          @for (s of system.urlIn; track s) {
          <cds-button action="flat-inline"><a [href]="s" target="_blank" [title]="s">Go to
              internal System</a>
          </cds-button>
          }
          }
          <!-- link to public system -->
          @if (system.url) {
          @for (s of system.url; track s) {
          <cds-button action="flat-inline"><a [href]="system.url" target="_blank" [title]="s">Go to Live
              System</a>
          </cds-button>
          }

          }
          <!-- link to project website -->
          @if (system.projectUrl) {
          <cds-button action="flat-inline"><a [href]="system.projectUrl" target="_blank">Further Information</a>
          </cds-button>
          }
        </div>

        <!-- contacts -->
        <div cds-layout="vertical gap:md p:sm">
          <div aria-hidden="true" cds-layout="grid gap:md m-t:md m-t@sm:none">
            <div cds-layout="col:2" class="content-separator"></div>
          </div>
          <!-- responsible -->
          @if (system.responsibleIn) {
          <p cds-text="message" cds-layout="m-t:md">Responsible:</p>
          }
          @if (system.responsibleIn) {
          <div cds-layout="horizontal gap:xs">
            @for (responsible of system.responsibleIn; track responsible) {
            <cds-tag readonly color="light-blue">
              <cds-icon shape="employee" role="img" alt="code"
                style="cursor:default;"></cds-icon>{{getParticipant(responsible)}}
            </cds-tag>
            }
          </div>
          }
          <!-- participants -->
          <p cds-text="message" cds-layout="m-t:md">Contributors:</p>
          <div cds-layout="horizontal gap:xs">
            @for (participant of system.participantsIn; track participant) {
            <cds-tag readonly color="light-blue">
              <cds-icon shape="employee" role="img" alt="code"
                style="cursor:default;"></cds-icon>{{getParticipant(participant)}}
            </cds-tag>
            }
          </div>
          <!-- point of contact -->
          <p cds-text="message" cds-layout="m-t:md">Point of Contact:</p>
          <p cds-text="message">{{system.pointOfContact}}</p>
        </div>

        <!-- software / tags -->
        <div cds-layout="vertical gap:md p:sm">
          <div aria-hidden="true" cds-layout="grid gap:md m-t:md m-t@sm:none">
            <div cds-layout="col:2" class="content-separator"></div>
          </div>
          <!-- tags -->
          <p cds-text="message" cds-layout="m-t:md">Tags:</p>
          <div cds-layout="horizontal gap:xs">
            @for (tag of system.tags; track tag) {
            <cds-tag readonly color="gray">
              <cds-icon shape="tag" role="img" alt="tag" style="cursor:default;"></cds-icon>{{tag}}
            </cds-tag>
            }
          </div>
          <!-- software -->
          <p cds-text="message" cds-layout="m-t:md">Software:</p>
          <div cds-layout="horizontal gap:xs">
            @for (software of system.softwareIn; track software) {
            <cds-tag readonly color="purple">
              <cds-icon shape="code" role="img" alt="code" style="cursor:default;"></cds-icon>{{software}}
            </cds-tag>
            }
          </div>


          <!-- is: inactive or featured  -->

          <p cds-text="message" cds-layout="m-t:md">System Status:</p>
          <div cds-layout="horizontal gap:xs">
            <cds-tag readonly color="light-blue">
              <cds-icon shape="launchpad" role="img" alt="active or not" [attr.solid]="!system.inactive"
                style="cursor:default;"></cds-icon>{{getInactiveTitle(system)}}
            </cds-tag>

            @if (system.featured) {
            <cds-tag readonly color="light-blue">
              <cds-icon shape="image-gallery" role="img" alt="featured or not"
                style="cursor:default;"></cds-icon>Featured
            </cds-tag>
            }
          </div>
        </div>
      </div>

    </section>
  </div>
  }
  <app-footer></app-footer>
</div>