import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MarkdownLinkPipe } from '../../pipes/markdown-link.pipe';
import { CdsIconModule } from '@cds/angular/icon';
import { CdsButtonModule } from '@cds/angular/button';
import { TruncateTagListComponent } from '../truncate-tag-list/truncate-tag-list.component';
import { NgOptimizedImage, DatePipe, CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { ClarityIcons, detailsIcon } from "@cds/core/icon";
ClarityIcons.addIcons(...[detailsIcon]);


@Component({
  selector: 'app-list-system-card',
  templateUrl: './list-system-card.component.html',
  styleUrls: ['./list-system-card.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, NgOptimizedImage, RouterLinkActive, TruncateTagListComponent, CdsButtonModule, CdsIconModule, DatePipe, MarkdownLinkPipe]
})
export class ListSystemCardComponent implements OnInit {
  @Input('system') s: any
  img = {
    url: ''
  }

  @Input() tagFilter!: string[];
  @Output() tagClicked = new EventEmitter<string>();
  constructor(private dataSvc: DataService) { }

  getPreviewImage = this.dataSvc.getPreviewImage;
  concatDesc = this.dataSvc.concatDesc;

  ngOnInit(): void {
    this.img.url = this.getPreviewImage(this.s);
  }

  onTagClicked(tag: string) {
    this.tagClicked.emit(tag);
  }

  getActiveFilters() {
    return this.tagFilter;
  }
}
