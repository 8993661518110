import { listenForAttributeChange as t } from "../utils/events.js";
import { GlobalStateService as e } from "../services/global.service.js";
function s() {
  return t => {
    t.addInitializer(t => {
      t.layerController || (t.layerController = new r(t));
    });
  };
}
class r {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  get isActiveLayer() {
    return this.layers[this.layers.length - 1] === this.host;
  }
  get layerIndex() {
    const t = this.layers.indexOf(this.host);
    return -1 === t ? null : t;
  }
  get layers() {
    return [...e.state.layerElements];
  }
  set layers(t) {
    e.state.layerElements = [...t];
  }
  hostConnected() {
    this.host.hasAttribute("_demo-mode") || (this.updateLayer(), this.observer = t(this.host, "hidden", () => this.updateLayer()));
  }
  hostDisconnected() {
    this.observer?.disconnect(), this.removeLayer();
  }
  updateLayer() {
    this.host.hasAttribute("hidden") ? this.removeLayer() : this.addLayer(), this.layers.forEach((t, e) => t.setAttribute("cds-layer", "" + e));
  }
  addLayer() {
    this.layers.find(t => this.host === t) || (this.layers = [...this.layers, this.host], "true" === this.host.ariaModal && (this.layers.filter(t => "true" === t.ariaModal).forEach(t => t.role = "region"), this.host.role = "dialog"));
  }
  removeLayer() {
    this.layers = this.layers.filter(t => t !== this.host), this.host.removeAttribute("cds-layer");
    const t = this.layers.find(t => "true" === t.ariaModal);
    t && (t.role = "dialog");
  }
}
export { r as LayerController, s as layer };
