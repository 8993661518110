import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true
})
export class SortPipe implements PipeTransform {

  transform(value: string[], order: 'asc' | 'desc' = 'desc') {
    return value.sort((a, b) => {
      if (order === 'asc') {
        return b.localeCompare(a); // https://stackoverflow.com/a/45544166/10850021
      } else {
        return a.localeCompare(b);
      }
    });
  }

}
