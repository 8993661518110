import { Routes } from '@angular/router';
import { MainComponent } from './views/main/main.component';
import { AboutComponent } from './views/about/about.component';
import { ListViewComponent } from './views/list-view/list-view.component';
import { DetailViewComponent } from './views/detail-view/detail-view.component';
import { FeaturesComponent } from './views/features/features.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { AccessibilityComponent } from './views/accessibility/accessibility.component';
import { TermsComponent } from './views/terms/terms.component';

export const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home', component: MainComponent,
        data: {
            title: 'Home',
            header: true
        }
    },
    {
        path: 'systems', component: ListViewComponent, data: {
            title: 'Systems',
            header: true
        },
        /*
        If children is used the view is rendered inside the parent view, we want to render each view separated so css classes are fit the layout
        children: [
          {
            path: ':id', // child route path
            component: DetailViewComponent, // child route component that the router renders
          }
        ] */

    },
    {
        path: 'systems/:id', component: DetailViewComponent, data: {
            title: 'systems'
        }
    },
    {
        path: 'features', component: FeaturesComponent, data: {
            title: 'Features',
            header: true
        }
    },
    {
        path: 'about', component: AboutComponent, data: {
            title: 'About',
            header: true
        }
    },
    {
        path: 'imprint', component: ImprintComponent, data: {
            title: 'Imprint',
            footer: true
        }
    },
    {
        path: 'privacy', component: PrivacyComponent, data: {
            title: 'Privacy',
            footer: true
        }
    },
    {
        path: 'accessibility', component: AccessibilityComponent, data: {
            title: 'Accessibility',
            footer: true
        }
    },
    {
        path: 'terms', component: TermsComponent, data: {
            title: 'Terms of Use',
            footer: true
        }
    }
];
