function t() {
  return t => t.addInitializer(t => new o(t));
}
class o {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  hostConnected() {
    this.host.ariaModal = "true", this.host.role = "dialog";
  }
}
export { o as AriaModalController, t as ariaModal };
