import { ignoreFocus as s, focusable as t, focusElement as o } from "../utils/focus.js";
import { listenForAttributeChange as i } from "../utils/events.js";
import { getFlattenedDOMTree as c } from "../utils/traversal.js";
function h(s = {
  fallback: "focusable"
}) {
  return t => t.addInitializer(t => new e(t, s));
}
class e {
  constructor(s, t = {
    fallback: "focusable"
  }) {
    this.host = s, this.config = t, this.host.addController(this);
  }
  async hostConnected() {
    await this.host.updateComplete, this.observer = i(this.host, "hidden", () => this.cdsFocusFirst()), this.cdsFocusFirst();
  }
  hostDisconnected() {
    this.observer?.disconnect();
  }
  cdsFocusFirst() {
    if (!s(this.host)) {
      const s = this.host.shadowRoot ? this.host.shadowRoot : this.host,
        i = s.querySelector(".private-host") ?? this.host,
        h = c(s).filter(s => !s.hasAttribute("cds-focus-boundary")),
        e = h.find(s => s.hasAttribute("cds-first-focus")),
        r = "focusable" === this.config.fallback ? h.find(s => t(s) && !s.classList.contains("private-host")) : null,
        a = "none" === this.config.fallback ? null : i,
        n = e ?? r ?? a;
      n && o(n);
    }
  }
}
export { e as FirstFocusController, h as firstFocus };
