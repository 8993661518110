import { querySelectorRoots as e } from "../utils/dom.js";
import { onFirstInteraction as t, onChildListMutation as r } from "../utils/events.js";
import { createId as a } from "../utils/identity.js";
import { KeyNavigationListController as s } from "./key-navigation-list.controller.js";
let i = null;
function d(e) {
  return t => t.addInitializer(t => new n(t, e));
}
class n {
  constructor(e, t) {
    this.host = e, this.id = "__" + a(), this.host.addController(this), this.config = {
      layout: "both",
      item: "",
      dropZone: "",
      manageFocus: !0,
      manageTabindex: !1,
      ...t
    };
  }
  get items() {
    return e(this.host, this.config.item + '[draggable="true"]');
  }
  get dropZones() {
    return e(this.host, this.config.dropZone + '[draggable="false"]');
  }
  async hostConnected() {
    await this.host.updateComplete, t(this.host).then(() => {
      this.addDragEventListeners(this.items), this.initializeKeyListController(), this.host.addEventListener("click", async e => this.clickItem(e)), this.host.addEventListener("cdsKeyChange", e => this.focusItem(e)), this.host.shadowRoot?.addEventListener("click", async e => this.clickItem(e)), this.host.shadowRoot?.addEventListener("cdsKeyChange", e => this.focusItem(e)), this.observer = r(this.host, e => {
        if (e) {
          const t = Array.from(e.addedNodes).filter(e => e.draggable);
          t.length && this.addDragEventListeners(t);
        }
      });
    });
  }
  hostDisconnected() {
    this.observer?.disconnect();
  }
  initializeKeyListController() {
    Object.defineProperty(this.host, this.id, {
      get: () => this.items.map(e => e.querySelector("[cds-draggable]"))
    }), new s(this.host, {
      layout: this.config.layout,
      keyListItems: this.id,
      manageFocus: this.config.manageFocus,
      manageTabindex: this.config.manageTabindex
    });
  }
  clickItem(e) {
    const t = Array.from(e.composedPath()).find(e => e.getAttribute && "handle" === e.getAttribute("cds-draggable")),
      r = e.composedPath()[0].closest("[draggable]");
    t && r && ("true" === t.ariaPressed ? (r.setAttribute("cds-draggable", "active"), o(e.currentTarget, r, null, "grabbed", "touch")) : "false" === t.ariaPressed && (r.removeAttribute("cds-draggable"), o(e.currentTarget, r, null, "dropped", "touch")));
  }
  focusItem(e) {
    if (e.detail.keyListItems === this.id && "active" === e.detail.previousItem?.closest("[draggable]").getAttribute("cds-draggable")) {
      const t = e.detail.previousItem?.closest("[draggable]"),
        r = e.detail.activeItem.closest("[draggable]");
      "handle" === e.detail.activeItem.getAttribute("cds-draggable") && t !== r && (e.detail.previousItem.ariaPressed = "false", e.detail.activeItem.ariaPressed = "true", e.detail.previousItem.pressed = !1, e.detail.activeItem.pressed = !0, t.removeAttribute("cds-draggable"), r.setAttribute("cds-draggable", "active"), o(e.detail.activeItem, t, r, "reordered", "key"));
    }
  }
  addDragEventListeners(e) {
    e.filter(e => !e.cdsDraggableItem).forEach(e => {
      return (t = e).cdsDraggableItem = "item", t.addEventListener("dragstart", g, !1), t.addEventListener("dragover", c, !1), t.addEventListener("drop", l, !1), t.addEventListener("dragleave", u, !1), void t.addEventListener("dragend", e => e.currentTarget.removeAttribute("cds-draggable"), !1);
      var t;
    }), this.dropZones.filter(e => !e.cdsDraggableItem).forEach(e => {
      e.addEventListener("dragover", c, !1), e.addEventListener("dragleave", u, !1), e.addEventListener("drop", l, !1), e.cdsDraggableItem = "dropzone";
    });
  }
}
async function o(e, t, r, a, s) {
  e?.updateComplete && (await e.updateComplete), e.dispatchEvent(new CustomEvent("cdsDraggableChange", {
    detail: {
      from: t,
      target: r,
      type: a,
      interaction: s
    },
    bubbles: !0
  }));
}
function g(e) {
  i = e.currentTarget, e.dataTransfer.effectAllowed = "move", e.dataTransfer.setDragImage(e.currentTarget, 0, 0), e.currentTarget.setAttribute("cds-draggable", "active"), o(e.currentTarget, e.currentTarget, null, "grabbed", "touch");
}
function c(e) {
  return e.preventDefault && e.preventDefault(), e.dataTransfer.dropEffect = "move", i !== e.currentTarget && e.currentTarget.setAttribute("cds-draggable", "target"), !1;
}
function l(e) {
  const t = i,
    r = e.currentTarget;
  return t?.removeAttribute("cds-draggable"), r?.removeAttribute("cds-draggable"), o(e.currentTarget, t, r, "reordered", "touch"), !1;
}
function u(e) {
  "target" === e.currentTarget.getAttribute("cds-draggable") && e.currentTarget.removeAttribute("cds-draggable");
}
export { n as DraggableListController, d as draggableList };
