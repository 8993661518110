import { getOffesetDifference as t } from "../utils/math.js";
function e() {
  return t => t.addInitializer(t => new s(t));
}
class s {
  constructor(t) {
    this.host = t, this.moveHandler = this.move.bind(this), this.endHandler = this.end.bind(this), this.host.addController(this);
  }
  async hostConnected() {
    await this.host.updateComplete, this.host.addEventListener("pointerdown", t => this.start(t), {
      passive: !0
    });
  }
  start(t) {
    t.composedPath().find(t => t === this.host) && (this.startPosition = {
      x: t.clientX,
      y: t.clientY
    }, document.addEventListener("pointerup", this.endHandler, {
      passive: !0
    }), document.addEventListener("pointermove", this.moveHandler, {
      passive: !0
    }), this.host.dispatchEvent(new CustomEvent("cdsTouchStart", {
      detail: {
        ...this.startPosition
      }
    })));
  }
  end(t) {
    if (this.startPosition) {
      const e = this.getCoordinates(t);
      document.removeEventListener("pointerup", this.endHandler, !1), document.removeEventListener("pointermove", this.moveHandler, !1), this.host.dispatchEvent(new CustomEvent("cdsTouchEnd", {
        detail: e
      }));
    }
  }
  move(t) {
    requestAnimationFrame(() => {
      const e = this.getCoordinates(t);
      this.startPosition = {
        x: t.clientX,
        y: t.clientY
      }, this.host.dispatchEvent(new CustomEvent("cdsTouchMove", {
        detail: e
      }));
    });
  }
  getCoordinates(e) {
    return {
      x: e.clientX,
      y: e.clientY,
      offsetX: t(this.startPosition.x, e.clientX),
      offsetY: t(this.startPosition.y, e.clientY)
    };
  }
}
export { s as TouchController, e as touch };
