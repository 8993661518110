import { isBrowser as e } from "./environment.js";
import { getAngularVersion as n, getAngularJSVersion as o, getReactVersion as t, getVueVersion as i } from "./framework.js";
import { browserFeatures as s } from "./supports.js";
import { LogService as r } from "../services/log.service.js";
function w() {
  e() && (window.CDS = window.CDS || {
    _version: [],
    _react: {
      version: void 0
    },
    _supports: s.supports,
    _isStateProxied: !1,
    _state: {
      focusTrapItems: [],
      layerElements: [],
      i18nRegistry: {},
      elementRegistry: {},
      iconRegistry: {},
      motionRegistry: {}
    },
    environment: {
      production: !1
    },
    getDetails: a,
    logDetails: d
  }, function () {
    const e = "6.8.0";
    window.CDS._version.indexOf(e) < 0 && (window.CDS._version.push(e), document.querySelector("body")?.setAttribute("cds-version", window.CDS._version.join(" "))), window.CDS._version.length > 1 && r.warn("Running more than one version of Clarity can cause unexpected issues. Please ensure only one version is loaded.");
  }(), window.CDS._isStateProxied || (window.CDS._isStateProxied = !0, window.CDS._state = new Proxy(window.CDS._state, {
    set: (e, n, o) => {
      const t = {
        key: n,
        prev: window.CDS._state[n],
        current: o
      };
      return e[n] = o, document.dispatchEvent(new CustomEvent("CDS_STATE_UPDATE", {
        detail: t
      })), !0;
    }
  })));
}
function a() {
  return {
    versions: window.CDS._version,
    environment: window.CDS.environment,
    userAgent: navigator.userAgent,
    supports: window.CDS._supports,
    angularVersion: n(!1),
    angularJSVersion: o(!1),
    reactVersion: t(!1),
    vueVersion: i(!1),
    state: {
      ...window.CDS._state,
      iconRegistry: Object.keys(window.CDS._state.iconRegistry),
      motionRegistry: Object.keys(window.CDS._state.motionRegistry),
      focusTrapRegistry: Object.keys(window.CDS._state.focusTrapItems.map(e => e.focusTrapId))
    }
  };
}
function d() {
  r.log(JSON.stringify(a(), null, 2));
}
export { w as setupCDSGlobal };
