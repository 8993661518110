import { focusable as e } from "./focus.js";
function r(r, t = 10) {
  return n(r, t).filter(r => e(r));
}
function n(e, r = 10) {
  return Array.from(t(e)).reduce((e, o) => [...e, [o, [...Array.from(t(o)).map(e => [e, n(e, r)])]]], []).flat(r);
}
function t(e) {
  if (e.documentElement) return e.documentElement.children;
  if (e.shadowRoot) return e.shadowRoot.children;
  if (e.assignedElements) {
    const r = e.assignedElements();
    return r.length ? r : e.children;
  }
  return e.children;
}
export { t as getChildren, n as getFlattenedDOMTree, r as getFlattenedFocusableItems };
