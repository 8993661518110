import { KeyNavigationCode as r } from "../utils/keycodes.js";
import { getFlattenedDOMTree as o } from "../utils/traversal.js";
function t(t, e, n) {
  const i = t.find(r => 0 === r.tabIndex),
    l = e.find(r => o(r).find(r => r === i)),
    w = Array.from(o(l)).filter(r => !!t.find(o => o === r)),
    f = e.length - 1,
    a = w.length - 1,
    {
      code: d,
      ctrlKey: A,
      dir: c
    } = n;
  let g = w.indexOf(i),
    h = e.indexOf(l);
  const s = "rtl" === c ? r.ArrowRight : r.ArrowLeft,
    p = "rtl" === c ? r.ArrowLeft : r.ArrowRight;
  return d === r.ArrowUp && 0 !== h ? h -= 1 : d === r.ArrowDown && h < f ? h += 1 : d === s && 0 !== g ? g -= 1 : d === p && g < a ? g += 1 : d === r.End ? (g = a, A && (h = f)) : d === r.Home ? (g = 0, A && (h = 0)) : d === r.PageUp ? h = h - 4 > 0 ? h - 4 : 0 : d === r.PageDown && (h = h + 4 < f ? h + 4 : f), {
    x: g,
    y: h
  };
}
function e(o, t, e) {
  const {
    code: n,
    layout: i,
    loop: l,
    dir: w
  } = e;
  let f = t.indexOf(o);
  const a = f,
    d = "rtl" === w ? r.ArrowRight : r.ArrowLeft,
    A = "rtl" === w ? r.ArrowLeft : r.ArrowRight,
    c = t.length - 1;
  return "horizontal" !== i && n === r.ArrowUp && 0 !== f ? f -= 1 : "horizontal" !== i && n === r.ArrowUp && 0 === f && l ? f = c : "horizontal" !== i && n === r.ArrowDown && f < c ? f += 1 : "horizontal" !== i && n === r.ArrowDown && f === c && l ? f = 0 : "vertical" !== i && n === d && 0 !== f ? f -= 1 : "vertical" !== i && n === A && f < c ? f += 1 : n === r.End ? f = c : n === r.Home ? f = 0 : n === r.PageUp ? f = f - 4 > 0 ? f - 4 : 0 : n === r.PageDown && (f = f + 4 < c ? f + 4 : c), {
    next: f,
    previous: a
  };
}
export { t as getNextKeyGridItem, e as getNextKeyListItem };
