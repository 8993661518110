function t(t, e, o = !0) {
  const n = new ResizeObserver(() => {
    o ? window.requestAnimationFrame(() => e()) : e();
  });
  return n.observe(t), n.__testTrigger = e, n;
}
function e(t, e) {
  const o = new IntersectionObserver(t => {
    !0 === t[0].isIntersecting && e();
  }, {
    threshold: [0]
  });
  return o.observe(t), o;
}
function o(t, e) {
  return t.updateComplete.then(() => {
    const o = t.layout;
    return t.layout = e.layouts[0], e.layouts.reduce((o, n) => o.then(() => {
      if (t.layout === e.initialLayout) return n;
      {
        const e = t.layout;
        return t.layout = n, t.updateComplete.then(() => (t.layout = t.layoutStable ? t.layout : e, n));
      }
    }), Promise.resolve(e.layouts[0])).then(() => o !== t.layout);
  });
}
export { o as calculateOptimalLayout, t as elementResize, e as elementVisible };
