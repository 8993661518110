import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { IMAGE_CONFIG_Provider, IMAGE_LOADER_Provider } from './shared/imageLoader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    IMAGE_CONFIG_Provider,
    IMAGE_LOADER_Provider
  ]
};
