<div class="system-card" cds-layout="vertical" aria-labelledby="{id}">
  <!--<div class="cds-card-inner-float">-->
  <!--<div class="card-img-and-description">-->
  <a [routerLink]="['/systems',s.id]" class="card-img">

    @if (img.url !== '' && index < 3) { <img class="card-media-image" width="1920" height="1075" [ngSrc]="img.url"
      sizes="(max-width: 768px) 95vw, (max-width: 992px) 45vw, (max-width: 2560px) 30vw, 25vw" priority>
      }
      @else if (img.url !== '' && index >= 3) {
      <img class="card-media-image" width="1920" height="1075" [ngSrc]="img.url"
        sizes="(max-width: 768px) 95vw, (max-width: 992px) 45vw, (max-width: 2560px) 30vw, 25vw">
      }
  </a>
  <!--</div>-->

  <div class="card-block cds-card-action">
    <a [id]="s.id" class="card-text" [routerLink]="['/systems',s.id]" routerLinkActive="active">
      <span cds-text="section">{{s.title}}</span>
    </a>
  </div>

</div>