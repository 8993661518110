import { Iimage } from "./ukis-projects";

interface ISystem {
  id: string;
  name: string;
}

export interface IUkisFeature {
  id: string;
  /** Display image of the feature */
  image: Iimage;
  /** Linked systems */
  systems?: ISystem[];
}

export const featureIds = {
  ft_web_app: 'ft_web_app',
  ft_modularity: 'ft_modularity',
  ft_map_viewer: 'ft_map_viewer',
  ft_legend: 'ft_legend',
  ft_manage_layers: 'ft_manage_layers',
  ft_filter_layers: 'ft_filter_layers',
  ft_time_stepper: 'ft_time_stepper',
  ft_time_range_picker: 'ft_time_range_picker',
  ft_3d_viwer: 'ft_3d_viwer',
  ft_charts: 'ft_charts',
  ft_compare_view: 'ft_compare_view',
  ft_zoom_to_aoi: 'ft_zoom_to_aoi',
  ft_projection_switch: 'ft_projection_switch',
  ft_dataset_explorer: 'ft_dataset_explorer'
} as const;

export const introSection = [`UKIS is a feature-rich set of technologies that aims at the rapid development of modern geospatial web applications. Modularity and reusability are at the heart of UKIS. Designed with science in mind, UKIS can be used for a wide range of applications. Scroll down to discover some of the core features of our <a href="https://dlr-eoc.github.io/ukis-frontend-libraries" target="_blank">UKIS Frontend</a>.`];

export const ukis_features: IUkisFeature[] = [
  {
    id: featureIds.ft_web_app,
    image: {
      link: 'assets/images/features/ukis_responsive.jpg',
      title: 'Responsive design',
      description: 'UKIS runs in all common web browsers. The responsive web design makes it possible to use UKIS-based systems on a large number of devices, from smartphones to PCs. As UKIS is mainly used for the visualisation of complex datasets, the layout is optimized for desktop and tablet displays.'
    }
  },
  {
    id: featureIds.ft_modularity,
    image: {
      link: 'assets/images/features/layersService.jpg',
      title: 'Modularity',
      description: `One of UKIS' core concepts is the LayersService. It provides a modular interface where core components can be used independently from different mapping libraries e.g. OpenLayers, Cesium, Leaflet, or MapLibre. For example, the layer control of a UKIS system is working with different maps and layer types.`,
    }
  },
  {
    id: featureIds.ft_map_viewer,
    image: {
      link: 'assets/images/systems/worldbank-ukis-frontend/landing-page.jpg',
      title: 'Visualise data in maps',
      description: 'Using established mapping libraries such as OpenLayers, this core functionality of UKIS makes it possible to display georeferenced data on a dynamic web map. The data can be of type raster or vector and is usually integrated via standardised OGC services, e.g. WMS, WMTS or WFS.',
    },
    systems: [{ id: 'worldbank-ukis-frontend', name: 'Urbanization' }, { id: 'project-coastalx', name: 'Coastal Explorer' }]
  },
  {
    id: featureIds.ft_legend,
    image: {
      link: 'assets/images/features/ukis_legend.jpg',
      title: 'Show legend information',
      description: 'Explanatory information on the displayed data is shown in a legend for each layer. The legend is located in the widget at the side of the map. It can be minimized for smaller screens.',
    }
  },
  {
    id: featureIds.ft_manage_layers,
    image: {
      link: 'assets/images/features/layer_management.jpg',
      title: 'Manage data layers',
      description: 'Available data layers in the map can be organised in the side widget. This changes the display hierarchy and determines which layer is shown on top. Additionally, the visibility and opacity of the individual layers can be set. Further settings for the layers can be implemented on demand.',
    }
  },
  {
    id: featureIds.ft_filter_layers,

    image: {
      link: 'assets/images/features/layer_filter.jpg',
      title: 'Filter layers',
      description: 'If the data of a vector or raster layer includes certain attributes, the layer content can be filtered on the map with controls in the side widget. For example, only data above a threshold value is shown on the map.',
    },
    systems: [{ id: 'project-ecoaral', name: 'Eco-Aral' }]
  },
  {
    id: featureIds.ft_time_stepper,

    image: {
      link: 'assets/images/features/time_stepper.jpg',
      title: 'Time stepper',
      description: 'A particularly important data filter is the time stepper or slider widget for layers with temporal information. As a result, only information within a selected timespan is shown on the map. This can be used to visualise changes over time in the data.',
    },
    systems: [{ id: 'project-elkis', name: 'ELKIS' }, { id: 'project-coastalx', name: 'Coastal Explorer' }, { id: 'project-flood-h3', name: 'Surface Water' }]
  },
  {
    id: featureIds.ft_3d_viwer,
    image: {
      link: 'assets/images/features/ukis_cesium.jpg',
      title: '3D Viewer',
      description: 'Using the open source CesiumJS library, UKIS can visualise 2D and 3D content on a digital globe. The possibility to show layers in 3D adds new ways to display complex data. For global data layers the 3D visualisation removes projection errors near polar regions. Additionally, 3D tilesets can be integrated and shown, e.g. digital city models.',
    },
    systems: [{ id: 'project-demo-maps', name: 'Demo Maps' }, { id: 'project-elkis', name: 'ELKIS' }]
  },
  {
    id: featureIds.ft_charts,
    image: {
      link: 'assets/images/features/ukis_chart.jpg',
      title: 'Showing data in charts',
      description: 'Some data can be displayed well with diagrams. In UKIS this can be done as a standalone representation or in addition to the map view.',
    },
    systems: [{ id: 'project-demo-maps', name: 'Demo Maps' }, { id: 'project-elkis', name: 'ELKIS' }, { id: 'worldbank-ukis-frontend', name: 'Urbanization' }]
  }
  ,
  {
    id: featureIds.ft_compare_view,
    image: {
      link: 'assets/images/features/compare_view.jpg',
      title: 'Synchronised compare view',
      description: 'The compare view offers a side by side visualisation for the comparison of two datasets. Extent, time and pop-ups of both map views can be synchronised.',
    },
    systems: [{ id: 'project-elkis', name: 'ELKIS' }]
  },
  {
    id: featureIds.ft_zoom_to_aoi,
    image: {
      link: 'assets/images/features/aoi_selection.jpg',
      title: 'Zoom to predefined areas',
      description: 'Areas of interest can be predefined to highlight particularly important areas in large data sets. If the user selects such an area in the side widget, the map moves to this location.',
    },
    systems: [{ id: 'project-elkis', name: 'ELKIS' }]
  },
  {
    id: featureIds.ft_projection_switch,
    image: {
      link: 'assets/images/features/projection.jpg',
      title: 'Change the map projection',
      description: 'Most 2D representations of the Earth lead to projection errors near the poles. For datasets in these regions UKIS offers a projection switch to adjust the map projection.',
    },
    systems: [{ id: 'project-mariss', name: 'MARISS' }]
  }
];