import r from "ramda/es/isNil";
import m from "ramda/es/sum";
import t from "ramda/es/curryN";
function o(m) {
  return r(m) ? 0 : 1e3 * Number(m);
}
const a = t(3, (r, t, o) => (r || 0) + m(t || []) - m(o || [])),
  e = t(4, (r, m, t, o) => !!o && o(a(r, m, t)));
function i(r, m) {
  return Math.sign(m - r) * Math.abs(r - m);
}
export { e as compareSumTo, o as getMillisecondsFromSeconds, i as getOffesetDifference, a as sumAndSubtract };
