<div class="system-card" cds-layout="vertical gap:md" aria-labelledby="{id}">
  <div class="cds-card-inner-float">
    <div class="card-img-and-description">
      <a [routerLink]="['/systems',s.id]" class="card-img">
        <div cds-text="body light">
          @if (img.url !== '') {
          <img class="card-media-image" [ngSrc]="img.url" width="640" height="360" ngSrcset="384w, 640w">
          }
        </div>
      </a>

      <a [id]="s.id" class="card-title" [routerLink]="['/systems',s.id]" routerLinkActive="active">
        <span cds-text="section">{{s.title}}</span>
      </a>

      <div cds-text="body light" class="card-text" [innerHTML]="concatDesc(s) | markdownLink">
      </div>
    </div>

    @if (s.tags) {
    <app-truncate-tag-list [tags]="s.tags" [maxNr]="4" [activeTags]="getActiveFilters()"
      (tagClicked)="onTagClicked($event)">
    </app-truncate-tag-list>
    }
  </div>

  <div cds-layout="horizontal gap:md align:vertical-center" class="cds-card-action">
    <cds-button action="flat" size="sm" [routerLink]="['/systems',s.id]"><cds-icon shape="details" size="sm"></cds-icon>
      Details</cds-button>
    <div cds-layout="horizontal align:right" cds-text="body light" title="Initialization date">
      Since {{s?.startDate | date:'MM.y' || 'null'}}
    </div>
  </div>
</div>