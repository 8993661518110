import { onEscape as s, onFocusOut as t } from "../utils/focus.js";
function o(s = {}) {
  return t => {
    t.addInitializer(t => {
      t.closableController || (t.closableController = new e(t, s));
    });
  };
}
class e {
  constructor(s, t = {}) {
    this.host = s, this.config = {
      escape: !0,
      focusout: !1,
      ...t
    }, this.host.addController(this);
  }
  hostConnected() {
    this.config.escape && s(this.host, () => this.close("escape-keypress")), this.config.focusout && (this.host.tabIndex = 0, t(this.host, () => this.close("focusout")));
  }
  close(s) {
    this.host.dispatchEvent(new CustomEvent("closeChange", {
      detail: s
    }));
  }
}
export { e as ClosableController, o as closable };
