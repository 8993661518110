import { CLARITY_MOTION_REVERSE_ANIMATION_SUFFIX as t, AnimationStatus as n, CLARITY_MOTION_REVERSE_ANIMATION_LABEL as o, CLARITY_MOTION_FALLBACK_EASING as i, CLARITY_MOTION_FALLBACK_DURATION_IN_MS as r, CLARITY_MOTION_ENTER_LEAVE_PROPERTY as e } from "./interfaces.js";
import { LogService as s } from "../services/log.service.js";
import { ClarityMotion as a } from "./motion.service.js";
import u from "ramda/es/clone";
import { isCssPropertyName as c, getCssPropertyValue as f } from "../utils/css.js";
import { isSuffixedBy as m, getNumericValueFromCssSecondsStyleValue as l, isPrefixedBy as p, removePrefix as h } from "../utils/string.js";
import { queryChildFromLightOrShadowDom as g } from "../utils/dom.js";
import { allPropertiesPass as d } from "../utils/identity.js";
import { getMillisecondsFromSeconds as y } from "../utils/math.js";
async function w(t, n) {
  if (!n._animations) return s.warn(n.tagName.toLocaleLowerCase() + " is trying to animate but no animations are defined."), !1;
  const o = z(n._animations, t);
  return await Promise.all(o.map(o => {
    const [i, r] = o;
    if (t.get(i) === n[i]) return !1;
    const e = r[n[i].toString()],
      s = n.cdsMotion,
      [a, c] = M(e, x(s, i, n[i].toString()));
    let f = u(c);
    if (f.length < 1) return !1;
    f = O(a, f, n);
    const m = b(e, f, n);
    return Promise.all(m).then(() => (v(e, n), !0));
  })).then(t => t.indexOf(!0) > -1).catch(() => !1);
}
function j(t, o) {
  o.getAttribute("_cds-animation-status") !== n.active && (o.setAttribute("_cds-animation-status", n.active), o.cdsMotionChange.emit(`${t} animation ${n.start}`));
}
function v(t, o) {
  o.setAttribute("_cds-animation-status", n.ready), o.cdsMotionChange.emit(`${t} animation ${n.end}`);
}
function O(t, n, o) {
  const i = o.cdsMotion,
    r = !i || "off" === i;
  return S(t) && (n = C(n)), r ? B(n) : $(n = N(n, o), o);
}
function b(t, n, o) {
  return n.filter(t => !t.onlyIf || d(o, t.onlyIf)).map(n => (j(t, o), new Promise(t => {
    const i = A(o, n.target).animate(P(n.animation, o), n.options || {}),
      r = () => {
        t("animation finished"), i.removeEventListener("finish", r);
      };
    i.addEventListener("finish", r);
  })));
}
function A(t, n) {
  return g(t, n) || t;
}
function P(t, n) {
  return Array.isArray(t) ? R(t, n) : t;
}
function S(n) {
  return m(n, t);
}
function C(t) {
  return t.map(t => (t.options ? t.options.direction = o : t.options = {
    direction: o
  }, t));
}
function _(t) {
  return t + "-" + o;
}
function x(t, n, o) {
  if (!t || !n || void 0 === o) return "";
  if ("on" === t || "off" === t) return "";
  let i;
  try {
    i = JSON.parse(t);
  } catch (t) {
    return "";
  }
  return i[n] && i[n][o] || "";
}
function E(n) {
  return S(n) ? n.slice(0, -1 * t.length) : n;
}
function L(t, n) {
  return n && a.has(E(n)) ? n : t;
}
function M(t, n) {
  const o = L(t, n);
  return [o, u(a.get(E(o)))];
}
function N(t, n) {
  return I("duration", n, t, r, t => y(l(t)));
}
function $(t, n) {
  return I("easing", n, t, i);
}
function B(t) {
  return t.map(t => (t.options ? (t.options.duration = 0, t.options.easing = i) : t.options = {
    duration: 0,
    easing: i
  }, t));
}
function I(t, n, o, i, r) {
  return o.map(o => {
    if (o.options) {
      if (o.options[t]) {
        if (c(o.options[t])) {
          const e = o.options[t];
          let s = f(e, n);
          s ? r && (s = r(s)) : s = i, o.options[t] = s;
        }
      } else o.options[t] = i;
    } else {
      const n = {};
      n[t] = i, o.options = n;
    }
    return o;
  });
}
function R(t, n) {
  return Array.isArray(t) ? t.map(t => {
    if (Object.prototype.hasOwnProperty.call(t, "height") && p(t?.height?.toString() || "", "from:")) {
      const o = h(t?.height?.toString() || "", "from:"),
        i = g(n, o) || null;
      t.height = i ? i.getBoundingClientRect().height + "px" : "auto";
    }
    if (Object.prototype.hasOwnProperty.call(t, "width") && p(t?.width?.toString() || "", "from:")) {
      const o = h(t?.width?.toString() || "", "from:"),
        i = g(n, o) || null;
      t.width = i ? i.getBoundingClientRect().width + "px" : "auto";
    }
    return t;
  }) : t;
}
function J(t, n) {
  if (null == t) return null;
  let o = !0;
  const i = {};
  return Object.getOwnPropertyNames(t).forEach(r => {
    n.has(r) && void 0 !== n.get(r) && (i[r] = u(t[r]), o = !1);
  }), o ? null : i;
}
function k(t, n) {
  if (null == t) return [];
  const [o, i] = t;
  return o.length > 0 ? n ? [].concat(i, o) : [].concat(o, i) : i;
}
function q(t) {
  const n = [],
    o = [];
  return Object.getOwnPropertyNames(t || {}).forEach(i => {
    const r = [i, u(t[i])];
    i === e ? n.push(r) : o.push(r);
  }), [n, o];
}
function z(t, n) {
  const o = J(t || {}, n);
  return null === o ? [] : k(q(o), n.get(e));
}
export { S as animationIsReversed, E as extractAnimationNameIfReversed, J as filterAnimationsByUpdatedProperties, k as flattenAndSortAnimations, M as getAnimationConfigForPropertyValue, L as getAnimationFromOverrideOrDecorator, P as getAnimationKeyframesOrPropertyIndexedFrames, b as getAnimationPromiseInstructions, A as getAnimationTarget, q as getHidingAndNonHidingPropertyAnimations, x as getInlineOverride, z as getPropertyAnimations, v as resolveAnimationEndStatus, _ as reverseAnimation, C as reverseAnimationConfig, w as runPropertyAnimations, O as setAnimationConfigOptions, N as setAnimationDuration, $ as setAnimationEasing, I as setAnimationProperty, j as setAnimationStartStatus, R as sizeDimensionKeyframes, B as zeroOutAnimationConfig };
