import { initializeKeyListItems as t, setActiveKeyListItem as e, focusElement as s } from "../utils/focus.js";
import { validKeyNavigationCode as i } from "../utils/keycodes.js";
import { getFlattenedFocusableItems as o } from "../utils/traversal.js";
import { getNextKeyListItem as n } from "./key-navigation.utils.js";
function a(t = {}) {
  return e => {
    e.addInitializer(e => {
      e.keyNavigationListController || (e.keyNavigationListController = new c(e, t));
    });
  };
}
class c {
  constructor(t, e = {}) {
    this.host = t, this.host.addController(this), this.config = {
      keyListItems: "keyListItems",
      layout: "horizontal",
      manageFocus: !0,
      manageTabindex: !0,
      loop: !1,
      dir: this.host.getAttribute("rtl"),
      ...e
    };
  }
  get listItems() {
    return this.host[this.config.keyListItems];
  }
  async hostConnected() {
    await this.host.updateComplete, this.initializeTabIndex(), this.host.addEventListener("click", t => this.clickItem(t)), this.host.addEventListener("keydown", t => this.focusItem(t)), this.host.shadowRoot?.addEventListener("click", t => this.clickItem(t)), this.host.shadowRoot?.addEventListener("keydown", t => this.focusItem(t));
  }
  initializeTabIndex() {
    this.config.manageFocus && this.config.manageTabindex && t(this.listItems);
  }
  clickItem(t) {
    const e = this.getActiveItem(t);
    e && this.setActiveItem(t, e);
  }
  focusItem(t) {
    if (i(t)) {
      const e = this.getActiveItem(t);
      if (e) {
        const {
          next: s,
          previous: i
        } = n(e, Array.from(this.listItems), {
          ...this.config,
          code: t.code
        });
        s !== i && this.setActiveItem(t, this.listItems[s], this.listItems[i]);
      }
    }
  }
  getActiveItem(t) {
    return Array.from(this.listItems).find(e => e === t.target.closest(this.listItems[0].tagName.toLocaleLowerCase()) ?? e === t.target);
  }
  setActiveItem(t, i, n) {
    if (this.config.manageFocus) {
      this.config.manageTabindex && e(this.listItems, i);
      const n = o(i)[0] ?? i;
      s(n), t.preventDefault();
    }
    i.dispatchEvent(new CustomEvent("cdsKeyChange", {
      bubbles: !0,
      detail: {
        activeItem: i,
        previousItem: n,
        code: t.code,
        metaKey: t.ctrlKey || t.metaKey,
        keyListItems: this.config.keyListItems
      }
    }));
  }
}
export { c as KeyNavigationListController, a as keyNavigationList };
