<div class="app-content scrollable-content" id="scrollableContainer">
  <section cds-layout="container:lg container:center p-x:md p-x@xs:xl p-x@sm:xxl p-y:lg p-y@sm:xxl">
    <h1 cds-text="display" cds-layout="m-b:md m-t:xl m-t@xs:xxl">About UKIS</h1>

    <div cds-layout="m-t:xl m-t@md:xxl m-b:xl">
      <app-kpi-section></app-kpi-section>
    </div>

    <section cds-layout="p-t@sm:xl">
      <div cds-layout="grid cols@sm:6 gap@sm:lg gap@md:xxl align:vertical-center">
        <div cds-text="subsection left">
          <p class="p1 about-text">
            UKIS is a set of technologies from the <a href="https://www.dlr.de" target="_blank">German Aerospace Center</a> (DLR) for web applications with geodata. Based on UKIS, software for the analysis and visualization of geodata can be created quickly and easily. Tools and libraries for data processing as well as a framework for data visualization are available on <a href="https://github.com/orgs/dlr-eoc/repositories?q=ukis" target="_blank">GitHub</a>.
          <p>
        </div>
        <div cds-text="center" cds-layout="col:4 col:end-9 col@sm:end-12 align:center">
          <!-- <img [src]="logo.image" class="about-image"/> -->
          <img [ngSrc]="logo.image" class="about-image" width="384" height="384" ngSrcset="384w, 640w" />
        </div>
        <div cds-text="center title" cds-layout="p-x:md">
          <p>
            <i>"The UKIS Frontend provides a stable platform for geospatial data visualization."</i>
          <p>
        </div>
        <div cds-text="subsection left">
          <p class="p1 about-text">
            The core of UKIS is the 'UKIS Frontend', a combination of various open source software libraries and frameworks to simplify the rapid creation of web-based geospatial user interfaces. The pillars of the UKIS Frontend are the <a href="https://clarity.design" target="_blank">Clarity Design System</a>, <a href="https://openlayers.org" target="_blank">OpenLayers</a> and the <a href="https://angular.io" target="_blank">Angular</a> web development framework.
          <p>
        </div>
        <div cds-text="subsection left" cds-layout="m-r:md display:none display@sm:flex">
          <p class="p1 about-text">
            UKIS is being developed at the <a href="https://www.dlr.de/eoc/en/desktopdefault.aspx/tabid-5278/8856_read-15911">German Remote Sensing Data Center</a>, an institute of DLR. Built with the requirements of research in mind, UKIS technology can be adapted for a wide variety of topics: crisis information, environmental monitoring, civil security, health, planetary sciences and others. The acronym UKIS stands for 'Umwelt- und Kriseninformationssysteme' (German for 'Environmental and Crisis Information Systems').
          </p>
        </div>
        <div cds-text="center" cds-layout="col:4 col:end-9 col@sm:end-12 align:center">
          <a href="https://www.dlr.de/eoc/" target="_blank">
            <svg version="1.1" viewBox="0 -180 580 580" preserveAspectRatio="xMidYMid meet" class="has-solid"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false"
              role="img">
              <title>eoc</title>
              <path class="clr-i-solid clr-i-solid-path-1" d="M284,210c-55.1,0-100-44.9-100-100S228.9,10,284,10s100,44.9,100,100S339.1,210,284,210z M284,28.2
            c-45.1,0-81.8,36.7-81.8,81.8s36.7,81.8,81.8,81.8s81.8-36.7,81.8-81.8S329.1,28.2,284,28.2z M566,180.7l-12.9-12.9
            c-31.9,31.9-83.8,31.9-115.7,0c-31.9-31.9-31.9-83.8,0-115.7c31.9-31.9,83.8-31.9,115.7,0L566,39.3c-39-39-102.5-39-141.5,0
            s-39,102.5,0,141.5c19.5,19.5,45.1,29.3,70.7,29.3S546.5,200.2,566,180.7z M184.7,180.7l-12.9-12.9c-31.9,31.9-83.8,31.9-115.7,0
            c-15.5-15.5-24-36-24-57.8c0-21.9,8.5-42.4,24-57.8c28.6-28.6,73.4-31.8,105.5-8.7l-67.8,75.2l13.5,12.2l80.3-89.1l-6.7-6.1
            C141.4,0.1,80.9,1.7,43.3,39.3C24.4,58.2,14,83.3,14,110c0,26.7,10.4,51.8,29.3,70.7C62.8,200.2,88.4,210,114,210
            C139.6,210,165.2,200.2,184.7,180.7z" />
            </svg>
          </a>
        </div>
        <div cds-text="subsection left" cds-layout="m-r:md display@sm:none">
          <p class="p1 about-text">
            UKIS is being developed at the <a href="https://www.dlr.de/eoc/en/desktopdefault.aspx/tabid-5278/8856_read-15911">German Remote Sensing Data Center</a>, an institute of DLR. Built with the requirements of research in mind, UKIS technology can be adapted for a wide variety of topics: crisis information, environmental monitoring, civil security, health, planetary sciences and others. The acronym UKIS stands for 'Umwelt- und Kriseninformationssysteme' (German for 'Environmental and Crisis Information Systems').
          </p>
        </div>
      </div>
    </section>
  </section>

  <app-footer></app-footer>

</div>