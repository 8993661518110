function t(t = {
  skipFirst: !1
}) {
  return e => e.addInitializer(e => new s(e, t));
}
class s {
  constructor(t, s = {
    skipFirst: !1
  }) {
    this.host = t, this.skipFirst = !1, this.host.addController(this), this.skipFirst = !!s.skipFirst, this.resizeElement = s.element ? s.element : this.host;
  }
  async hostConnected() {
    await this.host.updateComplete, this.observer = new ResizeObserver(t => {
      window.requestAnimationFrame(() => {
        this.skipFirst ? this.skipFirst = !1 : (this.host.dispatchEvent(new CustomEvent("cdsResizeChange", {
          detail: t[0].contentRect
        })), this.host.requestUpdate());
      });
    }), this.observer.observe(this.resizeElement);
  }
  hostDisconnected() {
    this.observer?.disconnect();
  }
}
export { s as ResponsiveController, t as responsive };
