<div class="app-content scrollable-content">
  <div class="imprint pad-footer-bottom" cds-layout="container:lg container:center p:lg p-y:lg">
    <h2>Legal Notice</h2>

    <p>Legal Notice according to Section § 5 TMG (German Telemedia Act) and § 18 Medienstaatsvertrag (German 
      State Media Treaty)</p>
    <p>
      Deutsches Zentrum fuer Luft- und Raumfahrt e. V.
      <br> German Aerospace Center (DLR)
      <br> Linder Hoehe
      <br> 51147 Koeln (Cologne)
      <br> Germany
    </p>

    <p>
      Tel: +49 2203 601-0
      <br> Fax: +49 2203 67310
      <br> email: contact-dlr [at] dlr.de
      <br> www: http://www.dlr.de/en
    </p>

    <p>
      DLR's Executive Board is empowered to act as DLR's representative. It consists of Prof. Dr.-Ing. Anke 
      Kaysser-Pyzalla (Chair of the DLR Executive Board), Klaus Hamacher (Vice Chairman of the Executive Board), 
      Prof. Dr.-Ing. Karsten Lemmer and Dr.-Ing. Walther Pelzer.
    </p>
    <p>
      <b>Executive Board</b><br>The Executive Board's seat is located at DLR, Executive Board, Linder Hoehe, D-51147
      Koeln. The Executive Board can also authorise DLR employees to act on behalf of DLR. The head of DLR's legal
      department Linder Hoehe, 51147
      Cologne, can provide information about the extent of this authorisation.
    </p>
    <p>
      <b>Registration court and registration number of DLR</b><br> District court of Bonn, VR 2780.
    </p>
    <p>
      <b>Value added tax identification number</b><br> DE 121965658
    </p>
    <p>
      <b>Responsible in the sense of Section § 18 2nd paragraph of Medienstaatsvertrag (German State Media Treaty)</b>
      <br> Prof. Dr. Günter Strunz, Head of Department „Georisiks and Civil Security“,
      Oberpfaffenhofen, 82234 Wessling
    </p>
  </div>
  <app-footer></app-footer>
</div>
