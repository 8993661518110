<div class="app-content scrollable-content">
  <div class="accessibility pad-footer-bottom" cds-layout="container:lg container:center p:lg p-y:lg">
    <h2>Accessibility statement</h2>
    <p></p>
    <p>This accessibility statement is valid for the website
      ukis.dlr.de of the German Aerospace Center (Deutsches Zentrum
      für Luft- und Raumfahrt e. V.; DLR).</p>
    <p>As a public sector body of the German Federal Government within the
      meaning of EU Directive 2016/2102, we endeavour to make our websites and
      mobile applications accessible in accordance with the provisions set out
      in the German Equality for Persons with Disabilities Act (BGG) and the
      German Barrier-Free Information Technology Ordinance (BITV 2.0) in order
      to implement EU Directive 2016/2102.</p>
    <h2 id="compliance-status" class="test">Compliance status</h2>
    <p>The accessibility requirements stem from Sections 3 (1) to (4) and 4 of
      BITV 2.0, adopted on the basis of Section 12d BGG.</p>
    <p>At present, the website is partially compliant with the aforementioned
      requirements. The website is currently being reviewed with regard to
      accessibility. It is therefore possible that not all accessibility
      requirements have been fully met. We are working on addressing all
      identified accessibility problems.</p>
    <h2 id="preparation-of-this-accessibility-statement" class="test">Preparation of this accessibility statement</h2>
    <p>This statement was prepared on May 5, 2023.</p>
    <h2 id="reporting-barriers-feedback-and-contact-information" class="test">Reporting barriers: feedback and contact
      information</h2>
    <p>Would you like to notify us of existing barriers or request information
      regarding the implementation of accessibility?</p>
    <p>If you would like to give us your feedback and for further information,
      please contact <a href="mailto:barrierefreiheit@dlr.de">barrierefreiheit&#64;dlr.de</a>.</p>
    <h2 id="conciliation-procedure" class="test">Conciliation procedure</h2>
    <p>If no satisfactory solution is found following your feedback, then you
      can turn to the conciliation body, pursuant to Section 16 BGG. The BGG
      conciliation body is tasked with helping to settle accessibility-related
      disputes out of court between people with disabilities and federal
      public bodies. The conciliation procedure is free of charge. No legal
      assistance is required.</p>
    <p>For more information on the conciliation procedure and the options for
      filing an application, please visit:
      <a href="https://wiki.dlr.de/www.schlichtungsstelle-bgg.de" rel="external" target="_blank" class="external-link"
        title="Externer Link ">www.schlichtungsstelle-bgg.de</a>
    </p>
    <p>You can contact the BGG conciliation body directly at:
      <a href="mailto:info@schlichtungsstelle-bgg.de">info&#64;schlichtungsstelle-bgg.de</a>
    </p>
    <p>
      DISCLAIMER: The English version is a translation of the original in
      German for information purposes only. In case of a discrepancy, the
      German original will prevail.</p>
  </div>
  <app-footer></app-footer>
</div>
