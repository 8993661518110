import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Iproject } from '../../data/ukis-projects';
import { DataService, sortSystems } from '../../services/data.service';


type filterKeys = keyof Iproject | 'sort';
export interface IFilterFormItem {
  action: 'filter';
  fc: FormControl;
  key: filterKeys;
  title?: string;
  type: 'number' | 'date' | 'checkbox' | 'text' | string;
  fc2?: FormControl;
  condition: (v1: any, item: any) => boolean;
}

export interface ISortFormItem {
  action: 'sort';
  fc: FormControl;
  key: 'sort';
  title?: string;
  type: 'select';
  fc2?: FormControl;
  sort: (a: any, b: any, v: 'title' | 'date' | 'default') => number;
  options: { value: string | number, title: string }[];
}

export interface IFilterFormSelctselect extends IFilterFormItem {
  type: 'select';
  options: { value: string | number, title: string }[];
}


type IFilterFormItems = IFilterFormItem | IFilterFormSelctselect | ISortFormItem;

type Tfilters = {
  [k in filterKeys]?: IFilterFormItems;
}

type TfromGroupOptions = {
  [k in filterKeys]?: FormControl;
}


export interface IrouteQuery {
  tags?: string;
  startDate?: string;
  inactive?: 'Active' | 'Inactive';
  title?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataFilterService {

  filterForm: FormGroup<TfromGroupOptions> = null as never;
  filters: Tfilters = {};
  filtersArray: IFilterFormItems[];
  fromGroupOptions: TfromGroupOptions = {};

  public filteredSystems: Iproject[] = [];
  constructor(private dataSvc: DataService, private fb: FormBuilder) {
    this.filtersArray = [
      {
        action: 'filter',
        fc: new FormControl({ value: 'All', disabled: false }),
        key: 'startDate',
        condition: function (v1: string, item: Iproject) {
          if (v1 === 'All') {
            return true;
          } else {
            if (this.fc2?.value) {
              if (item.startDate) {
                const v1d = new Date(v1);
                const itemd = new Date(item.startDate);
                return v1d <= itemd;
              } else {
                return false; // false -> all with no date filter out
              }
            } else {
              return item.startDate?.includes(v1) || false; // false -> all with no date filter out
            }
          }
        },
        title: 'Start Date',
        type: 'select',
        fc2: new FormControl({ value: false, disabled: false }),
        options: [{ value: 'All', title: '- set date -' }]
      },
      {
        action: 'filter',
        fc: new FormControl({ value: 'All', disabled: false }),
        key: 'inactive',
        condition: (v1: string, item: Iproject) => {
          if (v1 === 'All') {
            return true;
          } else if (v1 === 'Active') {
            return item.inactive !== true;
          } else if (v1 === 'Inactive') {
            return item.inactive === true;
          } else if (v1 === 'Public') {
            const isPublic = item.tags?.map(i => i.toLocaleLowerCase())?.includes('public');
            return ('url' in item && isPublic === true);
          } else {
            return true;
          }
        },
        title: 'System Status',
        type: 'select',
        options: [{ value: 'All', title: '- set status -' }, { value: 'Active', title: 'Active' }, { value: 'Inactive', title: 'Inactive' }, { value: 'Public', title: 'Public' }]
      },
      {
        action: 'filter',
        fc: new FormControl({ value: [], disabled: false }),
        key: 'tags',
        condition: (v1: string[], item: Iproject) => {
          if (!v1.length) {
            return true;
          } else {
            if (item.tags?.length) {
              const lowerTags = item.tags?.map(i => i.toLocaleLowerCase());
              // check if dataset contains all tags in filterValues
              const includes = v1.map(i => lowerTags.includes(i));
              // check only includes true
              return !includes.includes(false);
            } else {
              return false;
            }
          }
        },
        title: 'Tags',
        type: 'select',
        options: []
      },
      {
        action: 'filter',
        fc: new FormControl({ value: '', disabled: false }),
        key: 'title',
        condition: (v1: string, item: Iproject) => {
          if (v1 === 'Type Name...') {
            return true;
          } else {
            return item.title.toLowerCase().includes(v1.toLowerCase());
          }
        },
        title: 'Title',
        type: 'text'
      },
      {
        action: 'sort',
        fc: new FormControl({ value: 'default', disabled: false }),
        key: 'sort',
        sort: (a: Iproject, b: Iproject, v) => {
          if(v === 'default'){
            return sortSystems(a, b, 'random');
          }else if(v === 'title'){
            return sortSystems(a, b, 'title');
          }else{
            return sortSystems(a, b, 'date');
          }
        },
        title: 'Sort',
        type: 'select',
        options: [{ value: 'default', title: '- Sort Systems -' }, { value: 'title', title: 'Title' }, { value: 'date', title: 'Date' }]
      },

    ];

    this.filtersArray.forEach(f => {
      this.fromGroupOptions[f.key] = f.fc;
      this.filters[f.key] = f;
    });

    this.filterForm = new FormGroup<TfromGroupOptions>(this.fromGroupOptions);
  }

  getFilterOptions(key: filterKeys) {
    const filter = this.filters[key] as IFilterFormSelctselect;
    if (filter && filter.type === 'select') {
      return filter.options;
    } else {
      return false;
    }
  }

  addFilterOptions(key: filterKeys, options: IFilterFormSelctselect['options']) {
    const filter = this.filters[key] as IFilterFormSelctselect;
    if (filter && filter.type === 'select' && options) {
      filter.options = filter.options?.concat(options);
    }
  }


  filterSystems(systems: Iproject[]) {
    const active = this.filtersArray.filter(item => !item.fc.disabled);
    const activeFilters = active.filter(item => item.action === 'filter');
    const activeSort = active.filter(item => item.action === 'sort');
    let filtered = systems.filter(item => {
      if (activeFilters.length) {
        /** collect all conditions to filter on multiple conditions */
        const conditions = [];
        for (const f of activeFilters) {
          if ('condition' in f) {
            conditions.push(f.condition(f.fc.value, item));
          }
        }
        if (conditions.includes(false)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    if (activeSort.length) {
      for (const f of activeSort) {
        if ('sort' in f) {
          
          filtered = [...filtered].sort((a, b) => {
            return f.sort(a, b, f.fc.value);
          });
        }
      }
    }

    return filtered;
  }

  toggleFilter(fc: FormControl) {
    if (fc.disabled) {
      fc.enable();
    } else {
      fc.disable();
    }
  }

  filter2(fc2: FormControl, fc: FormControl) {
    if (!fc2.value) {
      fc2.setValue(true);
    } else {
      fc2.setValue(false);
    }

    fc.patchValue(fc.value);
  }


}
