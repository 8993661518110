import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-list-system-card-main',
  templateUrl: './list-system-card-main.component.html',
  styleUrls: ['./list-system-card-main.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, NgOptimizedImage, RouterLinkActive]
})
export class ListSystemCardMainComponent implements OnInit {
  @Input('system') s: any
  @Input() index: any
  img = {
    url: ''
  }

  constructor(private dataSvc: DataService) { }

  getPreviewImage = this.dataSvc.getPreviewImage;
  concatDesc = this.dataSvc.concatDesc;

  ngOnInit(): void {
    this.img.url = this.getPreviewImage(this.s);
  }

}
