const a = "cds-navigation-open",
  i = [{
    target: "cds-navigation",
    animation: [{
      width: "var(--collapsed-width)"
    }, {
      width: "var(--expanded-width)"
    }],
    options: {
      duration: "--animation-duration",
      easing: "--animation-easing",
      fill: "forwards"
    }
  }];
export { i as AnimationNavigationOpenConfig, a as AnimationNavigationOpenName };
