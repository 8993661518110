function n(n, t) {
  return n.reduce((n, e) => (n[e[t]] = e, n), {});
}
function t(n) {
  return n.length ? n[n.length - 1] : void 0;
}
function e(n) {
  return n.length ? n[0] : void 0;
}
function r(n, t) {
  const e = t.indexOf(n);
  return -1 === e ? void 0 : t[Math.max(e - 1, 0)];
}
function o(n, t) {
  const e = t.indexOf(n);
  return -1 === e ? void 0 : t[Math.min(e + 1, t.length - 1)];
}
function i(n, t) {
  const e = Array.from(t),
    r = t.indexOf(n);
  return r > -1 && e.splice(r, 1), e;
}
function u(n, t) {
  return i(n, Array.from(t).reverse()).reverse();
}
function c(n, t) {
  const e = [].concat(n);
  return t.filter(n => !e.includes(n));
}
function f(n, t) {
  return [...n].reduce((n, e, r) => {
    const o = Math.floor(r / t);
    return (n[o] || (n[o] = [])).push(e), n;
  }, []);
}
async function* s(n, t = 100) {
  const e = f(n, t);
  for (let n = 0; n < e.length; n++) yield e[n], await new Promise(n => setTimeout(n, 0));
}
export { e as arrayHead, c as arrayRemoveAllInstances, i as arrayRemoveFirstInstance, u as arrayRemoveLastInstance, t as arrayTail, n as arrayToObject, s as asyncGroupArray, f as groupArray, o as nextInArray, r as previousInArray };
