<section class="kpi-section">
  <div cds-layout="grid cols@md:6 gap:lg align:stretch">
    <div class="card cds-card" cds-text="center" cds-layout="col@md:4 p-y:xl">
      <span>
        <p cds-text="display" cds-layout="p-b:md">{{librariesCount}}</p>
        <p cds-text="title"><i><a href="https://github.com/orgs/dlr-eoc/packages?repo_name=ukis-frontend-libraries"
              target="_blank">Open sourced libraries</a></i></p>
      </span>
    </div>
    <div class="card cds-card" cds-layout="col@md:4 p-y:xl">
      <span>
        <p cds-text="display" cds-layout="p-b:md">{{systems.length}}</p>
        <p cds-text="title"><i><a [routerLink]="['/systems']">Overall systems</a></i></p>
      </span>
    </div>
    <div class="card cds-card" cds-layout="col@md:4 p-y:xl">
      <span>
        <p cds-text="display" cds-layout="p-b:md">{{projectsCount}}</p>
        <p cds-text="title"><i>Research collaborations</i></p>
      </span>
    </div>
  </div>
</section>