import { __decorate as t } from "tslib";
import { createId as e, baseStyles as r, state as o } from "@cds/core/internal";
import { LitElement as s, html as d } from "lit";
import a from "./accordion-header.element.scss.js";
class n extends s {
  constructor() {
    super(...arguments);
    this.expanded = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this.setAttribute("slot", "accordion-header"), this.id || (this.id = e());
  }
  render() {
    return d`<div class="private-host" cds-layout="horizontal gap:sm align:vertical-center wrap:none"><cds-button-expand .expanded="${this.expanded}" readonly="readonly" action="vertical"></cds-button-expand><div cds-layout="align:stretch"><slot></slot></div></div>`;
  }
  static get styles() {
    return [r, a];
  }
}
t([o()], n.prototype, "expanded", void 0);
export { n as CdsAccordionHeader };
