import { Component, HostBinding } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdsModule } from '@cds/angular';
import { Iproject } from '../../data/ukis-projects';
import { actions, banner } from '../../data/main.page';
import { DataService, checkLinkExternal, sortSystems } from '../../services/data.service';
import { FooterComponent } from "../../components/footer/footer.component";
import { MarkdownLinkPipe } from '../../pipes/markdown-link.pipe';
import { HighlightSliderComponent } from "../../components/highlight-slider/highlight-slider.component";


@Component({
    selector: 'app-main',
    standalone: true,
    templateUrl: './main.component.html',
    styleUrl: './main.component.scss',
    imports: [CommonModule, RouterModule, CdsModule, NgOptimizedImage, FooterComponent, MarkdownLinkPipe, HighlightSliderComponent]
})
export class MainComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";

  public featuredSystems: Iproject[] = [];

  public banner = banner;

  public actions = actions;
  public codeAction = actions.find(i => i.subTitle === 'UKIS Frontend');

  checkLinkExternal = checkLinkExternal;

  constructor(private dataSvc: DataService) { }

  ngOnInit(): void {
    this.featuredSystems = [...this.dataSvc.getProjects('active').filter(i => i.featured)].sort((a, b) => sortSystems(a, b, 'random'));
  }
}
