import { stopEvent as e } from "./events.js";
function t(e) {
  return e.matches("a[href],area[href],input:not([disabled]),button:not([disabled]),select:not([disabled]),textarea:not([disabled]),iframe,object,embed,*[tabindex],*[contenteditable=true],[role=button]:not([disabled])");
}
function n(e) {
  return e.matches("a[href],button:not([disabled]),input[type=checkbox],input[type=radio],object,embed,*[tabindex],[role=button]:not([disabled])");
}
function o(e = document) {
  return e.activeElement && e.activeElement.shadowRoot ? o(e.activeElement.shadowRoot) ?? e.activeElement : e.activeElement;
}
function a(e) {
  e && !t(e) ? (e.setAttribute("tabindex", "-1"), e.focus(), e.addEventListener("blur", () => e.removeAttribute("tabindex"), {
    once: !0
  })) : e?.focus();
}
function d(e, t) {
  e.addEventListener("focusout", n => {
    !e.contains(n.relatedTarget) && document.hasFocus() && t();
  });
}
function i(t, n) {
  t.addEventListener("keyup", t => {
    "Escape" === t.code && (e(t), n());
  });
}
function c(e) {
  return !0 === e.cdsIgnoreFocus || e.hasAttribute("cds-ignore-focus") || e.hasAttribute("_demo-mode");
}
function r(e, t) {
  e.forEach(e => e.tabIndex = -1), t.tabIndex = 0;
}
function u(e) {
  e.forEach(e => e.tabIndex = -1), e[0].tabIndex = 0;
}
export { a as focusElement, t as focusable, o as getActiveElement, c as ignoreFocus, u as initializeKeyListItems, i as onEscape, d as onFocusOut, r as setActiveKeyListItem, n as simpleFocusable };
