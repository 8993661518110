import { isSafari as o, isWindows as i } from "../utils/browser.js";
import { onChildListMutation as t } from "../utils/events.js";
import { getFlattenedFocusableItems as r } from "../utils/traversal.js";
function e(o = {
  update: "slot"
}) {
  return i => {
    i.addInitializer(i => {
      i.ariaGridController || (i.ariaGridController = new s(i, o));
    });
  };
}
class s {
  constructor(o, i = {
    update: "slot"
  }) {
    this.host = o, this.config = i, this.observers = [], o.addController(this);
  }
  async hostConnected() {
    await this.host.updateComplete, this.intializeColumnSort(), this.update(), "slot" === this.config.update ? this.host.shadowRoot.addEventListener("slotchange", () => this.host.updateComplete.then(() => this.update())) : this.observers.push(t(this.host, () => this.host.updateComplete.then(() => this.update())));
  }
  hostDisconnected() {
    this.observers.forEach(o => o?.disconnect());
  }
  update() {
    this.grid = {
      grid: this.host.grid ? this.host.grid : this.host,
      columnRowGroup: this.host.columnRowGroup,
      columnRow: this.host.columnRow,
      columns: this.host.columns,
      rowGroup: this.host.rowGroup,
      rows: this.host.rows,
      cells: this.host.cells,
      footerRowGroup: this.host.footerRowGroup,
      footerRow: this.host.footerRow,
      footerCells: this.host.footerCells,
      placeholderCell: this.host.placeholderCell
    }, this.initializeGrid(), this.intializeColumns(), this.initializeRows(), this.initializeCells(), this.initializePlaceholder(), this.intializeFooter();
  }
  intializeColumnSort() {
    this.host.addEventListener("sortChange", o => {
      const i = o.composedPath().find(o => "columnheader" === o.role);
      i && (i.ariaSort = o.detail);
    });
  }
  initializeGrid() {
    const o = Math.max(this.grid.rows?.length, 1),
      i = this.grid.footerRow ? 1 : 0;
    this.grid.grid.role = "grid", this.grid.grid.ariaRowCount = "" + (1 + o + i), this.grid.grid.ariaColCount = "" + this.grid.columns.length;
  }
  intializeColumns() {
    this.grid.columnRowGroup.role = "rowgroup", this.grid.columnRow.role = "row", this.grid.columnRow.ariaRowIndex = "1", this.grid.columns.forEach((o, i) => {
      o.role = "columnheader", o.ariaColIndex = "" + (i + 1), o.ariaSort = "none", this.patchInvalidScreenReaderBehavior(o);
    });
  }
  initializeRows() {
    this.grid.rows?.forEach((o, i) => {
      o.role = "row", o.ariaRowIndex = "" + (i + 2);
    });
  }
  initializeCells() {
    const o = this.grid.columns.length;
    this.grid.cells?.forEach((i, t) => {
      i.role || (i.role = "gridcell"), i.ariaColIndex = "" + (t % o + 1);
    });
  }
  initializePlaceholder() {
    this.grid.placeholderCell && (this.grid.placeholderCell.ariaColSpan = this.grid.grid.ariaColCount);
  }
  intializeFooter() {
    this.grid.footerRowGroup && this.grid.footerRow && (this.grid.footerRowGroup.role = "rowgroup", this.grid.footerRow.role = "row", this.grid.footerRow.ariaRowIndex = "" + (this.grid.rows.length + 2), this.grid.footerCells?.forEach(o => o.role = "gridcell"), 1 === this.grid.footerCells?.length && (this.grid.footerCells[0].ariaColSpan = this.grid.grid.ariaColCount));
  }
  patchInvalidScreenReaderBehavior(t) {
    if (o() || i()) {
      const o = r(t).filter(o => !o.readonly),
        i = t.ariaLabel ? t.ariaLabel : t?.textContent?.trim();
      (o.length || t.resizable) && i.length && (t.ariaLabel = i, t.setAttribute("scope", "col"));
    }
  }
}
export { s as AriaGridController, e as ariaGrid };
