import { html as t } from "lit";
import { getFlattenedFocusableItems as s } from "../utils/traversal.js";
import { renderBefore as o, renderAfter as i } from "../utils/lit.js";
import { ignoreFocus as r } from "../utils/focus.js";
function h() {
  return t => t.addInitializer(t => new e(t));
}
class e {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  get focusableItems() {
    return s(this.root).filter(t => !t.hasAttribute("cds-focus-boundary") && (this.root.contains(t) || t.closest("[cds-focus-trap]") === this.host));
  }
  get root() {
    return this.host.shadowRoot ? this.host.shadowRoot : this.host;
  }
  get styles() {
    return t`<style cds-focus-style>:host(:focus-within) [cds-focus-boundary],:host(:host:focus-within) [cds-focus-boundary]{display:block!important}</style>`;
  }
  boundary(s) {
    return t`<div @focusin="${() => this.focusableItems.at(s)?.focus()}" test="${s}" cds-focus-boundary tabindex="0" style="display:none;position:absolute;width:1px;height:1px;clip:rect(0,0,0,0)">boundary</div>`;
  }
  async hostConnected() {
    await this.host.updateComplete, r(this.host) || (o(this.styles, this.root), o(this.boundary(-1), this.root), i(this.boundary(0), this.root), this.host.setAttribute("cds-focus-trap", ""));
  }
}
export { e as InlineFocusTrapController, h as focusTrap };
