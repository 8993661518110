const i = "cds-tree-item-expand",
  t = [{
    target: ".item-children",
    animation: [{
      opacity: 0,
      height: "0"
    }, {
      opacity: 1,
      height: "from:item-children"
    }],
    options: {
      duration: "--animation-duration",
      easing: "--animation-easing",
      fill: "forwards"
    }
  }];
export { t as AnimationTreeItemExpandConfig, i as AnimationTreeItemExpandName };
