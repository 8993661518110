function t() {
  return t => t.addInitializer(t => new o(t));
}
class o {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  get root() {
    return this.host.shadowRoot ? this.host.shadowRoot : this.host;
  }
  async hostConnected() {
    await this.host.updateComplete, this.root.addEventListener("scroll", () => this.host.style.setProperty("--row-content-visibility", "visibile"), {
      once: !0,
      capture: !0
    });
  }
}
export { o as ScrollableVisibilityController, t as scrollableVisibility };
