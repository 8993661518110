const o = "cds-accordion-panel-open",
  n = [{
    target: ".accordion-content",
    animation: [{
      opacity: 0,
      height: "0"
    }, {
      opacity: 1,
      height: "from:cds-accordion-content"
    }],
    options: {
      duration: "--animation-duration",
      easing: "--animation-easing",
      fill: "forwards"
    }
  }];
export { n as AnimationAccordionPanelOpenConfig, o as AnimationAccordionPanelOpenName };
