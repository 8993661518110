import { featureIds } from './ukis-features';

export interface Iimage {
  /** Link to the image, normally starts with assets/... */
  link: string;
  /** Display name of the image */
  title: string;
  /** Description text of the image */
  description: string;
  /** Version, if there are multiple versions of the image */
  version?: string;
  /** Additional information about the image  */
  commentIn?: string;
  /** if Image should only be shown on internal site */
  // internal?: boolean;
}

const software = {
  UF01: 'UKISFrontend:0.1', // 1.UKIS-Components-Main-Angular1.x Reference Implementation v.0.1: based on JS, bower, grunt, angular-leaflet-directive
  UF02: 'UKISFrontend:0.2', // 1.UKIS-Components-Main-Angular1.x Reference Implementation v.0.2: based on JS, bower, grunt, leaflet
  UF03: 'UKISFrontend:0.3', // ukis-ri-view v0.3.4-ol based on JS Typescript, npm, gulp, Openlayers
  UF2: 'UKISFrontend:2.0', // All versions starting from the switch to angular 2 and Clarity
  H3B: 'h3-backend',
  PB: 'Presto-backend',
  DjangoB: 'Django Oauth2 backend',
  DjangoBo: 'Old Django or other backend',
  ArcGIS: 'ArcGIS Services',
  Drupal: 'Old Drupal backend',
  JsonSB: 'json-server',
  ups: 'UPS (Geoserver)',
  GS: 'Geoservice',
  WPS: 'Geoserver and WPS',
  RSS: 'GeoRSS',
  ng: 'Angular',
  cds: 'cds/angular',
  nuxt: 'Nuxt',
  TS: 'TypeScript',
  vue: "Vue.js v2",
  boot: "Bootstrap",
  three: "three.js",
  ol: "OpenLayers",
  lume: "lume (deno)",
  hexo: "hexo (node.js)"
} as const;
type ValueOf<T> = T[keyof T];

type Tsoftware = ValueOf<typeof software>;
type Tfeatures = keyof typeof featureIds;



export interface Iproject extends IpublicProject, IinternalProject {
  id: string;
  /** The System is from an old project and not actively developed */
  inactive?: boolean;
  /** If the System should be excluded and not shown */
  exclude?: boolean;
  /** is shown in Example Gallery */
  featured?: boolean;
}

export interface IpublicProject {
  /** Title for the System e.g. on Card in Systems view */
  title: string;
  /** The image used as preview for the system of it's images */
  previewImage: number;
  /** A description of the project/system */
  description: string[];
  /** preview Image and further images for the System */
  images: Iimage[];

  /** description for Example Gallery. usethem if featured===true */
  shortDescription?: string;
  /** use ['ukis-helpdesk@dlr.de'] as default -> done with setMissingPointOfContact in getProjects */
  pointOfContact?: string[];
  /** use tag "Public" if this system is available outside of dlr */
  tags?: string[];
  /** ID's of ukis features */
  features?: Tfeatures[];
  /** public url - should not be password-protected, if possible */
  url?: string[];
  /** URL of the project for the system or other link, if it exists */
  projectUrl?: string;
  /** YYYY-MM or YYYY-MM-DD  first Sprint or creation Timespan / initialization date of the System */
  startDate: string;
  /** funding of the Project */
  fundingAuthorities?: string[];
  /** data Providers for the Project */
  dataProviders?: string[];
}

export interface IinternalProject {
  /** for inactive projects: */
  lastUpdatedIn?: string;
  urlIn?: string[];
  /** participating departments */
  participantsIn: string[];
  /** responsible department: */
  responsibleIn?: string[];
  pointOfContactIn?: string[];
  /** software used in the project (e.g. UKISFrontend:1.0, h3-backend): */
  softwareIn?: Tsoftware[];
  /** links to the code */
  gitIn?: string[];
  /** internal comment */
  commentIn?: string;
}

// New Frontend (Clarity and Angular2+) (ca. since 2018-05)

/**
 * U:\08_Multimedia\Präsentationen\DLR-GZS-alle-ukis-clients.pptx
 * U:\08_Multimedia\Präsentationen\alt\DLR-GZS-Client-Entwicklung.pptx
 *
 * https://gitlab.dlr.de/ukis-frontend
 */
export const projects: Iproject[] = [
  {
    title: "EO4cam",
    id: "project-eo4cam",
    shortDescription: "EO4cam",
    description: ["The effects of climate change are already being felt in Bavaria today. The sooner these changes are recognized, the sooner their economic and social consequences can be mitigated. The aim of EO4CAM (Earth Observation Laboratory for Climate Adaptation and Mitigation) is to support decision-makers with earth observation data."],
    featured: false,
    participantsIn: ["DFD-GZS:ISG"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Bookmarks", "EO4cam"],
    features: [],
    gitIn: ["https://gitlab.dlr.de/eo4cam/ukis-frontend"],
    url: ["https://eo4cam.dlr.de"],
    previewImage: 0,
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    images: [
      {
        link: 'assets/images/systems/eo4cam/Landing_page_1.jpg',
        title: 'Landing Page',
        description: 'Landing page with bookmarks leading to sub pages (routes) of the system. Bookmarks are helpful for users to get to their desired content faster.'
      },
    ],
    startDate: '2024-07-09' // based on repo in gitlab
  },
  {
    title: "Riesgos Website",
    id: "hexo-riesgos",
    shortDescription: "Website of the RIESGOS project",
    description: ["Static website for the RIESGOS project, with all information about the project and a news page. It is translated in three languages, de, en and es."],
    featured: false,
    inactive: true,
    participantsIn: ["DFD-GZS:ISG"],
    softwareIn: ["hexo (node.js)"],
    tags: ["Public", "Static Website"],
    features: [],
    gitIn: ["https://gitlab.dlr.de/gzs/hexo-riesgos"],
    url: ["https://www.riesgos.de/de/"],
    previewImage: 0,
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    images: [
      {
        link: 'assets/images/systems/hexo-riesgos/Landing_page_1.jpg',
        title: 'Landing Page',
        description: 'The landing page lists all the information about the project and is defined with HTML anchor tags so that the menu scrolls to this position on the single page.'
      },
    ],
    startDate: '2017-11-08' // based on repo in gitlab
  },
  {
    title: "ZKI Activations Website",
    id: "zki-activations-static-site",
    shortDescription: "ZKI Activations Website",
    description: ["Static website for ZKI activations in almost the same layout as the [dlr zki site](https://www.dlr.de/de/zki) made with Plone. It also allows you to create feeds from the content that store the coordinates of the events to create GeoRSS and GeoJSON files."],
    featured: false,
    participantsIn: ["DFD-GZS:ISG"],
    softwareIn: ['lume (deno)'],
    tags: ["Public", "Static Website"],
    features: [],
    gitIn: ["https://gitlab.dlr.de/gzs/zki-activations-static-site"],
    url: ["https://activations.zki.dlr.de/de/activations/"],
    previewImage: 0,
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    images: [
      {
        link: 'assets/images/systems/zki-activations-static-site/Activations_list_1.jpg',
        title: 'Landing Page',
        description: 'The landing page lists all activations, which can be filtered and sorted. Clicking on an activation takes the user to the detail page with descriptions and content for that activation.'
      },
    ],
    startDate: '2018-01-22' // based on repo in gitlab
  },
  {
    title: "EO Solar",
    id: "project-solar",
    shortDescription: "Solar potential Germany",
    description: ["Web client for the visualization of solar potential and existing solar investments in Germany. The data is divided into administrative level and building level."],
    featured: true,
    participantsIn: ["DFD-GZS:ISG", "DFD-LAX"],
    softwareIn: ["UKISFrontend:2.0"],
    tags: ["Public", "solar", "vector tiles", "maplibre"],
    features: ["ft_map_viewer", "ft_manage_layers", "ft_legend"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-solar"],
    url: ["https://eosolar.dlr.de/"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/project-solar/#/home"],
    previewImage: 0,
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    images: [
      {
        link: 'assets/images/systems/project-solar/Landing_page_bookmarks.jpg',
        title: 'Landing Page',
        description: 'Landing page with bookmarks leading to configured map route of the system based on URL parameters. Bookmarks are helpful for users to get to their desired content faster.'
      },
      {
        link: 'assets/images/systems/project-solar/Statistics_map_lan.jpg',
        title: 'Map View',
        description: 'Map view with side-navigation to manage layers, set layers styles, view legends and show data.'
      },
      {
        link: 'assets/images/systems/project-solar/Statistics_anteilPV_map_lan.jpg',
        title: 'Map View (side-nav)',
        description: 'In the side navigation you can change the map style with a dropdown. Since the layers are created with vector tiles, the client can automatically rerender the style of the layer based on some instructions.'
      },
      {
        link: 'assets/images/systems/project-solar/Statistics_map_krs_select.jpg',
        title: 'Map View (selection)',
        description: 'It is possible to select some features in the map by clicking on the map or using a drop-down menu on the side navigation. The selected features are then presented to the user with their values.'
      },
      {
        link: 'assets/images/systems/project-solar/Statistics_map_buildings.jpg',
        title: 'Map View (layer change)',
        description: `The visualized layers on the map can be changed in the side navigation through some radio buttons. Based on the layer IDs, different legends and values are displayed. <p></p> Due to the use of mapliebre, the view can be shifted so that the user can see the map like a bird's eye view and it is possible to activate simple 3D features.`
      },
    ],
    startDate: '2024-01-18' // based on repo in gitlab
  },
  {
    title: "MARISS Vessel Tracker",
    id: "mariss-vessel-tracker",
    shortDescription: "MARISS Vessel Tracker",
    description: ["Web client for the visualization of latest ship locations (AIS) and antarctic facilities."],
    featured: true,
    participantsIn: ["DFD-NBS:NZ"],
    softwareIn: ["UKISFrontend:2.0"],
    tags: ["maritime", "ship locations"],
    features: ["ft_map_viewer", "ft_manage_layers", "ft_legend"],
    gitIn: ["https://gitlab.dlr.de/fmsnz_external/mariss-vessel-tracker"],
    urlIn: ["https://fmsnzaisdb-develop-vm.eoc.dlr.de/"],
    previewImage: 0,
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    images: [
      {
        link: 'assets/images/systems/mariss-vessel-tracker/Map_View_2023-12-11.JPG',
        title: 'Map View (Landing Page)',
        description: 'Map view with side-navigation to manage layers, view legends and location navigation.'
      },
      {
        link: 'assets/images/systems/mariss-vessel-tracker/Map_View_Detail_2023-12-11.JPG',
        title: 'Map View (Zoom In)',
        description: 'Higher zoom resolutions allow users to examine more details on the map, e.g. layers can be rendered more complex.'
      },
      {
        link: 'assets/images/systems/mariss-vessel-tracker/Map_View_Detail_2023-12-11_animated.gif',
        title: 'Update - Map View',
        description: 'Layers can also have real-time data. This data is requested at certain intervals and updates the map view accordingly.'
      },
    ],
    startDate: '2023-04-24' // based on repo in gitlab
  },
  {
    exclude: true,
    title: "ukis show case",
    id: "ukis-show-case",
    shortDescription: "This application itself",
    description: ["UKIS application/website to show all with UKIS generated applications."],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: ["Angular", "cds/angular"],
    tags: ["Public", "demo"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/ukis-showcase"],
    url: ["https://www.ukis.dlr.de/home"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/ukis-showcase/#/home"],
    previewImage: 0,
    images: [{
      link: "assets/images/systems/ukis-show-case/Startseite1.JPG",
      title: "Landing Page",
      description: ""
    }],
    startDate: '2022-01-18' // https://gitlab.dlr.de/groups/ukis-frontend/-/milestones/3#tab-issues
  },
  {
    title: "Map's Demo Project",
    id: "project-demo-maps",
    shortDescription: "UKIS demo application.",
    description: ["UKIS demo application shows how to create mapping applications with the [ukis-frontend-libraries](https://github.com/dlr-eoc/ukis-frontend-libraries). It uses angular routing to show demos in a variety of different views e.g. how to work with the different [types of layers](https://github.com/dlr-eoc/ukis-frontend-libraries/tree/main/projects/services-layers), a small example for map projections and things like events and layout."],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    featured: true,
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Public", "demo", "layers", "routes", "projection", "ows"],
    features: ["ft_map_viewer", "ft_legend", "ft_manage_layers", "ft_3d_viwer", "ft_projection_switch"],
    gitIn: ["https://github.com/dlr-eoc/ukis-frontend-libraries/tree/main/projects/demo-maps"],
    url: ["https://dlr-eoc.github.io/ukis-frontend-libraries/#/examples"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-demo-maps/StartPage_1080.png',
        title: 'Landing Page',
        description: 'Overview of all examples for the demo application.'
      },
      {
        link: 'assets/images/systems/project-demo-maps/Route-Layers.png',
        title: 'Layers',
        description: 'The example shows how to work with UKIS layers, groups and the layer-service.'
      },
      {
        link: 'assets/images/systems/project-demo-maps/Route-Projection.png',
        title: ' Projection ',
        description: 'This example shows how to work with projections using ukis-projection-switch from @dlr-eoc/map-tools.'
      },
      {
        link: 'assets/images/systems/project-demo-maps/Route-Events.png',
        title: 'Map and Layer Events',
        description: 'Examples of map and layer events, such as displaying a loading bar or creating a grid layer based on zoom.'
      },
      {
        link: 'assets/images/systems/project-demo-maps/Route-Custom-Layers.png',
        title: 'Custom Layers',
        description: 'The example shows how to use UKIS custom layers, e.g. use OpenLayers instances directly, bind events, styles and renderers.'
      },
      {
        link: 'assets/images/systems/project-demo-maps/Route-OWS-Context-layers.jpg',
        title: 'OWS Context',
        description: 'The example shows how to declaratively configure layers in a json structure to store and share that state. This is done using the [OWS Context GeoJSON format](https://www.owscontext.org/owc_user_guide/C0_userGuide.html). The sample contexts are stored in projects/shared-assets/owc.'
      }
    ],
    startDate: '2019-05-03', // https://github.com/dlr-eoc/ukis-frontend-libraries/blob/cdea221b2e4ef48ea8a7c206de6103a4698d4a7d/CHANGELOG.md?plain=1#L636
  },
  {
    title: "Eco-Aral",
    id: "project-ecoaral",
    shortDescription: "Ecologically oriented regional development in the Aral Sea region",
    description: ["Stakeholders/scientists can use geospatial data to identify suitable locations for Artemia in the Aral Sea Region, Central Asia."],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    featured: true,
    participantsIn: ["DFD-GZS:OP", "DFD-LAX:OP"],
    softwareIn: [software.UF2, software.H3B],
    tags: ["Public", "H3", "Coastal", "Land"],
    features: ["ft_map_viewer", "ft_legend", "ft_manage_layers", "ft_filter_layers", "ft_charts"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-ecoaral"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/project-ecoaral"],
    url: ["https://eco-aral-project.eoc.dlr.de"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-ecoaral/map-view-info.jpg',
        title: 'Feature Info Chart',
        description: 'Display attributes of vector data (served as vector tiles) in a chart next to the map. This is also possible for OGC services if the allow GetFeatureInfo or the vector data contains all attributes.'
      },
      {
        link: 'assets/images/systems/project-ecoaral/filter-hexagons.jpg',
        title: 'Filter Map View',
        description: 'The user can filter the map data with range sliders. This helps to focus on specific values. Dynamic filtering is possible if the data service allows this via API. Smaller vector datasets can also be filtered on the client side.'
      },
    ],
    startDate: '2023-05-02', // first sprint
  },
  {
    title: "PRESTO",
    id: "project-early-triggering",
    shortDescription: "Improved satellite-based emergency mapping",
    description: ["This system intersects public disaster alerts with satellite acquisition data in order to detect when and which satellite imagery will be available for a crisis event."],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    featured: true,
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF2, software.PB],
    tags: ["early-triggering"],
    features: ["ft_map_viewer", "ft_legend", "ft_manage_layers", "ft_filter_layers", "ft_charts"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-early-triggering"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/project-early-triggering"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-early-triggering/LandingPage.JPG',
        title: 'Landing Page',
        description: 'Organize vector data side-by-side in a data list and a map with spatial context.'
      },
      {
        link: 'assets/images/systems/project-early-triggering/LandingPageCluster.JPG',
        title: 'Cluster Data',
        description: 'Vector data can be clustered to display regions with many data points. The clusters dissolve as you zoom further into the map.'
      },
      {
        link: 'assets/images/systems/project-early-triggering/LandingPageFilterData.JPG',
        title: 'Filter Data',
        description: 'Filters can be used to prioritize the data you want to see. Range filters are used here to filter data by time range, and toggle buttons and drop-down menus are used for additional filters.'
      },
      {
        link: 'assets/images/systems/project-early-triggering/Screenshot-2023-06-23-190749.jpg',
        title: 'Layer Manager',
        description: 'The Layer Manager allows you to quickly switch between layers, so you can see the data you are working with by toggling the eye icon.'
      },
      {
        link: 'assets/images/systems/project-early-triggering/Screenshot-2023-06-23-191446.jpg',
        title: 'Feature Info',
        description: 'Information about the data can be displayed in pop-ups when you click on the map in different layers. This is useful for inspecting the data.'
      }


    ],
    startDate: '2022-06-14', // gitlab
    // created from Satplan
  },
  {
    title: "ELKIS",
    id: "project-elkis",
    shortDescription: "ELK Informationssystem",
    description: [`ELKIS is a prototype for a web-based information system, presenting national, regional and global emissions from transport and transport-related sectors for the project ELK.
    The system will provide easy visual access to selected data from the project and allow visualisation of scientific emission data for potential users.
    It is an interdisciplinary project involving several DLR institutes and Hereon.`],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    featured: true,
    responsibleIn: ["DFD-GZS:OP"],
    participantsIn: ["DFD", "PA", "VF", "FK", "AS-GÖ"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Bookmarks", "ELK", "emissions"],
    features: ["ft_map_viewer", "ft_zoom_to_aoi", "ft_time_stepper", "ft_manage_layers", "ft_legend", "ft_compare_view", "ft_charts", "ft_3d_viwer"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/elkis"],
    url: ["https://elkis.dlr.de"],
    projectUrl: "https://verkehrsforschung.dlr.de/de/projekte/elk",
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/elkis"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/elkis/HomePage.JPG?v=2',
        title: 'Landing Page',
        description: 'Landing page with bookmarks leading to sub pages (routes) of the system. Bookmarks are helpful for users to get to their desired content faster.'
      },
      {
        link: 'assets/images/systems/elkis/GlobalRegion.JPG?v=2',
        title: 'Highlight slider',
        description: 'A sub page of the system, with a "highlight slider" that leads to different views of the data.'
      },
      {
        link: 'assets/images/systems/elkis/GlobalRegionFilter.JPG',
        title: 'Search and Filter Data',
        description: 'This page allows the user to search and filter for data hosted on a Geoserver, which is possible by parsing the capabilities of the service.'
      },
      {
        link: 'assets/images/systems/elkis/HamburgRegionTags.JPG',
        title: 'Filter Tags',
        description: 'The information is displayed on cards with tags. The user can filter these cards by tags and other input fields to get the desired dataset, which then can be displayed on a map.'
      },
      {
        link: 'assets/images/systems/elkis/HamburgRegionComapre-munich-NO2_25m-vs-NO2_5m.JPG',
        title: 'Compare View',
        description: 'When two different datasets are selected, they can be viewed side-by-side in a Compare Map. Clicking on a location on the map displays the information for that data on both maps.'
      },
      {
        link: 'assets/images/systems/elkis/HamburgRegionComapre-comparison-no2-and-no2.JPG',
        title: 'Time Stepper',
        description: 'The user can inspect the data by moving through the time steps with the Time Stepper. This will synchronize both maps if selected, otherwise the user can inspect the same map extent at different times.'
      }
    ],
    startDate: '2022-06-14', // gitlab
  },
  {
    title: "ZKI Fire Monitoring System",
    id: "project-fire",
    shortDescription: "ZKI Fire Monitoring System",
    description: [`DLR's “ZKI Fire Monitoring System” provides up-to-date wildfire information for Europe for the last 31 days. Aiming at monitoring burnt areas and their spatial development in near-real time, a fully automated approach was developed (Nolde et al. 2020, link to publication https://doi.org/10.3390/rs12132162) and implemented in an operational processing chain. To achieve several updates per day, mid-resolution satellite data are used. The information system allows an interactive analysis of current wildfires for Europe and Northern Africa.`],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    featured: true,
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Public", "fire", "burnt areas"],
    features: ["ft_map_viewer", "ft_time_range_picker", "ft_manage_layers", "ft_legend", "ft_filter_layers"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-fire"],
    url: ["https://services.zki.dlr.de/fire/"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/project-fire/"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/zki-fireservice-2/BurntAreas_world_2023-08-28_2023-09-28.jpg',
        title: 'Map View (Landing Page)',
        description: 'Map view with side-navigation to manage layers, view legends, and filter data.'
      },
      {
        link: 'assets/images/systems/zki-fireservice-2/BurntAreas_europe_2023-09-23_2023-09-28.jpg',
        title: 'Time Range Picker',
        description: 'The user can filter the data displayed on the map based on time using the range slider and date inputs.'
      },
      {
        link: 'assets/images/systems/zki-fireservice-2/BurntAreas_europe_2023-08-28_2023-09-28_filter.jpg',
        title: 'Filter Map View',
        description: 'The user can filter the map data with some range sliders. This helps to focus on specific values. Dynamic filtering is possible if the data service allows it via API. In this case, a WMS with "Time" and "CQL Filter" was used.'
      },
      {
        link: 'assets/images/systems/zki-fireservice-2/About_Page.jpg',
        title: 'About Page',
        description: 'The About page can display general information about the system, allowing the user to get more information about the data and everything displayed.'
      }
    ],
    startDate: '2022-07-11', // gitlab
  },
  {
    title: "ZKI Viewer",
    id: "project-zki-viewer",
    shortDescription: "ZKI Activation Highlights",
    description: ["Web client for the visualization of ZKI projects. This system was initially created for the 2021 floods in Germany. It has an overview page which lists selected projects as 'bookmarks', from which you can inspect the data more accurately on a detailed map."],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    featured: true,
    inactive: true,
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Bookmarks", "ZKI"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-zki-viewer"],
    url: ["https://services.zki.dlr.de/viewer"],
    projectUrl: "https://www.dlr.de/eoc/desktopdefault.aspx/tabid-12797",
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/project-zki-viewer"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/zki-viewer/start_page_selected_bm.jpg',
        title: 'Landing Page',
        description: 'The landing page with bookmarks links to the map configuration of the ZKI event and displays the corresponding data.'
      },
      {
        link: 'assets/images/systems/zki-viewer/bm_kylltal_floods_germany.jpg',
        title: 'Swipe Tool and Time Stepper',
        description: 'Compare before and after satellite imagery with a swipe tool. The user can also step through different time steps in the data.'
      }
    ],
    startDate: '2021-07-16', // gitlab
    // entstanden zum Hochwasser Deutschland 2021
  },
  {
    title: "Coastal Explorer",
    id: "project-coastalx",
    shortDescription: "Coastal Information System",
    description: ["The Coastal Explorer visualizes the scientific results of the team Coasts and River Basins, part of the department Land Surface Dynamics (LAX). The data is structured in several bookmarks by regions and/or projects."],
    featured: true,
    participantsIn: ["DFD-GZS:OP", "DFD-LAX:OP"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Public", "Coastal", "Bookmarks", "Land", "River"],
    features: ["ft_map_viewer", "ft_manage_layers", "ft_legend"],
    responsibleIn: ["DFD-LAX:OP"],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-coastalx"],
    url: ["https://coastalx.eoc.dlr.de/#/bookmarks"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/project-coastalx/#/bookmarks"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-coastalx/bookmarks-v1.1.jpg',
        title: 'Landing Page',
        description: 'The landing page of the system contains bookmarks that guide users to relevant map views of the corresponding data. Bookmarks are helpful for users to get to their desired content faster.'
      },
      {
        link: 'assets/images/systems/project-coastalx/bookmarks-and-map-v1.1.jpg',
        title: 'Landing Page with Map',
        description: 'Next to the bookmarks page, a map can also be displayed showing the extent of the bookmark regions. This gives the user a better impression of the spatial context.'
      },
      {
        link: "assets/images/systems/project-coastalx/aquaculture-1.jpg",
        title: "Map View",
        description: "The map view of the system displays hexagon vector datasets served by a web mapping service. More information on the data can be found at: https://www.mdpi.com/2072-4292/14/1/153",
        commentIn: 'Coastal aquaculture ponds'
      }, {
        link: "assets/images/systems/project-coastalx/aquaculture-pixel-and-hex.jpg",
        title: "Layers and Legends",
        description: "At a higher zoom level, the hexagons are displayed with the original data in the background. The user can also see the legends of the data in the right panel. More information on the data can be found at: https://www.mdpi.com/2072-4292/14/1/153.",
        commentIn: 'Surface covered by aquaculture ponds'
      }, {
        link: "assets/images/systems/project-coastalx/Mekong-Delta-Vietnam-flood.jpg",
        title: "Time Stepper",
        description: "Another bookmark of the system displays time series data via a web mapping service. The user can inspect all data by moving through the time steps with the time stepper at the bottom of the page.",
        commentIn: "Flood frequency in the Mekong Delta",
      }
    ],
    commentIn: `This system was developed using the previous "Catch Mekong System", which was based on Java, and migrated to the newer UKIS frontend in 2018.`,
    lastUpdatedIn: '2023-05-12',
    startDate: '2018-11' // E-Mail und Jahresrückblick DFD-GZS 2018
  },
  {
    title: "MARISS",
    id: "project-mariss",
    shortDescription: "Maritime Information System",
    description: ["Web client for the visualization of ship locations, wind speeds, wave heights and other maritime data detected with the help of radar satellite imagery."],
    featured: true,
    participantsIn: ["DFD-GZS:OP", "DFD-IBS:OP", "DFD-LAX:OP", "DFD-NBS:NZ"],
    softwareIn: ["UKISFrontend:2.0"],
    tags: ["maritime", "wind speeds", "wave heights", "ship locations", "radar imagery"],
    features: ["ft_map_viewer", "ft_manage_layers", "ft_legend", "ft_projection_switch", "ft_dataset_explorer"],
    gitIn: ["https://code-repository.eoc.dlr.de/fmsnz/project-mariss"],
    url: ["https://mariss.eoc.dlr.de"],
    //previewLink: "https://wiki.dlr.de/download/attachments/145984193/maritimesicherheit.PNG?version=1&modificationDate=1519386094833&api=v2"
    previewImage: 3,
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    images: [
      {
        link: 'assets/images/systems/project-mariss/mariss_Login.jpg',
        title: 'Login Page',
        description: 'The login page is for applications that require authentication. To make this work, Angular lets you use [route guards](https://angular.io/guide/router#preventing-unauthorized-access) in combination with your backend authentication service.'
      },
      {
        link: 'assets/images/systems/project-mariss/mariss_Datasets.jpg',
        title: 'Dataset Explorer',
        description: 'The Dataset Explorer allows you to search and filter data, which can then be added to the map view.'
      },
      {
        link: 'assets/images/systems/project-mariss/mariss_Ships.jpg',
        title: 'Map View',
        description: 'Map view with side navigation for layer management, projection switching and location navigation.'
      },
      {
        link: 'assets/images/systems/project-mariss/mariss_S1A_IW_GRDH_1SDV_20220228.jpg',
        title: 'Feature Info',
        description: 'Information about the data can be displayed in pop-ups upon clicking on the map, providing a good overview of the data. For data with extensive text or multiple images, it can be displayed on a seperate page or in a sidebar.'
      },
      {
        link: 'assets/images/systems/project-mariss/mariss_Print_Map.jpg',
        title: 'Print',
        description: 'The experimental print feature allows the user to print the current map view as a PDF document. Since this is a client-side print, you get the same resolutions as in the browser.'
      }
    ],
    startDate: '2016-11' // first Screenshot creation date (maritimesicherheit.PNG)
    // entstanden aus ???
    // 2018-04 Umstellung auf neues Frontend
  },
  {
    title: "Surface Water Monitoring System",
    id: "project-flood-h3",
    shortDescription: "Surface Water Monitoring System",
    description: ["Visualization of water covered areas derived from multiple sensors such as TerraSAR-X, Sentinel-1 and Sentinel-2. The data is organized in hexagon cells."],
    featured: false,
    inactive: true,
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF2, software.H3B],
    tags: ["water", "H3"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-flood-h3"],
    urlIn: [""],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-flood-h3/H3-Flood-System-StartPage.jpg',
        title: 'Map View (Landing Page)',
        description: 'Map view with side-navigation to manage layers, view legends, and filter data.'
      },
      {
        link: 'assets/images/systems/project-flood-h3/H3-Flood-System-OverviewLayer.jpg',
        title: 'Feature Info',
        description: 'Display attributes of vector data (served as vector tiles) in a tabular form next to the map.'
      },
      {
        link: 'assets/images/systems/project-flood-h3/H3-Flood-System-WaterLayer.jpg',
        title: 'Range Filters',
        description: 'Range filters can be used to filter data by time-range, or other range values.'
      }
    ],
    startDate: '2019-10', // DLR-GZS-alle-ukis-clients.pptx
    // entstanden aus project-hierarchical https://gitlab.dlr.de/ukis-frontend/project-hierarchical
  },
  {
    title: "panDEmis",
    id: "project-panDEmis",
    shortDescription: "Covid-19 Pandemie Informationssystem",
    description: ["The system enables regionally resolved insights into the infection process, intensive care capacities and COVID-19 cases as well as the existing demographics and vulnerability of the population in the catchment areas of the hospitals."
      , "It was created as part of the [SPoCK](https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Projekte_RKI/SPoCK.html) project in a cooperation between [BiB](https://www.bib.bund.de/DE/Startseite.html), [IMBI](https://www.uniklinik-freiburg.de/imbi.html), [RKI](https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Projekte_RKI/SPoCK.html) and [DLR](https://www.dlr.de/eoc/desktopdefault.aspx/tabid-5290/9370_read-18028/)."],
    featured: true,
    participantsIn: ["DFD-GZS:OP"],
    responsibleIn: ["DFD-GZS:OP"],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    tags: ["COVID-19", "H3", "Map"],
    softwareIn: [software.UF2, software.H3B],
    gitIn: ["https://gitlab.dlr.de/gzs-covid-19/hospitalcapacity-ukis-frontend"],
    urlIn: ["http://scholl.eoc.dlr.de/de/"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/panDEmis/landing-page.jpg',
        title: 'Landing Page',
        description: 'Landing page with different map configurations so that a user can easily see a case-specific view with different layers and map options.'
      },
      {
        link: 'assets/images/systems/panDEmis/7-tages-inzidenz.jpg',
        title: 'Map View (Infections)',
        description: 'Daily updated overview of infection numbers and forecasts. Clicking on the map shows more information like graphs and tables for the selected region.'
      },
      {
        link: 'assets/images/systems/panDEmis/vulnerability-index.jpg',
        title: 'Map View (Vulnerability)',
        description: 'Daily updated vulnerability and risk assessment. This view is based on the "UKIS-h3-backend", which generates h3 cells with a risk index calculated from available hospital beds in the region of a selected cell and their inhabitants. For more information see the [backend code](https://gitlab.dlr.de/gzs-covid-19/ht-hospitalcapacity/-/blob/master/src/kotlin/hiera/hospitalcapacity/serverapp/analytics/core.kt#L49).'
      },
      {
        link: 'assets/images/systems/panDEmis/driving-times.jpg',
        title: 'Map View (Travel Times)',
        description: 'Travel times to hospitals based on a hexagon cell and the [GraphHopper Routing Engine](https://github.com/graphhopper/graphhopper). This feature is also provided by the "UKIS-h3-backend".'
      }
    ],
    startDate: '2020-04', //gitlab
    // enstanden zum SPOCK Projekt
    // https://www.rki.de/DE/Content/InfAZ/N/Neuartiges_Coronavirus/Projekte_RKI/SPoCK.html
    inactive: true,
    commentIn: `App was public on https://pandemis.dlr.de till 2022-07-02.`
  },
  {
    title: "Urbanization in Africa",
    id: "worldbank-ukis-frontend",
    shortDescription: "State of Urbanization (Africa)",
    description: ["With the help of a discreet grid this system relates natural hazards and population numbers in Africa."],
    featured: false,
    inactive: true,
    participantsIn: ["DFD-LAX:OP", "DFD-GZS:OP"],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    softwareIn: [software.UF2, software.H3B],
    tags: ["H3"],
    features: ["ft_charts"],
    gitIn: ["https://gitlab.dlr.de/smart-cities-ukis/worldbank-ukis-frontend"],
    url: ["https://cvdeeplearn.eoc.dlr.de/"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/worldbank-ukis-frontend/landing-page.jpg',
        title: 'Landing Page (Map View)',
        description: 'Map view with side-navigation to manage layers, view legends, and switch between hazard types.'
      },
      {
        link: 'assets/images/systems/worldbank-ukis-frontend/Worldbank-Client-1.png',
        title: 'Chart and Results',
        description: 'Display attributes of vector data (served as vector tiles) in a chart and table next to the map.'
      }
    ],
    startDate: '2020-09-22' // gitlab
    // entstanden aus UKIS-LAX-Workshop https://gitlab.dlr.de/boec_mt/ukis-lax-workshop
  },
  {
    title: "SATPLAN",
    id: "project-satplan",
    shortDescription: "Information system for project IntelliMar",
    description: ["This system enables a user to manually define an AOI on a map and to query future satellite acquisitions for that area."],
    participantsIn: ["DFD-NBS:NZ"],
    gitIn: ["https://gitlab.dlr.de/satplan/frontend-for-intellimar"],
    url: ["https://www.dlr.de/eoc/de/desktopdefault.aspx/tabid-5413/10560_read-21914/"],
    previewImage: 1,
    images: [
      {
        link: 'assets/images/systems/project-satplan/login.jpg',
        title: 'Login Page',
        description: ''
      },
      {
        link: 'assets/images/systems/project-satplan/order-aoi.jpg',
        title: 'Order from Polygon',
        description: ''
      },
      {
        link: 'assets/images/systems/project-satplan/order-aoi-landsat.jpg',
        title: 'Order AOI',
        description: ''
      },
      {
        link: 'assets/images/systems/project-satplan/test-basket-ordering.jpg',
        title: 'Order Results',
        description: ''
      },
      {
        link: 'assets/images/systems/project-satplan/test-basket-ordering_2.jpg',
        title: 'Add Order',
        description: ''
      },
      {
        link: 'assets/images/systems/project-satplan/test-view of-basket-ordering.jpg',
        title: 'Order Basket',
        description: ''
      }
    ],
    tags: ["satellite", "plan", "bodensegment"],
    softwareIn: ['Angular'],
    featured: false,
    startDate: '',
    inactive: true,
    commentIn: `This project was the basis for PRESTO.`
  },

  {
    title: "RIESGOS-Demonstrator",
    id: "project-riesgos",
    shortDescription: "Multi-risk exploration tool for the Andes Region",
    description: ["The demonstrator enables users to explore, describe and quantify multi-risk situations in the Andes Region. Main functionalities are display and exploration of multi-risk analysis data products and information. For more information check the [RIESGOS Website](https://www.riesgos.de/en)."],
    featured: true,
    inactive: true,
    participantsIn: ["DFD-GZS:OP"],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    softwareIn: [software.UF2, software.WPS],
    tags: ["multi-risk", "scenarios"],
    gitIn: ["https://github.com/riesgos/dlr-riesgos-frontend"],
    urlIn: ["http://10.104.105.216/frontend/", "http://10.104.105.216/light/"],
    projectUrl: "https://www.riesgos.de",
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-riesgos/landing-page-v1.9.jpg',
        title: 'Landing Page',
        description: 'Landing page with bookmarks leading to sub pages (routes) of the system. Bookmarks are helpful for users to get to their desired content faster.'
      },
      {
        link: 'assets/images/systems/project-riesgos/config_wizard.jpg',
        title: 'Configuration Wizard and Map View',
        description: 'The sidebar allows you to configure the data displayed on the map. This can be helpful for navigating through different views.'
      },
      {
        link: 'assets/images/systems/project-riesgos/config_wizard_es.jpg',
        title: 'Feature Info',
        description: 'Information about the data can be displayed in pop-ups when you click on the map. If the data is not too complex, charts can be added to these pop-ups.'
      }




    ],
    startDate: '2019-03' // Screenshot | github -> 2019-06
    // https://www.riesgos.de/de/news/#07-18-2019T12-00-00
    // enstanden zum Projekt riesgos
  },
  {
    title: "Selected ZKI Activations",
    id: "zki-activations-map",
    description: ["This client shows all public ZKI activations on a map. The user can filter hazards and get links to the activations on the ZKI website with further details."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF03, software.RSS],
    pointOfContact: ['ukis-helpdesk@dlr.de'],
    tags: ["Public", "ZKI", "hazards"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/other-projects/zki-activations-map"],
    url: ["https://activations.zki.dlr.de/viewer/#/de/georss"],
    projectUrl: "https://www.dlr.de/eoc/desktopdefault.aspx/tabid-12797",
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/zki-activations-map/ZKI-Activations-viewer.jpg',
        title: 'Map View (Landing Page)',
        description: 'Map view with side-navigation to filter data and navigate to details. Information about the data can be displayed in pop-ups when you click on the map.'
      },
      {
        link: 'assets/images/systems/zki-activations-map/ZKI-Activations-Website.jpg',
        title: 'List View (web page)',
        description: 'Applications can also navigate to other web pages where general information can be displayed. This is possible if the data model of the web page is connected to that of your application.'
      },
      {
        link: 'assets/images/systems/zki-activations-map/ZKI-Activations-Website-detail.jpg',
        title: 'Detail View (web page)',
        description: 'View details about your data on a separate page if it contains a lot of text and images.'
      }
    ],
    startDate: '2015-12',
    lastUpdatedIn: '2016-01'
  },

  // ---------------------------------------------------------------------------------------------------------------
  {
    title: "WUEKIS",
    id: "wuekis",
    inactive: true,
    description: ["Prototype of a geodata-based information system for the University of Würzburg. Presentation of remote sensing data and products for Bavaria."],
    participantsIn: ["DFD-GZS:OP", "UNI-wuerzburg"],
    tags: [],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/wuekis"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/wuekis/wuekis-website.jpg',
        title: 'Landing Page (Website)',
        description: ''
      }
    ],
    startDate: '2020-11',
  },
  {
    title: "NextGEOSS - Air Quality in Megacities",
    id: "project-nextg-airquality-pilot",
    inactive: true,
    description: ["This NextGEOSS pilot application analyses air pollution time series derived from different sources in urban areas."],
    participantsIn: ["DFD-GZS:OP", "ATM", "INF /"],
    pointOfContact: ['ukis-helpdesk@dlr.de', 'Julian.Meyer-Arnek@dlr.de'],
    softwareIn: [software.UF03, software.UF2],
    tags: ["air"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-nextg-airquality-pilot"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/nextg-airquality-pilot/NextGEOSS-Air-Quality_processing.jpg',
        title: 'Landing Page (Map View)',
        description: ''
      },
      {
        link: 'assets/images/systems/nextg-airquality-pilot/NextGEOSS-Air-Quality_2.jpg',
        title: 'Grid Selection',
        description: ''
      },
      {
        link: 'assets/images/systems/nextg-airquality-pilot/NextGEOSS-Air-Quality_1.jpg',
        title: 'Grid Selection (Regional)',
        description: ''
      },
      {
        link: 'assets/images/systems/nextg-airquality-pilot/NextGEOSS-Air-Quality_3.jpg',
        title: 'Chart and Results',
        description: ''
      },

    ],
    url: ["http://www.nextgeoss-airquality.de/map"],
    // U:\08_Multimedia\Screenshots Anwendungen\UKIS-2018.pptx -> 2018?? | Älteste E-Mail 2018-03-20 Einbindung von Dygraphs in Typescript/Angular-JS.
    // 11/2016 - 11/2020 https://www.dlr.de/eoc/desktopdefault.aspx/tabid-11882/20871_read-64586
    // based on git https://gitlab.dlr.de/ukis-frontend/project-nextg-airquality-pilot/-/commit/ca8e52ded9165eee88cb8a2e79c1431e3d2b6950
    startDate: "2018-03",

    // "https://gitlab.dlr.de/ukis-frontend/project-nextg-airquality-pilot/-/milestones/1#tab-issues"
  },
  {
    title: "Project Planetary",
    id: "project-planetary",
    inactive: true,
    description: ["This application is a prototype for structured storage and visualization of planetary data. Planetary data from Ceres, provided by the EOC Geoservice, is displayed on a map with a custom projection for this dwarf planet. The user can then query locations for existing publications through an ELIB REST interface. For more information see the [published article](https://www.mdpi.com/2072-4292/14/7/1598)."],
    participantsIn: ["DFD-GZS:OP", "PF-GEO:BA"],
    softwareIn: [software.GS, software.UF2],
    tags: ["planetary"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-planetary"],
    previewImage: 1,
    urlIn: ["https://geotest.eoc.dlr.de/apps/pf/"],
    images: [
      {
        link: 'assets/images/systems/planetary/login.JPG',
        title: 'Login Page',
        description: ''
      },
      {
        link: 'assets/images/systems/planetary/spektrum.jpg',
        title: 'Map View (Quadrangle)',
        description: ''
      },

      {
        link: 'assets/images/systems/planetary/map1.JPG',
        title: 'Feature Info',
        description: ''
      },
      {
        link: 'assets/images/systems/planetary/map2.JPG',
        title: 'Multiple Feature Info',
        description: ''
      },
      {
        link: 'assets/images/systems/planetary/elib.JPG',
        title: 'Link to Publications',
        description: ''
      }
    ],
    // U:\08_Multimedia\Screenshots Anwendungen\UKIS-2018.pptx -> 2018??
    // based on git readme edit https://gitlab.dlr.de/ukis-frontend/project-planetary/-/commits/3b450c82ce2a83d48f3fcaadb64b7527feee19aa/README.md
    startDate: "2019-04-15"
  },
  {
    title: "Geoservice Test v3",
    id: "geoservice-mockup",
    inactive: true,
    shortDescription: "",
    description: ["This project was a follow-up of the Geoservice Test v2 project. The focus was on creating a static site with a portal look and integrating Geoservice Test v2 as map client."],
    participantsIn: ["DFD-GZS:OP", "DFD-INF:OP", "DFD-WIS:OP"],
    softwareIn: [software.GS, 'Nuxt'],
    tags: ["Website"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/other-projects/geoservice-mockup"],
    urlIn: ["https://ukis-frontend.pages.gitlab.dlr.de/other-projects/geoservice-mockup/"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/geoservice/v3/geoservice_landing_page.jpg',
        title: 'Landing Page (Part 1)',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v3/geoservice_landing_page_2.jpg',
        title: 'Landing Page (Part 2)',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v3/geoservice_landing_page_3.jpg',
        title: 'Landing Page (Part 3)',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v3/geoservice_product_detail.jpg',
        title: 'Product Detail',
        description: ''
      }
    ],
    // based on Sprint 3 June/Juli 2020
    startDate: '2020-06'
    //
  },
  {
    title: "Geoservice Test v1 and v2",
    id: "project-geoservice",
    inactive: true,
    description: ["This project aimed at the development of a new map client for the [EOC Geoservice](https://geoservice.dlr.de/web). It uses the [OWS Context GeoJSON Standard](http://www.owscontext.org/) to store the configuration for different map views which include OGC web services and data."],
    participantsIn: ["DFD-GZS:OP", "DFD-INF:OP"],
    softwareIn: [software.UF2, software.GS],
    tags: ["Bookmarks"],
    // https://gitlab.dlr.de/ukis-frontend/project-geoservice/-/tree/v1.0.0
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-geoservice/-/tree/master"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/geoservice/v2/GeoserviceLandingPage_v2.0.JPG',
        title: 'Landing Page V2',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v2/AGRO-DE_Info.JPG',
        title: 'Info Modal (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v2/MapAGRO-DE.JPG',
        title: 'Map View (Dataset)',
        description: ''
      },

      {
        link: 'assets/images/systems/geoservice/v1/GeoserviceLandingPage.JPG',
        title: 'Landing Page V1',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v1/GeoserviceMapInfo.JPG',
        title: 'Info Modal (Landing Page V1)',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v1/GeoserviceMapExport.JPG',
        title: 'Data Export (OWC)',
        description: ''
      },
      {
        link: 'assets/images/systems/geoservice/v1/GeoserviceExportContext.JPG',
        title: 'Exported File',
        description: ''
      },
    ],
    // based on E-Mail UKIS-Sprint zum Thema Geoservice
    startDate: "2019-02-04"
  },

  {
    title: "ZKI Flood System",
    id: "project-flood",
    inactive: true,
    description: ["The system visualizes flooded areas derived from TerraSAR-X and Sentinel-1 data. The scene-based data was organized into point clusters, allowing the user to select which scenes they would like to visualize. There is also an option to visualize Sentinel-1's acquisition segments to see where the next scene might be captured."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF02, software.UF03],
    tags: ["water"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/project-flood", "https://gitlab.dlr.de/ukis-frontend/old-frontends/zki-flood-view"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/project-flood/s1_flood_overview.JPG',
        title: 'Observations (Landing Page)',
        description: 'Point clusters of all TerraSAR-X and Sentinel-1 scene data that can be filtered by time, map extent and some properties.'
      },
      {
        link: 'assets/images/systems/project-flood/HS_v2.1.1_1.JPG',
        title: 'Filter Observations',
        description: 'Filtered clusters by time range and overview for a flood detection of a Sentinel-1 scene.'
      },
      {
        link: 'assets/images/systems/project-flood/s1_flood_20_08_2017_overview.JPG',
        title: 'Filter Observations 2',
        description: 'Filtered clusters by time range and overview for a flood detection of a Sentinel-1 scene.'
      },
      {
        link: 'assets/images/systems/project-flood/Flood-30.08-31.08-2017_1.JPG',
        title: 'Detailed View (Multiselection)',
        description: 'Detailed view of two selected scenes and there flood detections, combined urban data from the [Global Urban Footprint](https://www.dlr.de/eoc/en/desktopdefault.aspx/tabid-9628/16557_read-40454/)'
      },
      {
        link: 'assets/images/systems/project-flood/flood4.png',
        title: 'Detailed View',
        description: 'Detailed view of Sentinel 1 flood detection visualized over an ESRI satellite basemap.'
      },
      {
        link: 'assets/images/systems/project-flood/HS_v2.1.1_2.JPG',
        title: 'Intersection',
        description: 'Detailed view of Sentinel 1 flood detection clipped with OSM roads to show affected segments. This is done through a backend service that intersects the polygon and line features.'
      },
      {
        link: 'assets/images/systems/project-flood/s1_flood_20_08_2017_acquisitions.JPG',
        title: 'Acquisition Segments',
        description: 'Sentinel 1s acquisition segments, filtered by time, and an overview of the data provided by the segment.'
      },
      {
        link: 'assets/images/systems/project-flood/s1-flood-2015-10-06.png',
        title: 'Original Satellite Data',
        description: 'Detailed view of the Sentinel 1 flood detection visualised on top of the original satellite data of the scene.'
      }
    ],
    startDate: '2015-10' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
    // TerraSAR-X/Sentinel-1 Flood Client
  },
  {
    title: "Change Detection System",
    id: "change-detection",
    inactive: true,
    description: ["Web based change detection, upload of two classified raster datasets, analysis based on classes, output of results as maps, tables and graphics."],
    participantsIn: ["DFD-GZS:OP", "DFD-LAX:OP"],
    softwareIn: [software.UF03, 'Django Oauth2 backend'],
    tags: [],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/change-detection"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/change-detection/2_ChangeDetection_start.JPG',
        title: 'Landing Page',
        description: ''
      },
      {
        link: 'assets/images/systems/change-detection/3_ChangeDetection_upload1.JPG',
        title: 'Upload Data',
        description: ''
      },
      {
        link: 'assets/images/systems/change-detection/5_ChangeDetection_match1.JPG',
        title: 'Matching Classes',
        description: ''
      },
      {
        link: 'assets/images/systems/change-detection/7_ChangeDetection_select.JPG',
        title: 'Select Classes',
        description: ''
      },
      {
        link: 'assets/images/systems/change-detection/8_ChangeDetection_results.JPG',
        title: 'Show Results',
        description: ''
      }
    ],
    startDate: '2016-09' // DLR-GZS-alle-ukis-clients.pptx
  },
  {
    title: "UKIS Visualization Service",
    id: "client-video-on-deman",
    inactive: true,
    description: ["Prototype of a video on demand visualization service that demonstrates the possibility for rapid operational production of animations. It is based on time series of air pollutants of the EOC (Snow Cover, Ozone, NO2, HNO3, …). In the future, the service will be ready to visualize all operational EOC data."],
    participantsIn: ["DFD-GZS:OP", "DFD-WIS:OP"],
    softwareIn: ["TypeScript", "Vue.js v2", "Bootstrap", "three.js", "OpenLayers"],
    tags: ["video", "2D", "3D", "Web Form"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/client-video-on-deman/-/tree/dev"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/video-on-deman/vod2.PNG',
        title: '3D Selection',
        description: ''
      },
      {
        link: 'assets/images/systems/video-on-deman/vod2-2D.PNG',
        title: '2D Selection',
        description: ''
      },
      {
        link: 'assets/images/systems/video-on-deman/vod.png',
        title: '2D Selection (V1)',
        version: '0.1',
        description: ''
      },
    ],
    startDate: '2016-03' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "ZKI Modis Fire Service",
    id: "zki-fireservice",
    inactive: true,
    description: ["Visualisation of fire hotspots extracted from MODIS data using an automated process chain. It includes the ability to predict overpasses from the MODIS-Terra and MODIS-Aqua satellites. The scene-based data has been organised into point clusters, allowing the user to choose which scene to visualise."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF02, software.UF03],
    tags: ["fire"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/zki-fireservice"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/zki-fireservice/modisfire_2017-05-06.PNG',
        title: 'Observations (Landing Page)',
        description: 'The landing page of the system with a map showing the fire hotspots over the last 24 hours on top of an OSM base map and the MODIS satellite data. The user can also view hotspots for other days.'
      },
      {
        link: 'assets/images/systems/zki-fireservice/Feuer_Kola.jpg',
        title: 'Observations (Kola)',
        description: 'Detected fire hotspots in Murmansk, Russia, derived from MODIS data.'
      },
      {
        link: 'assets/images/systems/zki-fireservice/fireservice_Portugal2.jpg',
        title: 'Observation Detail',
        description: 'Detected fire hotspots in Portugal, derived from MODIS data.'
      },
      {
        link: 'assets/images/systems/zki-fireservice/Italien und Kroatien.PNG',
        title: 'Observations (Italy)',
        description: 'Fire hotspots identified in Italy and Croatia, derived from MODIS data.'
      },
      {
        link: 'assets/images/systems/zki-fireservice/Fireservice_20.09.2017_1.JPG',
        title: 'EFFIS Fire Danger',
        description: 'Overview of a MODIS fire hotspot on top of a [EFFIS fire danger layer](https://effis.jrc.ec.europa.eu/about-effis/technical-background/fire-danger-forecast).'
      },
      {
        link: 'assets/images/systems/zki-fireservice/modis-fire-satellite-footprint.png',
        title: 'Fire and Overpasses',
        description: 'MODIS satellite overpasses for the derived fire hotspots.'
      },
      {
        link: 'assets/images/systems/zki-fireservice/modis-satellite-footprint-view.png',
        title: 'Satellite Overpasses',
        description: 'Overpasses View of satellites and their current positions.'
      }
    ],
    startDate: '2015-10' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx and Git https://gitlab.dlr.de/ukis-frontend/old-frontends/zki-fireservice/-/blob/9088fe333c33ae8ef18b70e3bcb0a1fcf940aa3d/src/index.html
  },
  {
    title: "SHOA Lightweight DSS Prototype (SLDP)",
    id: "sldp-view",
    inactive: true,
    description: ["Prototype of a web-based tsunami early warming system for Chile. Earthquake locations can be entered manually. The system retrieves and visualizes an appropriate simulation scenario. Warning levels are assigned to warning segments based on the predicted wave heights at the coast."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF01],
    tags: ["water", "early warming"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/sldp-view"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/sldp-view/sldp.png',
        title: 'Map View (Landing Page)',
        description: ''
      }
    ],
    startDate: '2014-02' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "Modis SnowPack Timeseries",
    id: "visualization-of-timeseries",
    inactive: true,
    description: ["Visualization of the snow cover in Europe per day for a period between September 2000 and August 2014. A specific day can be selected using a time slider. Time series of a configurable length can be played automatically."],
    participantsIn: ["DFD-GZS:OP", "DFD-WIS:OP"],
    softwareIn: [software.UF02],
    tags: ["snow"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/visualization-of-timeseries"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/visualization-of-timeseries/snowpack_edit-color.png',
        title: 'Landing Page (Map View)',
        description: ''
      }
    ],
    startDate: '2015-09' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "EGSIEM",
    id: "egsiem",
    inactive: true,
    description: ["The system correlates gravity field data of the GRACE satellites with flood masks derived from Sentinel-1 and TerraSAR-X data. The user can view a [GRACE based Wetness Index](http://www.egsiem.eu/129-blog-entry-flood-drought-info-in-near-real-time) on different timesteps and overlay example floods detected from Sentinel-1 and TerraSAR-X."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF03],
    tags: ["GRACE"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/egsiem"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/egsiem/egsiem-overview.png',
        title: 'Map View (Landing Page)',
        description: 'Map view with side-navigation to manage layers, view legends, and filter data.'
      },
      {
        link: 'assets/images/systems/egsiem/egsiem.png',
        title: 'Data Filters',
        description: ''
      },
      {
        link: 'assets/images/systems/egsiem/egsiem-info2.png',
        title: 'Time Range Filter and Time Stepper',
        description: ''
      }
    ],
    // Präsentationen/DLR-GZS-alle-ukis-clients.pptx -> 2017-05
    // (2015 - 2018) https://www.dlr.de/eoc/desktopdefault.aspx/tabid-16042/
    // based on git https://gitlab.dlr.de/ukis-frontend/old-frontends/egsiem/-/blob/a3ff770222de589897de7ccfed809309bec63ae7/src/index.html
    startDate: '2017-08'
  },
  {
    title: "Demo for SOS Children's Villages",
    id: "sos-childrens-villages",
    inactive: true,
    description: ["Visualization of worldwide SOS Children's Villages in relation to different kinds of risk areas. The user can view and filter locations of villages and overlay various hazard maps, and analyze villages that may be affected by specific hazards."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF02],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/sos-childrens-villages"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/sos-childrens-villages/situation-view.png',
        title: 'Map View (Data List)',
        description: ''
      },
      {
        link: 'assets/images/systems/sos-childrens-villages/sos.png',
        title: 'Detail View (drought)',
        description: ''
      },
      {
        link: 'assets/images/systems/sos-childrens-villages/hazard-detail-view.png',
        title: 'Detail View (earthquake)',
        description: ''
      }
    ],
    // 2016-03-10 https://gitlab.dlr.de/ukis-frontend/old-frontends/sos-childrens-villages/-/blob/d55428fb0899ef226deef07e65d8d2ca8983da03/src/index.html
    startDate: '2016-04'
  },

  {
    title: "Generic UPS Client",
    id: "ukis-ups-client",
    inactive: true,
    description: ["The UKIS Processing Server (UPS) is a software component, allowing users to execute processes on remote computers (e.g. in other networks) via WPS. The generic UPS client provides a user friendly frontend to configure the wps inputs on dynamically generated forms and visualize the process results on a map."],
    participantsIn: ["DFD-GZS:OP", "DFD-LAX:OP"],
    softwareIn: [software.UF03, 'UPS (Geoserver)'],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/ukis-ups-client"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/ukis-ups-client/ups.png',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/ukis-ups-client/ups_2.3.png',
        title: 'Configuration Sidebar',
        description: ''
      }

    ],
    // 2015-02-20 https://gitlab.dlr.de/ukis-frontend/old-frontends/ukis-ups-client/-/blob/69279ff0080c206753471b8a9871baf0109c74c5/app/index.html
    startDate: '2015-02' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "TerraSAR-X Flood Client",
    id: "tsx-flood-view",
    inactive: true,
    description: ["Visualization of flooded areas derived from TerraSAR-X data and a flood probability layer. As an overview, the client shows clustered center points of all available scenes, allwoing a user to easily zoom to a scene and inspect it in detail."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF01, 'Old Django or other backend'],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/tsx-flood-view"],
    tags: ["water"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/tsx-flood-view/tsx1.png',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/tsx-flood-view/tsx2.png',
        title: 'Map View (Detail)',
        description: ''
      }
    ],
    startDate: '2015-02' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "Global Urban Footprint Client (GUF)",
    // guf-view-2
    id: "guf-view",
    inactive: true,
    description: ["Analyses based on the [GUF data](https://www.dlr.de/eoc/en/desktopdefault.aspx/tabid-9628/16557_read-40454/) for Germany. The user can send queries to a system backend in order to calculate statistics such as population within a configurable rectangle."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF01, 'Old Django or other backend', 'ArcGIS Services'],
    tags: ["Bookmarks", "GUF"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/guf-view", "https://gitlab.dlr.de/ukis-frontend/old-frontends/guf-view-2"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/guf/guf2.png',
        title: 'Landing Page V2 (Map View)',
        description: ''
      },
      {
        link: 'assets/images/systems/guf/guf.png',
        title: 'Landing Page V1 (Map View)',
        description: ''
      },
      {
        link: 'assets/images/systems/guf/guf_munich_bookmarks.jpg',
        title: 'Bookmarks Munich',
        description: ''
      },
      {
        link: 'assets/images/systems/guf/guf_munich_analyze.jpg',
        title: 'Analyze Munich',
        description: ''
      },
      {
        link: 'assets/images/systems/guf/guf_munich_geocode.jpg',
        title: 'Geocode Munich',
        description: ''
      },
      {
        link: 'assets/images/systems/guf/guf_munich_getFeatureInfo.jpg',
        title: 'Feature Info Munich',
        description: ''
      }
    ],
    startDate: '2014-04' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
    //v1 2014-04 | v2 09.2014
  },
  {
    // spinoff from tsx-flood-view
    title: "SASSCAL Client",
    id: "tsx-flood-view-sasscal",
    inactive: true,
    description: ["This demonstrator for a flood risk management system for the Southern Africa [SASSCAL project](https://www.sasscal.org) was derived from [ZKI Flood System](http://localhost:4200/systems/project-flood). The user can visualize flood masks derived from Sentinel-1 and TerraSAR-X data as well as the Global Urban Footprint which was used to analyze whether flooding occured in urban areas."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF01, 'Old Django or other backend'],
    tags: ["water"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/flood-view-sasscal/sasscal.png',
        title: 'Map View (Landing Page)',
        description: ''
      }
    ],
    // https://www.dlr.de/eoc/desktopdefault.aspx/tabid-16042/ SASSCAL (2012 - 2018)
    startDate: '2015-08' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "MODIS Flood Client",
    id: "modis-flood-view",
    inactive: true,
    description: ["Visualization of flooded areas derived from MODIS data. Additionally the ground tracks of MODIS/Terra and MODIS/Terra as well as the current locations of the satellites can be displayed."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF01, 'Old Drupal backend'],
    tags: ["Water"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/modis-flood-view/modisclient.png',
        title: 'Landing Page (Map View)',
        description: ''
      },
    ],
    startDate: '2013-09' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },
  {
    title: "Demo ZKI Flood Information System",
    id: "cebit-flood",
    inactive: true,
    description: ["This application was developed as a demonstrator for a satellite-based flood information system and was shown at the [CeBIT 2014](https://www.dlr.de/eoc/desktopdefault.aspx/tabid-9209/15811_read-39556/)."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF01],
    tags: ["water"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/cebit-flood-plattner"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/cebit-flood/zkihochwasser.png',
        title: 'Map View',
        description: `Map view with side-navigation to manage layers, view legends, and zoom to pre-configured AOIs.`
      },
    ],
    startDate: '2014-03' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },

  // TODO https://www.dlr.de/eoc/desktopdefault.aspx/tabid-16042/
  {
    title: "UPS EVO-ODAS Client",
    id: "client-evo-odas",
    inactive: true,
    description: ["This application was developed as part of the [EVO-ODAS project](https://www.geosolutionsgroup.com/blog/evo-odas-eo-data-access-services/) with a focus on improving the management of OGC TIME and ELEVATION dimensions. The user can query time and elevation dimensions from a GeoServer and visualize data products on a map with two separate sliders. The example data was generated with a FLEXPART particle dispersion model using Geoserver WPS."],
    participantsIn: ["DFD-GZS:OP", "DFD-INF:OP", "DFD-ATM:OP"],
    softwareIn: [software.UF03, "json-server", "UPS (Geoserver)"],
    features: [],
    tags: ["emissions"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/client-evo-odas"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/client-evo-odas/Emissionsausbreitung-Client.jpg',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/time-slider.gif',
        title: 'Time Slider',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/height-slider.gif',
        title: 'Height Slider',
        description: ''
      },
      /* {
        link: 'assets/images/systems/client-evo-odas/evo-oda_1.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_2.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_3.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_4.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_4_1.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_4_2.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_5_2.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      },
      {
        link: 'assets/images/systems/client-evo-odas/evo-oda_6_2.JPG',
        title: 'Map View (Landing Page)',
        description: ''
      } */

    ],
    startDate: '2016-12'
  },

  {
    title: "UKIS Pharos",
    id: "pharos-view",
    inactive: true,
    description: ["A test system for DLR's backend technology developed in the project [PHAROS](https://www.dlr.de/content/en/articles/news/2016/20160512_pharos-successful-demonstration-of-a-system-solution-for-catastrophe-management-during-forest-fires-in-catalonia_17781.html). In the project a backend for handling disaster incidents and observations was built. The purpose of the client was to visualize the backend's results for verification purposes."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF02, software.DjangoBo],
    tags: ["fire"],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/pharos-view"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/pharos-view/observations-view.png',
        title: 'Observations V1 (Map View)',
        description: ''
      },
      {
        link: 'assets/images/systems/pharos-view/incidents.jpg',
        title: 'Incidents (Map View)',
        description: ''
      },
      {
        link: 'assets/images/systems/pharos-view/incidents_detail.jpg',
        title: 'Incident Detail',
        description: ''
      },
      {
        link: 'assets/images/systems/pharos-view/observations.jpg',
        title: 'Observations V2 (Map View)',
        description: ''
      },
      {
        link: 'assets/images/systems/pharos-view/observations_detail.jpg',
        title: 'Observation Detail',
        description: ''
      },
      {
        link: 'assets/images/systems/pharos-view/drawing.jpg',
        title: 'Draw Incident/Observation',
        description: ''
      },
    ],
    startDate: '2014-11' // Präsentationen/alt/DLR-GZS-Client-Entwicklung.pptx
  },

  {
    title: "Demmin",
    id: "demmin-view",
    inactive: true,
    description: ["The system visualizes data in the context of the Durable Environmental Multidisciplinary Monitoring Information Network (DEMMIN)."],
    participantsIn: ["DFD-GZS:OP"],
    softwareIn: [software.UF03],
    gitIn: ["https://gitlab.dlr.de/ukis-frontend/old-frontends/demmin-view"],
    previewImage: 0,
    images: [
      {
        link: 'assets/images/systems/demmin-view/demmin.png',
        title: 'Map View',
        description: ''
      }
    ],
    startDate: '2017-04-11' // from Git https://gitlab.dlr.de/ukis-frontend/old-frontends/demmin-view/-/commit/1b6a711850e0e59b440e03c2d8e6514c7b126b96
  }
];
