import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'markdownLink',
  standalone: true
})
export class MarkdownLinkPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): unknown {
    if (value && value.length > 0) {

      const renderer = new marked.Renderer();
      renderer.link = function (href, title, text) {
        const link = marked.Renderer.prototype.link.call(this, href, title, text);
        return link.replace("<a", "<a target='_blank' ");
      };
      marked.setOptions({
        renderer: renderer
      });
      // https://github.com/markedjs/marked/pull/1761
      return marked.parseInline(value);
    }
    return value;
  }
}
