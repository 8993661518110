import { notProductionEnvironment as e, LogService as t } from "../services/log.service.js";
const r = (e, t, r) => {
    Object.defineProperty(t, r, e);
  },
  s = (e, t) => ({
    kind: "method",
    placement: "prototype",
    key: t.key,
    descriptor: e
  });
function i(i, o) {
  return (n, u) => {
    const a = n.firstUpdated;
    n.firstUpdated = function () {
      const r = this.querySelector(i),
        s = o?.exemptOn && o?.exemptOn(this);
      if (!r && e() && o?.required && !s) {
        const e = o.requiredMessage || `The <${i}> element is required to use <${this.tagName.toLocaleLowerCase()}>`;
        if ("error" === o.required) throw Error(e);
        t.warn(e, this);
      }
      o?.assign && !1 === r?.hasAttribute("slot") && r.setAttribute("slot", o.assign), a && a.apply(this);
    };
    const c = {
      get() {
        return this.querySelector(i);
      },
      enumerable: !0,
      configurable: !0
    };
    return void 0 !== u ? r(c, n, u) : s(c, n);
  };
}
function o(e, t) {
  return (i, o) => {
    const n = i.firstUpdated;
    i.firstUpdated = function (r) {
      t?.assign && Array.from(this.querySelectorAll(e)).filter(e => !e.hasAttribute("slot")).forEach(e => e.setAttribute("slot", t.assign)), n && n.call(this, r);
    };
    const u = {
      get() {
        return this.querySelectorAll(e);
      },
      enumerable: !0,
      configurable: !0
    };
    return void 0 !== o ? r(u, i, o) : s(u, i);
  };
}
export { i as querySlot, o as querySlotAll };
